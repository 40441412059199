// src/plugins/vuetify.js
import { createVuetify } from "vuetify";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css"; // Import Material Design Icons
import { md3 } from "vuetify/blueprints";

const vuetify = createVuetify({
  blueprint: md3,
  icons: {
    defaultSet: "mdi", // Use Material Design Icons by default
  },
  locale: {
    messages: {
      en: {
        close: "mdi-close-circle", // Replace "mdi-close" with any icon, like "mdi-close-circle"
      },
    },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#6200EE",
          secondary: "#03DAC6",
          background: "#ffffff",
        },
      },
      dark: {
        colors: {
          primary: "#BB86FC",
          secondary: "#03DAC6",
          background: "#121212",
        },
      },
    },
  },
});

export default vuetify;
