<template>
  <div class="data-visualization-comparison">
    <h1 class="title">Compare Players</h1>

    <!-- Search Bar and Players Table Container -->
    <div v-if="players.length > 0" class="styled-container">
      <!-- Search Bar -->
      <v-text-field
        v-model="search"
        density="compact"
        label="Search Players"
        prepend-inner-icon="mdi-magnify"
        variant="solo-filled"
        flat
        hide-details
        single-line
        class="search-bar"
      ></v-text-field>

      <!-- Players Table -->
      <v-data-table
        v-model="selectedPlayers"
        v-model:sort-by="sortBy"
        :headers="tableHeaders"
        :items="filteredPlayers"
        density="compact"
        :filter-keys="['name']"
        v-model:search="search"
        :item-selectable="isRowSelectable"
        @update:options="onOptionsUpdate"
        item-value="id"
        class="elevation-2 players-table"
        :items-per-page="20"
        hide-default-footer
        show-select
        return-object
      >
        <template #no-data>
          <div class="no-data-message">No players found for this team.</div>
        </template>
      </v-data-table>
    </div>

    <!-- Selected Players Info and Training Sessions -->
    <div v-if="selectedPlayers.length > 0" class="selected-players-info">
      <h3>
        Selected Players and Training Sessions ({{ selectedPlayers.length }}):
      </h3>
      <ul
        v-for="player in selectedPlayers"
        :key="player.id"
        class="player-item"
      >
        <li class="player-info">
          <span class="player-text"
            >{{ player.name }} {{ player.last_name }} -
            {{ player.position }}</span
          >
          <div class="training-session-dropdown">
            <v-select
              v-model="selectedPlayerSessions[player.id]"
              :items="getPlayerTrainingSessions(player.id)"
              item-title="session_date"
              item-value="id"
              label="Select Training Session"
              outlined
              no-data-text="No training sessions available for this player."
              dense
            ></v-select>
          </div>
        </li>
      </ul>
    </div>

    <!-- No Players Alert -->
    <v-alert
      v-if="players.length === 0"
      type="warning"
      elevation="2"
      border="left"
      style="text-align: center; margin-top: 16px"
    >
      No players found for this team.
    </v-alert>

    <!-- Compare Players Button -->
    <div v-if="canComparePlayers" class="button-container">
      <v-btn @click="comparePlayers" class="md3-button" color="primary" dark>
        <v-icon left>mdi-account-multiple</v-icon> Compare Players
      </v-btn>
    </div>
  </div>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "ComparePlayersComponent",
  props: {
    teamId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localTeamId: null, // Local copy of the teamId prop
      search: "", // Search query for filtering
      players: [], // List of players fetched from the API
      selectedPlayers: [], // Selected players from the table
      selectedPlayerSessions: {}, // Stores selected training sessions per player
      trainingSessions: {}, // Training sessions keyed by player ID
      sortBy: [{ value: "position", order: "asc" }], // Sorting configuration
      tableHeaders: [
        { title: "Player Number", value: "player_number", sortable: true },
        { title: "First Name", value: "name", sortable: true },
        { title: "Last Name", value: "last_name", sortable: true },
        { title: "Position", value: "position", sortable: true },
      ],
    };
  },
  computed: {
    filteredPlayers() {
      if (!this.search) return this.players;
      return this.players.filter((player) =>
        `${player.name} ${player.last_name}`
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
    /**
     * Determines whether a row should be disabled.
     * Rows are disabled if two players are already selected, unless the row is one of the selected players.
     * @returns {Function} - Function to determine if a row should be disabled.
     */
    isRowDisabled() {
      return (item) => {
        const isSelected = this.selectedPlayers.some(
          (player) => player.id === item.id
        );
        return this.selectedPlayers.length >= 2 && !isSelected;
      };
    },

    /**
     * Determines if the "Compare Players" button should be visible.
     * The button is shown only if two players and their corresponding training sessions are selected.
     */
    canComparePlayers() {
      return (
        this.selectedPlayers.length === 2 &&
        Object.keys(this.selectedPlayerSessions).length === 2 &&
        Object.values(this.selectedPlayerSessions).every((session) => !!session)
      );
    },
  },
  async mounted() {
    // Parse query parameters
    const { playerId, sessionId, teamId } = this.$route.query;
    this.localTeamId = parseInt(teamId || this.teamId);

    console.log(`Team ID retrieved: ${this.localTeamId}`);
    console.log(`Preselect Player ID: ${playerId}, Session ID: ${sessionId}`);

    // Fetch players and auto-select based on query parameters
    if (this.localTeamId) {
      await this.fetchPlayersByTeam(this.localTeamId);
      if (playerId) {
        this.autoSelectPlayer(parseInt(playerId), parseInt(sessionId));
      }
    } else {
      console.error("No team ID provided.");
    }
  },
  watch: {
    /**
     * Watch for changes in selectedPlayers to fetch training sessions
     * and clear data for unselected players.
     */
    selectedPlayers: {
      deep: true,
      handler(newSelectedPlayers, oldSelectedPlayers) {
        if (newSelectedPlayers.length > 2) {
          this.selectedPlayers = newSelectedPlayers.slice(0, 2);
          return;
        }

        newSelectedPlayers.forEach((player) => {
          if (!this.trainingSessions[player.id]) {
            this.fetchTrainingSessions(player.id);
          }
        });

        // Clear data for unselected players
        const unselectedPlayerIds = oldSelectedPlayers
          .filter(
            (player) => !newSelectedPlayers.find((p) => p.id === player.id)
          )
          .map((player) => player.id);

        unselectedPlayerIds.forEach((playerId) => {
          delete this.trainingSessions[playerId]; // Remove training sessions
          delete this.selectedPlayerSessions[playerId]; // Remove selected sessions
        });
      },
    },
  },
  methods: {
    /**
     * Determines if a row can be selected based on the current state.
     * Allows selection of up to 2 players only.
     * @param {Object} item - The row item being checked.
     * @returns {Boolean} - True if the row can be selected, false otherwise.
     */
    isRowSelectable(item) {
      const isSelected = this.selectedPlayers.some(
        (player) => player.id === item.id
      );
      return isSelected || this.selectedPlayers.length < 2;
    },

    /**
     * Updates the options for selected rows.
     * @param {Object} options - The updated options.
     */
    onOptionsUpdate(options = {}) {
      // Safely extract selectedItems from options, defaulting to an empty array
      const selectedItems = options.selectedItems || [];

      // Get the IDs of currently selected players
      const currentSelectedIds = this.selectedPlayers.map((p) => p.id);

      // Filter the selectedItems to include only those already in selectedPlayers
      this.selectedPlayers = selectedItems.filter((item) =>
        currentSelectedIds.includes(item.id)
      );

      // Ensure any previously selected players are kept even if not visible
      const existingSelections = this.players.filter((player) =>
        currentSelectedIds.includes(player.id)
      );
      this.selectedPlayers.push(
        ...existingSelections.filter(
          (player) =>
            !this.selectedPlayers.find((selected) => selected.id === player.id)
        )
      );
    },

    /**
     * Fetch players for the given team ID.
     * @param {Number} teamId - ID of the team to fetch players for.
     */
    async fetchPlayersByTeam(teamId) {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(`/api/players/player/team/${teamId}/`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.players = response.data;
      } catch (error) {
        console.error(`Error fetching players for team ID ${teamId}:`, error);
      }
    },

    /**
     * Fetch training sessions for a specific player.
     * @param {Number} playerId - ID of the player to fetch training sessions for.
     */
    /**
     * Fetch training sessions for a specific player.
     * @param {Number} playerId - ID of the player to fetch training sessions for.
     */
    async fetchTrainingSessions(playerId) {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(
          `/api/training-sessions/?playerId=${playerId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        // Sort the sessions by newest date first and assign directly
        this.trainingSessions[playerId] = response.data.sort((a, b) => {
          return new Date(b.session_date) - new Date(a.session_date);
        });
      } catch (error) {
        console.error(
          `Error fetching training sessions for player ${playerId}:`,
          error
        );
        this.trainingSessions[playerId] = [];
      }
    },

    /**
     * Get the training sessions for a specific player.
     * @param {Number} playerId - ID of the player to retrieve sessions for.
     * @returns {Array} List of training sessions for the player.
     */
    getPlayerTrainingSessions(playerId) {
      return this.trainingSessions[playerId] || [];
    },

    /**
     * Auto-select a player and their training session based on query parameters.
     * @param {Number} playerId - The ID of the player to auto-select.
     * @param {Number} sessionId - The ID of the session to auto-select.
     */
    async autoSelectPlayer(playerId, sessionId) {
      // Find the player in the list
      const player = this.players.find((p) => p.id === playerId);
      if (!player) {
        console.error(`Player with ID ${playerId} not found.`);
        return;
      }

      // Add the player to the selectedPlayers array
      this.selectedPlayers.push(player);

      // Fetch training sessions if not already fetched
      if (!this.trainingSessions[player.id]) {
        await this.fetchTrainingSessions(player.id);
      }

      // Auto-select the session for the player
      if (sessionId && this.trainingSessions[playerId]) {
        const sessionExists = this.trainingSessions[playerId].some(
          (session) => session.id === sessionId
        );
        if (sessionExists) {
          this.selectedPlayerSessions[playerId] = sessionId;
        } else {
          console.error(
            `Session with ID ${sessionId} not found for player ID ${playerId}.`
          );
        }
      }
    },

    /**
     * Navigate to the CompareResultsComponent with the required query parameters.
     */
    comparePlayers() {
      if (!this.canComparePlayers) {
        return;
      }

      // Extract player and session IDs
      const player1Id = this.selectedPlayers[0].id;
      const player2Id = this.selectedPlayers[1].id;
      const session1Id = this.selectedPlayerSessions[player1Id];
      const session2Id = this.selectedPlayerSessions[player2Id];

      // Navigate to the CompareResultsComponent
      this.$router.push({
        name: "CompareResults",
        query: {
          player1Id,
          player2Id,
          session1Id,
          session2Id,
          teamId: this.localTeamId, // Include team ID in the query
        },
      });
    },

    /**
     * Reset search input and training sessions.
     */
    resetSelections() {
      this.search = "";
      this.selectedPlayers = [];
      this.selectedPlayerSessions = {};
    },
  },
};
</script>

<style scoped>
/* Container to center the button */
.button-container {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  margin-top: 20px; /* Add spacing above the button */
}
/* Container for the entire component Testing */
.data-visualization-comparison {
  max-width: 700px;
  margin: 117px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

/* Container for the search bar and players table */
.styled-container {
  margin-top: 25px;
  background-color: #ffffff; /* White background */
  padding: 15px;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 700px; /* Restrict maximum width */
}

/* Title styling */
.title {
  font-family: "Roboto", sans-serif;
  color: #333;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

/* Subtitle headers */
h3 {
  font-family: "Roboto", sans-serif;
  color: #6200ee;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}

/* Search bar */
.search-bar {
  margin-bottom: 15px;
}

/* Table container */
.players-table-container {
  margin-top: 20px;
}

/* Data table styling */
.v-data-table {
  background-color: #fff;
  border-radius: 8px;
}

/* No data message inside the table */
.no-data-message {
  text-align: center;
  color: #666;
  font-size: 16px;
}

/* Selected players info container */
.selected-players-info {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Individual player item in the list */
.player-item {
  margin-bottom: 20px;
}

/* Player info section */
.player-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* Player text styling */
.player-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  align-items: left;
  color: #333;
  margin-bottom: 10px;
}

/* Dropdown for training sessions */
.training-session-dropdown {
  margin-top: 10px;
}

/* Alert message styling */
.v-alert {
  margin-top: 16px;
  text-align: center;
}

/* Export section styling */
.export-section {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Button styling */
.md3-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #6200ee;
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.md3-button:hover {
  background-color: #3700b3;
}

.md3-button v-icon {
  margin-right: 8px;
}

/* Alert for no players */
.no-players-alert {
  text-align: center;
  margin-top: 16px;
}

/* Styling adjustments for responsive design */
</style>
