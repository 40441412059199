/* prettier-ignore */
<template>
  <v-container max-width="600px" class="profile-container">
    <v-card elevation="3" class="profile-card">
      <!-- Profile Title -->
      <v-card-title class="profile-title">User Profile</v-card-title>

      <!-- Error Alert -->
      <v-alert v-if="fetchError" type="error" dismissible>
        {{ fetchError }}
      </v-alert>

      <v-card-text class="profile-content">
        <!-- Profile Picture -->
        <div class="profile-picture">
          <v-avatar size="140" class="profile-avatar">
            <img
              :src="loadError ? defaultAvatar : profilePicturePreview"
              alt=""
              class="profile-picture-img"
              @error="handleImageError"
            />
          </v-avatar>
        </div>

        <!-- User Name -->
        <div class="profile-name">{{ firstName }} {{ lastName }}</div>

        <v-divider class="profile-divider"></v-divider>

        <!-- Profile Details -->
        <v-list dense class="profile-list">
          <!-- Email -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="profile-label">
                <v-icon class="profile-icon" left>mdi-email</v-icon>
                Email
              </v-list-item-title>
              <v-list-item-subtitle class="profile-info">
                {{ email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="profile-divider"></v-divider>

          <!-- About Me -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="profile-label">
                <v-icon class="profile-icon" left
                  >mdi-information-outline</v-icon
                >
                About Me
              </v-list-item-title>
              <v-list-item-subtitle class="profile-info">
                {{ aboutMe }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <!-- Edit Profile Button -->
      <v-card-actions class="edit-profile-button">
        <v-btn
          color="primary"
          block
          large
          class="action-btn"
          @click="goToEditProfile"
        >
          <v-icon left>mdi-pencil</v-icon>
          Edit User Profile
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
// import api from "api";
import api from "@/axios.js";

export default {
  name: "UserProfileComponent",
  data() {
    return {
      firstName: "", // User's first name
      lastName: "", // User's last name
      email: "", // User's email address
      aboutMe: "", // About Me text, now loaded from backend
      profilePicturePreview: null, // URL for profile picture
      defaultAvatar: "path/to/default-avatar.png", // Fallback image URL
      fetchError: null, // Holds error message for display in v-alert
      loadError: false, // Tracks if profile picture failed to load
    };
  },
  async mounted() {
    try {
      const accessToken = localStorage.getItem("access_token");

      // Fetch user profile data from backend API
      const response = await api.get("/api/auth/user/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Assign fetched data to component variables
      this.firstName = response.data.first_name;
      this.lastName = response.data.last_name;
      this.email = response.data.email;
      this.aboutMe =
        response.data.about_me || "Write something about yourself...";
      this.profilePicturePreview = response.data.profile_picture;
    } catch (error) {
      this.fetchError = "Error fetching user profile. Please try again later.";
      console.error("Error fetching user profile:", error);
    }
  },
  methods: {
    goToEditProfile() {
      this.$router.push({ name: "EditUserProfile" });
    },
    handleImageError() {
      this.loadError = true;
    },
  },
};
</script>

<style scoped>
/* Centering the profile card */
.profile-card {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

/* Title styling */
.profile-title {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  font-weight: bold;
}

/* Profile picture container */
.profile-picture {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

/* Profile avatar with circular purple border */
.profile-avatar {
  border: 2px solid #6200ee;
  border-radius: 80%;
}

/* Profile picture image adjustments */
.profile-picture-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* User name styling */
.profile-name {
  font-size: 1.5rem;
  color: #6200ee;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

/* Divider styling */
.profile-divider {
  margin: 12px 0;
  background-color: #e0e0e0;
}

/* List styling */
.profile-list {
  width: 100%;
}

/* Field labels in the profile list */
.profile-label {
  font-size: 1.2rem;
  color: #424242;
  font-weight: 500;
  display: flex;
  align-items: center;
}

/* Purple color for icons */
.profile-icon {
  color: #6200ee;
  margin-right: 8px;
  font-size: 1.5rem;
}

/* Text for Email and About Me */
.profile-info {
  font-size: 1rem;
  color: #333;
  padding-left: 24px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
}

/* About Me dynamic height */
.profile-list .v-list-item:last-child .profile-info {
  min-height: 3rem;
  max-height: none;
  overflow: visible;
}

/* Button styling for Edit Profile */
.action-btn {
  background-color: #6200ee;
  color: white !important;
  border-radius: 30px;
  padding: 12px 24px;
  font-size: 1em;
  font-weight: 500;
  transition: all 0.3s ease;
}

/* Hover effect for button */
.action-btn:hover {
  background-color: #6b0bf2;
  color: white !important;
}
</style>
