/* prettier-ignore */
<template>
  <v-container max-width="600px">
    <v-card elevation="3" class="profile-card">
      <v-card-title class="profile-title">Edit User Profile</v-card-title>

      <v-card-text>
        <!-- Profile Picture Upload and Remove Section -->
        <div class="profile-picture-upload">
          <v-avatar size="140" class="profile-avatar mb-4">
            <img
              :src="
                tempProfilePicturePreview ||
                profilePicturePreview ||
                defaultAvatar
              "
              alt=""
              class="profile-picture-img"
              @error="handleImageError"
            />
          </v-avatar>
          <v-file-input
            label="Upload Profile Picture"
            v-model="tempProfilePicture"
            outlined
            accept="image/*"
            show-size="false"
            @change="previewImage"
          ></v-file-input>
        </div>

        <!-- Editable Fields for User Information with icons -->
        <v-text-field
          label="First Name"
          v-model="firstName"
          outlined
          prepend-inner-icon="mdi-account"
          @input="checkChanges"
        ></v-text-field>
        <v-text-field
          label="Last Name"
          v-model="lastName"
          outlined
          prepend-inner-icon="mdi-account"
          @input="checkChanges"
        ></v-text-field>
        <v-textarea
          label="About Me"
          v-model="aboutMe"
          outlined
          maxlength="50"
          counter="50"
          prepend-inner-icon="mdi-information-outline"
          @input="checkChanges"
        ></v-textarea>
        <!-- Success Alert with a redirect message after saving -->
        <v-alert
          v-if="alerts.success"
          color="success"
          class="mt-3 success-alert"
          close-label="Close Alert"
          border="start"
          title="Success"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.success = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          Profile updated successfully! Redirecting to profile...
        </v-alert>

        <!-- Error Alert -->
        <v-alert v-if="errorMessage" type="error" dismissible class="mt-3">
          {{ errorMessage }}
        </v-alert>
        <!-- Buttons for changing password and email -->
        <div class="stacked-buttons">
          <v-btn
            color="primary"
            dark
            class="mt-2 action-btn"
            :disabled="!hasChanges"
            @click="saveProfile"
          >
            <v-icon left>mdi-content-save</v-icon> Save
          </v-btn>
          <v-btn
            color="primary"
            class="mt-4 action-btn"
            @click="goToChangePassword"
          >
            <v-icon left>mdi-lock</v-icon> Change Password
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2 action-btn"
            @click="goToChangeEmail"
          >
            <v-icon left>mdi-email</v-icon> Change Email
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2 action-btn"
            @click="goToChangeUsername"
          >
            <v-icon left>mdi-account</v-icon> Change Username
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import api from "api";
import api from "@/axios.js";
export default {
  name: "UserProfileEditComponent",
  data() {
    return {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      aboutMe: "",
      profilePicture: null,
      profilePicturePreview: null,
      tempProfilePicture: null,
      tempProfilePicturePreview: null,
      tempRemovePicture: false,
      successMessage: "",
      errorMessage: "",
      defaultAvatar: "path/to/default-avatar.png",
      alerts: {
        success: false,
      },
      hasChanges: false,
      initialData: {},
    };
  },
  async mounted() {
    try {
      const accessToken = localStorage.getItem("access_token");

      // Fetch user profile data from the backend
      const response = await api.get("/api/auth/user/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.username = response.data.username;
      this.firstName = response.data.first_name;
      this.lastName = response.data.last_name;
      this.email = response.data.email;
      this.aboutMe =
        response.data.about_me || "Write something about yourself...";
      this.profilePicturePreview = response.data.profile_picture;

      // Save initial data to compare later for changes
      this.initialData = {
        firstName: this.firstName,
        lastName: this.lastName,
        aboutMe: this.aboutMe,
        profilePicture: this.profilePicturePreview,
      };
    } catch (error) {
      this.displayMessage("Error fetching user profile.", "error");
    }
  },
  methods: {
    goToChangePassword() {
      this.$router.push({ name: "ChangePassword" });
    },
    goToChangeUsername() {
      this.$router.push({ name: "ChangeUsername" });
    },
    goToChangeEmail() {
      this.$router.push({ name: "ChangeEmail" });
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.tempProfilePicturePreview = e.target.result;
          this.tempProfilePicture = file;
          this.tempRemovePicture = false;
          this.checkChanges();
        };
        reader.readAsDataURL(file);
      }
    },
    checkChanges() {
      const validNames =
        this.firstName.trim() !== "" && this.lastName.trim() !== "";

      // Check if any field is different from the initial data
      this.hasChanges =
        validNames &&
        (this.firstName !== this.initialData.firstName ||
          this.lastName !== this.initialData.lastName ||
          this.aboutMe !== this.initialData.aboutMe ||
          (this.tempProfilePicture &&
            this.profilePicturePreview !== this.tempProfilePicturePreview) ||
          this.tempRemovePicture);
    },
    async tempRemoveProfilePicture() {
      try {
        const accessToken = localStorage.getItem("access_token");
        await api.patch(
          "/api/auth/user/",
          { profile_picture: "" },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        this.profilePicturePreview = null;
        this.tempProfilePicturePreview = null;
        this.tempProfilePicture = null;
        this.tempRemovePicture = true;
        this.checkChanges();

        this.displayMessage("Profile picture removed successfully!", "success");
      } catch (error) {
        this.displayMessage("Failed to remove profile picture.", "error");
        console.error("Error removing profile picture:", error);
      }
    },
    async saveProfile() {
      try {
        const accessToken = localStorage.getItem("access_token");

        const formData = new FormData();
        formData.append("first_name", this.firstName);
        formData.append("last_name", this.lastName);
        formData.append("about_me", this.aboutMe);

        if (this.tempProfilePicture) {
          formData.append("profile_picture", this.tempProfilePicture);
        } else if (this.tempRemovePicture) {
          formData.append("profile_picture", ""); // Clear profile picture if removed
        }

        this.alerts.success = false;

        await api.patch("/api/auth/user/", formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (this.tempProfilePicturePreview) {
          this.profilePicturePreview = this.tempProfilePicturePreview;
        } else if (this.tempRemovePicture) {
          this.profilePicturePreview = null;
        }

        this.tempProfilePicture = null;
        this.tempProfilePicturePreview = null;
        this.tempRemovePicture = false;

        this.alerts.success = true;

        // Emit profile picture update event
        this.$emit(
          "profile-picture-updated",
          this.profilePicturePreview || this.defaultAvatar
        );

        setTimeout(() => {
          this.alerts.success = false;
          this.$router.push({ name: "UserProfile" });
        }, 2000);

        // Update initial data after save to reset change detection
        this.initialData = {
          firstName: this.firstName,
          lastName: this.lastName,
          aboutMe: this.aboutMe,
          profilePicture: this.profilePicturePreview,
        };
        this.hasChanges = false; // Reset hasChanges
      } catch (error) {
        this.displayMessage(
          "An error occurred while saving the profile.",
          "error"
        );
      }
    },

    displayMessage(message, type) {
      if (type === "success") {
        this.successMessage = message;
      } else {
        this.errorMessage = message;
      }
      setTimeout(() => {
        if (type === "success") {
          this.successMessage = "";
        } else {
          this.errorMessage = "";
        }
      }, 3000);
    },
    handleImageError() {
      this.profilePicturePreview = this.defaultAvatar;
    },
  },
};
</script>

<style scoped>
/* Adjusted styles */
.profile-card {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

.profile-title {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.mt-3 {
  margin-top: 1rem;
}

.profile-picture-upload {
  text-align: center;
  margin-bottom: 20px;
}

.profile-avatar {
  border: 2px solid #6200ee;
  border-radius: 80%;
}

.profile-picture-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.stacked-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.action-btn {
  background-color: #6200ee;
  color: white !important;
  border-radius: 30px;
  padding: 12px 24px;
  font-weight: 500;
  width: 300px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.action-btn:hover {
  background-color: #3700b3;
  color: white !important;
}

.success-alert {
  background-color: #e6ffed;
  color: #388e3c;
}

.custom-close-icon {
  cursor: pointer;
}
</style>
