<template>
  <v-container max-width="600px" class="settings-container">
    <v-card elevation="3" class="settings-card">
      <v-card-title class="settings-title">User Settings</v-card-title>
      <v-card-text>
        <!-- Theme Toggle (Light/Dark) -->
        <div class="theme-toggle">
          <v-btn class="theme-toggle-btn">
            <v-icon class="theme-icon">{{
              isDarkMode ? "mdi-weather-sunny" : "mdi-moon-waxing-crescent"
            }}</v-icon>
            {{ isDarkMode ? "Light Mode" : "Dark Mode" }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<!-- @click="toggleTheme" -->
<script>
export default {
  name: "UserSettingsComponent",
  data() {
    return {
      isDarkMode: localStorage.getItem("theme") === "dark",
      language: "en",
      languages: [{ label: "English", code: "en" }],
    };
  },
  methods: {
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
      const theme = this.isDarkMode ? "dark" : "light";
      this.$vuetify.theme.global.name = theme;
      localStorage.setItem("theme", theme);
    },
    changeLanguage() {
      console.log("Language changed to:", this.language);
    },
  },
  mounted() {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      this.isDarkMode = storedTheme === "dark";
      this.$vuetify.theme.global.name = storedTheme;
    }
  },
};
</script>

<style scoped>
.settings-card {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

.settings-title {
  font-size: 1.8rem;
  color: #6200ee;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.theme-toggle-btn {
  font-size: 1rem;
  padding: 6px 24px;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: #6200ee;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.theme-toggle-btn:hover {
  background-color: #3700b3;
}

.theme-icon {
  font-size: 1rem;
  margin-right: 8px;
}

.language-selection {
  margin-top: 20px;
}
</style>
