<template>
  <v-container max-width="500px">
    <!-- Card containing the registration form -->
    <v-card outlined class="register">
      <!-- Title of the registration card -->
      <v-card-title class="headline-small text-h5 register-title">
        Register
      </v-card-title>

      <v-card-text>
        <!-- Form for user registration -->
        <v-form @submit.prevent="submitForm" v-model="valid" lazy-validation>
          <!-- Username Field with Icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.username"
                label="Username"
                :rules="[rules.username]"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-account"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Email Field with Icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.email"
                label="Email"
                type="email"
                :rules="[rules.email, rules.emailFormat]"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-email"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- First Name Field with Icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.first_name"
                label="First Name"
                :rules="[rules.first_name]"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-account-circle"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Last Name Field with Icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.last_name"
                label="Last Name"
                :rules="[rules.last_name]"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-account-circle-outline"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Password Field with Show/Hide Toggle Icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.password"
                :type="showPassword1 ? 'text' : 'password'"
                label="Password"
                :append-inner-icon="
                  showPassword1 ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
                "
                prepend-inner-icon="mr-3 mdi-lock"
                @click:append-inner="togglePasswordVisibility(1)"
                @focus="showPasswordGuideline = true"
                @blur="showPasswordGuideline = false"
                :rules="[rules.password]"
                outlined
                dense
                required
              ></v-text-field>
              <small class="password-guidelines">
                Must have atleast 1 Capital letter.Must have atleast 1 Special
                character.Must have at least 1 number.Password must be at least
                8 characters long and should not be entirely numeric.
              </small>
            </v-col>
          </v-row>

          <!-- Confirm Password Field with Show/Hide Toggle Icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.password2"
                :type="showPassword2 ? 'text' : 'password'"
                label="Confirm Password"
                :append-inner-icon="
                  showPassword2 ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
                "
                prepend-inner-icon="mr-3 mdi-lock-outline"
                @click:append-inner="togglePasswordVisibility(2)"
                :rules="[rules.password2]"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Register Button with Icon -->
          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                block
                large
                class="mt-2 register-btn"
                :disabled="!valid"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-icon left>mr-1 mdi-check-circle</v-icon> Register
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <!-- Error Messages with Custom Close Icon -->
        <v-alert
          v-model="showErrors"
          color="error"
          class="mt-3 custom-alert"
          close-label="Close Alert"
          border="start"
          title="Error Alert"
          variant="elevated"
        >
          <template #append>
            <v-icon large class="custom-close-icon" @click="showErrors = false"
              >mdi-close-circle</v-icon
            >
          </template>
          <div v-for="(error, index) in errors" :key="index" class="text-left">
            {{ error }}
          </div>
        </v-alert>

        <!-- Success Message -->
        <v-alert
          v-if="showMessage"
          color="success"
          class="mt-3 success-alert"
          border="start"
          close-label="Close Alert"
          title="Success Alert"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="showMessage = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <div class="text-left">
            Registration successful! Redirecting to Email Confirmation...
          </div>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import axios from "axios";
import api from "@/axios.js";

export default {
  name: "RegisterComponent",
  data() {
    return {
      // Form data for registration
      form: {
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        password2: "",
      },
      message: "", // Success message for registration
      errors: [], // Array to hold error messages
      showErrors: false, // Controls visibility of error alert
      showMessage: false, // Controls visibility of success alert
      showPassword1: false, // Toggle for password visibility
      showPassword2: false, // Toggle for confirm password visibility
      showPasswordGuideline: true, // Controls visibility of password guideline
      valid: false, // Form validation status
      // Validation rules for each form field
      rules: {
        username: (value) => !!value || "Enter a username.",
        email: (value) => !!value || "Enter an email address.",
        emailFormat: (value) =>
          /.+@.+\..+/.test(value) || "E-mail must be valid.",
        first_name: (value) => !!value || "Enter your first name.",
        last_name: (value) => !!value || "Enter your last name.",
        password: (value) => !!value || "Enter a password.",
        password2: (value) => !!value || "Confirm your password.",
      },
    };
  },
  methods: {
    /**
     * Handles form submission for user registration.
     */
    async submitForm() {
      this.errors = []; // Clear previous errors
      this.message = ""; // Clear previous success message

      const url = "/api/auth/register/";
      try {
        // Register the user
        await api.post(url, {
          username: this.form.username,
          email: this.form.email,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          password: this.form.password,
          password2: this.form.password2,
        });
        localStorage.setItem("emailForVerification", this.form.email);
        // Display a success message and redirect to email verification
        this.message = "Registration successful! Please verify your email.";
        this.showMessage = true;
        this.showErrors = false; // Hide error alert if successful

        // Pass the email to VerifyEmailComponent
        setTimeout(() => {
          this.$router.push({ name: "VerifyEmail" }); // Navigate without email in the URL
        }, 2000);
      } catch (error) {
        // Handle errors
        this.errors = []; // Clear errors before adding new ones
        if (error.response && error.response.data) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Object.prototype.hasOwnProperty.call(errorData, key)) {
              if (Array.isArray(errorData[key])) {
                this.errors.push(`${key}: ${errorData[key].join(" ")}`);
              } else {
                this.errors.push(`${key}: ${errorData[key]}`);
              }
            }
          }
        } else {
          this.errors.push("An error occurred during registration.");
        }
        this.showErrors = true; // Show error alert if errors are present
      }
    },

    /**
     * Toggles the visibility of password fields individually.
     * @param {number} field - The field to toggle (1 for password, 2 for confirm password)
     */
    togglePasswordVisibility(field) {
      if (field === 1) {
        this.showPassword1 = !this.showPassword1;
      } else if (field === 2) {
        this.showPassword2 = !this.showPassword2;
      }
    },

    /**
     * Clears all error messages and hides the alert.
     */
    clearErrors() {
      this.errors = [];
      this.showErrors = false;
    },

    /**
     * Resets the form fields to empty after successful submission.
     */
    clearForm() {
      this.form = {
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        password2: "",
      };
      this.valid = false; // Reset form validation
    },
  },
};
</script>

<style scoped>
/* Card styling for registration */
.register {
  border-radius: 12px; /* Rounded corners */
  padding: 40px; /* Inner padding */
  margin: 100px auto; /* Center the card vertically */
  text-align: center; /* Center text */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #ffffff; /* White background */
}

/* Registration card title styling */
.register-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
  text-align: center;
}
/* Custom close icon styling */
.custom-close-icon {
  font-size: 28px;
  color: #ffffff !important;
  cursor: pointer;
  margin-left: auto;
}

/* Align text to the left in error messages */
.text-left {
  text-align: left;
}

/* Styling for password guidelines */
.password-guidelines {
  color: #888;
  font-size: 0.9em;
  margin-top: 5px;
}

/* Register button styling */
.register-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  margin-left: 1%;
  padding: 10px 20px;
  background-color: #6200ee; /* MD3 Primary */
  color: white;
  border: none;
  border-radius: 30px; /* Rounded corners */
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

/* Hover effect for register button */
.register-btn:hover {
  background-color: #3700b3; /* MD3 Hover color */
}

/* Success alert styling */
.success-alert {
  background-color: #e6ffed;
  color: #388e3c;
}
</style>
