<template>
  <v-container max-width="600px">
    <v-card elevation="3" class="password-card">
      <v-card-title class="mb-4 password-title">Change Password</v-card-title>
      <v-card-text>
        <!-- Current password input -->
        <v-text-field
          v-model="oldPassword"
          label="Current Password"
          :type="showOldPassword ? 'text' : 'password'"
          outlined
          class="mb-4"
          :rules="[rules.required('Current password is required.')]"
          prepend-inner-icon="mr-3 mdi-lock"
          :append-inner-icon="
            showOldPassword ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
          "
          @click:append-inner="toggleOldPasswordVisibility"
        ></v-text-field>

        <!-- New password input -->
        <v-text-field
          v-model="newPassword"
          label="New Password"
          :type="showNewPassword ? 'text' : 'password'"
          outlined
          class="mb-4"
          :rules="[
            rules.required('New password is required.'),
            rules.passwordStrength,
          ]"
          prepend-inner-icon="mr-3 mdi-lock"
          :append-inner-icon="
            showNewPassword ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
          "
          @click:append-inner="toggleNewPasswordVisibility"
        ></v-text-field>

        <!-- Password guidelines -->
        <small class="mb-2 password-guidelines">
          Must have atleast 1 Capital letter.Must have atleast 1 Special
          character.Must have at least 1 number.Password must be at least 8
          characters long and should not be entirely numeric.
        </small>

        <!-- Confirm new password input -->
        <v-text-field
          v-model="confirmNewPassword"
          label="Confirm New Password"
          :type="showConfirmPassword ? 'text' : 'password'"
          outlined
          class="mt-2"
          :rules="[
            rules.required('Please confirm your new password.'),
            rules.matchingPassword,
          ]"
          prepend-inner-icon="mr-3 mdi-lock"
          :append-inner-icon="
            showConfirmPassword ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
          "
          @click:append-inner="toggleConfirmPasswordVisibility"
        ></v-text-field>
      </v-card-text>

      <!-- Save Changes Button -->
      <v-card-actions class="button-container">
        <v-btn
          color="primary"
          class="save-button"
          @click="handleChangePassword"
          :loading="loading"
          block
          large
          :disabled="!isFormValid || loading"
        >
          <v-icon left>mdi-content-save</v-icon> Save Changes
        </v-btn>
      </v-card-actions>

      <!-- Success Alert -->
      <v-alert
        v-if="alerts.success"
        color="success"
        class="alert success-alert mt-3"
        border="start"
        title="Success"
        variant="elevated"
      >
        <template #append>
          <v-icon
            large
            class="custom-close-icon"
            @click="alerts.success = false"
          >
            mdi-close-circle
          </v-icon>
        </template>
        {{ alerts.success }}
      </v-alert>

      <!-- Error Alert -->
      <v-alert
        v-if="alerts.error"
        color="error"
        class="alert error-alert"
        border="start"
        title="Error"
        variant="elevated"
      >
        <template #append>
          <v-icon large class="custom-close-icon" @click="alerts.error = false">
            mdi-close-circle
          </v-icon>
        </template>
        {{ alerts.error }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "ChangePasswordComponent",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      loading: false,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      alerts: {
        success: "",
        error: "",
      },
      rules: {
        required: (message) => (value) => !!value || message,
        passwordStrength: (value) =>
          value.length >= 8 || "Password must be at least 8 characters",
        matchingPassword: (value) =>
          value === this.newPassword || "Passwords do not match",
      },
    };
  },
  computed: {
    isFormValid() {
      return (
        this.oldPassword &&
        this.newPassword &&
        this.confirmNewPassword &&
        this.newPassword === this.confirmNewPassword &&
        this.newPassword.length >= 8
      );
    },
  },
  methods: {
    toggleOldPasswordVisibility() {
      this.showOldPassword = !this.showOldPassword;
    },
    toggleNewPasswordVisibility() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async handleChangePassword() {
      this.alerts.success = "";
      this.alerts.error = "";

      if (this.newPassword !== this.confirmNewPassword) {
        this.alerts.error = "Passwords do not match";
        return;
      }

      this.loading = true;

      try {
        const response = await api.put(
          "/api/auth/settings/change-password/",
          {
            old_password: this.oldPassword,
            new_password: this.newPassword,
            confirm_new_password: this.confirmNewPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        this.alerts.success = `${response.data.detail}. Redirecting to edit user profile...`;

        setTimeout(() => {
          this.$router.push({ name: "EditUserProfile" });
        }, 3000);
      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data;

          // If the errorData is an object, join the error messages; otherwise, use it directly
          if (typeof errorData === "object") {
            // Collect all error messages into a single string
            this.alerts.error = Object.values(errorData).flat().join(", ");
          } else {
            this.alerts.error =
              errorData || "Error changing password. Please try again.";
          }
        } else {
          this.alerts.error = "Error changing password. Please try again.";
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Card styling for the password change form */
.password-card {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

/* Form title styling */
.password-title {
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
  font-weight: bold;
}

/* Center button container */
.button-container {
  display: flex;
  justify-content: center;
}

/* Save button styling with white text and icon */
.save-button {
  width: 100%;
  max-width: 400px;
  background-color: #6200ee;
  color: white !important;
  border-radius: 30px;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for save button */
.save-button:hover {
  background-color: #3700b3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Success alert styling */
.success-alert {
  background-color: #e6ffed;
  color: #388e3c;
  text-align: left;
}

/* Error alert styling */
.error-alert {
  background-color: #ffebee;
  color: #d32f2f;
  text-align: left;
}

/* Custom styling for close icon inside alerts */
.custom-close-icon {
  font-size: 24px;
  color: #ffffff !important;
  cursor: pointer;
}

/* Styling for password guidelines */
.password-guidelines {
  display: block;
  margin-top: 5px;
  color: gray;
  font-size: 0.9em;
  text-align: left;
}
</style>
