<template>
  <div class="data-visualization-comparison">
    <h1 class="title">Generate Report: Single Player</h1>

    <!-- Team Selection Dropdown -->
    <div class="styled-container">
      <h3>Select Team:</h3>
      <div class="dropdown-container">
        <v-select
          v-model="selectedTeamId"
          :items="teams"
          item-title="team_name"
          item-value="id"
          label="Choose Team"
          outlined
          dense
          no-data-text="No Teams Available"
          @change="resetPlayerAndSessions"
        ></v-select>
      </div>
    </div>
    <!-- Search Bar -->
    <div
      v-if="selectedTeamId && playersForTeam.length > 0"
      class="styled-container"
    >
      <v-text-field
        v-model="search"
        density="compact"
        label="Search Players"
        prepend-inner-icon="mdi-magnify"
        variant="solo-filled"
        outlined
        hide-details
        single-line
        class="search-bar"
      ></v-text-field>

      <!-- Players Table -->
      <div v-if="filteredPlayers.length > 0" class="players-table-container">
        <v-data-table
          v-model="selectedPlayerId"
          :headers="tableHeaders"
          :items="filteredPlayers"
          density="compact"
          item-value="id"
          class="elevation-2 players-table"
          :items-per-page="20"
          hide-default-footer
          select-strategy="single"
          show-select
        >
          <template #no-data>
            <div class="no-data-message">No players found for this team.</div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- No Players Alert -->
    <v-alert
      v-if="selectedTeamId && playersForTeam.length === 0"
      type="warning"
      elevation="2"
      class="no-players-alert"
      border="left"
    >
      No players on this team.
    </v-alert>

    <!-- No Matching Search Results Alert -->
    <v-alert
      v-if="filteredPlayers.length === 0 && playersForTeam.length > 0"
      type="warning"
      elevation="2"
      class="no-players-alert"
      border="left"
    >
      No players match your search.
    </v-alert>

    <!-- Training Session Selection -->
    <div
      v-if="selectedPlayerId > 0"
      class="training-session-section styled-container mt-5"
    >
      <h3>Select Training Session:</h3>
      <div class="dropdown-container">
        <v-select
          v-model="selectedSingleSessionId"
          :items="getPlayerTrainingSessions(selectedPlayerId)"
          item-title="session_date"
          item-value="id"
          label="Choose Training Session"
          outlined
          no-data-text="No training sessions available for this player."
          dense
        ></v-select>
      </div>
    </div>

    <!-- Export Format Selection -->
    <div
      v-if="selectedSingleSessionId && selectedPlayerId > 0"
      class="export-section"
    >
      <h3>Select Export Format:</h3>
      <div class="dropdown-container">
        <v-select
          v-model="selectedExportFormat"
          :items="exportFormats"
          item-title="name"
          item-value="value"
          label="Choose Export Format"
          outlined
          dense
        ></v-select>
      </div>
      <div class="button-container">
        <v-btn
          v-if="selectedSingleSessionId && selectedExportFormat"
          @click="generateReport"
          class="md3-button"
          color="primary"
          dark
        >
          <v-icon left>mdi-file-chart</v-icon> Generate Report
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "DataVisualizationComponent",
  data() {
    return {
      search: "",
      selectedTeamId: null, // Selected team ID
      selectedPlayerId: null, // Selected player ID for single player report
      selectedSingleSessionId: null, // Selected training session ID for single player report
      selectedExportFormat: "pdf", // Selected export format
      teams: [], // List of teams fetched from the API
      players: [], // List of players fetched from the API
      trainingSessions: {}, // Training sessions keyed by player ID
      exportFormats: [
        { name: "JSON", value: "json" },
        { name: "CSV", value: "csv" },
        { name: "PDF", value: "pdf" },
      ], // Options for export formats
      sortBy: [{ value: "player_number", order: "asc" }], // Sorting configuration for the table
      tableHeaders: [
        { title: "Player Number", value: "player_number", sortable: true },
        { title: "First Name", value: "name", sortable: true },
        { title: "Last Name", value: "last_name", sortable: true },
        { title: "Position", value: "position", sortable: true },
      ],
    };
  },
  computed: {
    playersForTeam() {
      if (!this.selectedTeamId) return [];
      return this.players.filter(
        (player) => player.team === this.selectedTeamId
      );
    },

    filteredPlayers() {
      if (!this.selectedTeamId) {
        console.log("No team selected. Returning empty players list.");
        return [];
      }

      // Filter players by team
      const teamFiltered = this.players.filter(
        (player) => player.team === this.selectedTeamId
      );
      console.log(
        `Filtered players for team ${this.selectedTeamId}:`,
        teamFiltered
      );

      // Apply search filter if search query exists
      if (this.search) {
        const lowerCaseSearch = this.search.toLowerCase();
        const searchFiltered = teamFiltered.filter((player) =>
          player.name.toLowerCase().includes(lowerCaseSearch)
        );
        console.log(
          `Search filtered players for query "${this.search}":`,
          searchFiltered
        );
        return searchFiltered;
      }

      // Return team-filtered players if no search query
      return teamFiltered;
    },

    getPlayerTrainingSessions() {
      return (playerId) => {
        const sessions = this.trainingSessions[playerId] || [];
        console.log(
          `Computed: Training Sessions for Player ID ${playerId}:`,
          sessions
        );
        return sessions;
      };
    },
  },
  async mounted() {
    console.log("Lifecycle: Mounted - Fetching initial data...");
    await this.fetchTeams();
    console.log("Teams fetched in mounted:", this.teams);
    await this.fetchPlayers();
    console.log("Players fetched in mounted:", this.players);
  },
  watch: {
    selectedTeamId(newTeamId, oldTeamId) {
      console.log(
        `Watcher: selectedTeamId changed from ${oldTeamId} to ${newTeamId}`
      );
      this.players = []; // Clear players list
      this.resetPlayerAndSessions();
      if (newTeamId) {
        this.fetchPlayersForTeam(newTeamId);
      }
    },
    selectedPlayerId(newPlayerId, oldPlayerId) {
      console.log(
        `Watcher: selectedPlayerId changed from ${oldPlayerId} to ${newPlayerId}`
      );
      this.selectedSingleSessionId = null;
      if (!newPlayerId || newPlayerId <= 0) {
        console.log("No player selected. Clearing training sessions...");
        this.selectedSingleSessionId = null;
        this.trainingSessions = {}; // Clear training sessions
        this.clearTrainingSessions(); // Clear all training sessions
      } else {
        console.log(`Fetching training sessions for Player ID ${newPlayerId}`);
        this.fetchTrainingSessions(newPlayerId);
      }
    },
  },
  methods: {
    clearDropdown() {
      console.log("Clearing training session dropdown...");
      this.selectedSingleSessionId = null; // Reset dropdown selection
    },
    clearTrainingSessions() {
      console.log("Clearing all training sessions...");
      this.trainingSessions = {}; // Completely reset the training sessions object
    },
    // Or, to clear only specific player data:
    clearTrainingSessionsForPlayer(playerId) {
      console.log(`Clearing training sessions for Player ID: ${playerId}`);
      if (this.trainingSessions[playerId]) {
        delete this.trainingSessions[playerId]; // Remove the player's sessions
      }
    },
    async fetchTeams() {
      console.log("Method: fetchTeams - Starting...");
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get("/api/team/teams/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.teams = response.data;
        console.log(
          "Method: fetchTeams - Teams fetched successfully:",
          response.data
        );
      } catch (error) {
        console.error("Method: fetchTeams - Error fetching teams:", error);
      }
    },
    async fetchPlayers() {
      console.log("Method: fetchPlayers - Starting...");
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get("/api/players/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.players = response.data;
        console.log(
          "Method: fetchPlayers - Players fetched successfully:",
          response.data
        );
      } catch (error) {
        console.error("Method: fetchPlayers - Error fetching players:", error);
      }
    },
    async fetchPlayersForTeam(teamId) {
      console.log(`Fetching players for team ID: ${teamId}`);
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(`/api/players/?team_id=${teamId}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Verify the API response
        const players = response.data;
        console.log("Players returned by API:", players);

        // Manually filter as a fallback
        const filteredPlayers = players.filter(
          (player) => player.team === teamId
        );
        if (filteredPlayers.length !== players.length) {
          console.warn(
            `API returned unexpected players. Expected only team ${teamId}.`
          );
        }
        this.players = filteredPlayers; // Use filtered data
        console.log(`Filtered players for team ${teamId}:`, this.players);
      } catch (error) {
        console.error("Error fetching players:", error);
        this.players = [];
      }
    },
    async fetchTrainingSessions(playerId) {
      console.log(
        `Method: fetchTrainingSessions - Fetching sessions for Player ID: ${playerId}`
      );
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(
          `/api/training-sessions/?playerId=${playerId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        this.trainingSessions = {
          ...this.trainingSessions,
          [playerId]: response.data,
        };
        console.log(
          `Method: fetchTrainingSessions - Sessions for Player ID ${playerId} fetched:`,
          response.data
        );
      } catch (error) {
        console.error(
          `Method: fetchTrainingSessions - Error fetching sessions for Player ID ${playerId}:`,
          error
        );
        this.trainingSessions = {
          ...this.trainingSessions,
          [playerId]: [],
        };
      }
    },
    resetPlayerAndSessions() {
      console.log(
        "Method: resetPlayerAndSessions - Clearing player and session selections..."
      );
      this.selectedPlayerId = null;
      this.selectedSingleSessionId = null;
      this.trainingSessions = {};
    },
    async generateReport() {
      console.log("Method: generateReport - Starting...");
      if (!this.selectedPlayerId || !this.selectedSingleSessionId) {
        console.error(
          "Method: generateReport - Missing player or session selection."
        );
        return;
      }
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(
          `/api/data-visualization/export-player/${this.selectedPlayerId}/export/?sessionId=${this.selectedSingleSessionId}&format=${this.selectedExportFormat}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
          }
        );
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `report.${this.selectedExportFormat}`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        console.log("Method: generateReport - Report generated successfully.");
      } catch (error) {
        console.error(
          "Method: generateReport - Error generating report:",
          error
        );
      }
    },
  },
};
</script>

<style scoped>
/* Container to center the button */
.button-container {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  margin-top: 20px; /* Add spacing above the button */
}
/* Add a styled container similar to the export section */
.styled-container {
  margin-top: 25px;
  background-color: #ffffff; /* White background */
  padding: 15px;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
/* Main container styling */
.data-visualization-comparison {
  max-width: 700px;
  margin: 117px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

/* Title styling */
.title {
  font-family: "Roboto", sans-serif;
  color: #333;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

/* Section headers */
h3 {
  font-family: "Roboto", sans-serif;
  color: #6200ee;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}

/* Dropdown container styling */
.dropdown-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Search bar styling */
.search-bar {
  margin-top: 15px;
}

/* Vuetify select input styling */
.v-select {
  background-color: #fff; /* White background for dropdowns */
  border: 1px solid #dcdcdc; /* Light gray border for dropdown */
  border-radius: 8px; /* Rounded corners */
  box-shadow: none; /* Removed default shadow */
  margin: 0; /* Eliminate extra margin around the dropdown */
  padding: 0; /* Remove internal padding */
  width: 100%; /* Ensure full width of the dropdown */
  transition: border-color 0.3s ease; /* Smooth transition for focus */
}

.v-select:hover {
  border-color: #6200ee; /* Highlight with MD3 primary color on hover */
}

.v-select:focus {
  outline: none;
  border-color: #6200ee; /* Primary color on focus */
}

/* Styling for the dropdown menu */
.v-menu__content {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove internal padding */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for dropdown */
  border-radius: 8px; /* Match border radius with the select component */
}

/* Styling for v-list inside the dropdown menu */
.v-list {
  padding: 0; /* Remove extra padding inside the list */
}

.v-list-item {
  padding: 8px 16px; /* Compact padding for dropdown items */
}

.v-list-item:hover {
  background-color: rgba(
    98,
    0,
    238,
    0.1
  ); /* Subtle hover effect for list items */
}

/* Alert for No Players Available */
.no-players-alert {
  color: #e74c3c; /* MD3 error red */
  font-size: 1.1em;
  margin-top: 50px;
  text-align: center;
}

/* Players table container styling */
.players-table-container {
  margin-top: 20px;
}

/* Data table styling */
.v-data-table {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for elevation */
}
.v-data-table .v-data-table__wrapper {
  border-radius: 8px; /* Match border radius */
}

.v-data-table__checkbox {
  color: #6200ee; /* Primary color for checkboxes */
}

/* No data message inside the table */
.no-data-message {
  text-align: center;
  color: #666;
  font-size: 16px;
}

/* Selected player info container */
.selected-players-info {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Individual player item in the list */
.player-item {
  margin-bottom: 20px;
}

/* Player info section styling */
.player-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

/* Player text styling */
.player-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

/* Dropdown for training sessions */
.training-session-dropdown {
  margin-top: 10px;
}

/* Alert styling */
.v-alert {
  margin-top: 16px;
  text-align: center;
}

/* Export section styling */
.export-section {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Button styling */
.md3-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #6200ee;
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.md3-button:hover {
  background-color: #3700b3;
}

.md3-button v-icon {
  margin-right: 8px;
}
</style>
