<template>
  <v-container class="file-import-container" max-width="600px">
    <v-card outlined class="file-import-card">
      <v-card-title class="headline-small text-h5 file-import-title">
        File Import
      </v-card-title>

      <v-card-text>
        <!-- Download Template Button -->
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              block
              large
              class="mt-4 mb-4 download-btn"
              @click="downloadTemplate"
              variant="elevated"
            >
              <v-icon left>mr-1 mdi-download</v-icon>
              Download Template
            </v-btn>
          </v-col>
        </v-row>

        <!-- File Upload Form -->
        <v-form @submit.prevent="uploadFile">
          <v-row>
            <v-col>
              <v-file-input
                label="Choose file"
                v-model="file"
                accept=".csv, .xlsx, .json"
                dense
                outlined
                required
                prepend-icon="mdi-file"
                class="mt-7 mb-1"
              ></v-file-input>
            </v-col>
          </v-row>

          <!-- Instruction Text -->
          <v-row>
            <v-col>
              <p class="mb-1 file-instructions">
                Please upload a file in Excel (.xlsx), CSV, or JSON format. Make
                sure the file follows the format specified in the template.
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                type="submit"
                color="primary"
                block
                large
                class="mt-4 upload-btn"
                :disabled="isUploading"
                variant="elevated"
              >
                <v-icon left>mr-1 mdi-upload</v-icon>
                Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <!-- Error Alert -->
        <v-alert
          v-if="showErrors"
          color="error"
          class="mt-3 custom-alert"
          close-label="Close Alert"
          border="start"
          title="Error Alert"
          variant="elevated"
        >
          <template #append>
            <v-icon large class="custom-close-icon" @click="showErrors = false">
              mdi-close-circle
            </v-icon>
          </template>
          <div v-for="(error, index) in errors" :key="index" class="text-left">
            {{ error }}
          </div>
        </v-alert>

        <!-- Success Alert -->
        <v-alert
          v-if="showMessage"
          color="success"
          class="mt-3 custom-alert text-left"
          close-label="Close Alert"
          border="start"
          title="Success Alert"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="showMessage = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          {{ message }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "FileImportComponent",
  data() {
    return {
      file: null,
      errors: [],
      message: "",
      showErrors: false,
      showMessage: false,
      isUploading: false,
    };
  },
  methods: {
    async uploadFile() {
      this.clearMessages();
      if (!this.file) {
        this.displayError("Please select a file to upload.");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.file);
      this.isUploading = true;

      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.post("/import/upload/", formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 201 && response.data.success) {
          this.displayMessage(response.data.success);
        } else if (response.status === 207 && response.data.errors) {
          this.errors = response.data.errors;
          this.showErrors = true;
        } else if (response.data.error) {
          this.displayError(response.data.error);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.displayError(error.response.data.error);
        } else {
          this.displayError("An error occurred during file upload.");
        }
      } finally {
        this.isUploading = false;
      }
    },
    async downloadTemplate() {
      try {
        const response = await api.get("/import/download-template/", {
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "import_template.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading template:", error);
        this.displayError("Failed to download template.");
      }
    },
    /**
     * Displays an error message in the alert.
     * @param {string} message - The error message to display
     */
    displayError(message) {
      this.errors = [message];
      this.showErrors = true;
    },
    /**
     * Displays a success message in the alert.
     * @param {string} message - The success message to display
     */
    displayMessage(message) {
      this.message = message;
      this.showMessage = true;
    },
    /**
     * Clears all messages and hides alerts.
     */
    clearMessages() {
      this.errors = [];
      this.message = "";
      this.showErrors = false;
      this.showMessage = false;
    },
  },
};
</script>

<style scoped>
.file-import-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 100px;
  width: 100%;
}

.file-import-card {
  max-width: 600px;
  width: 100%;
  padding: 30px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.file-import-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 16px;
}

.file-instructions {
  font-size: 1.2rem;
  color: #666;
  margin-top: -30px;
}

.download-btn,
.upload-btn {
  background-color: #6200ee;
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 1.1em;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.download-btn:hover,
.upload-btn:hover {
  background-color: #3700b3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.custom-close-icon {
  font-size: 28px;
  color: #ffffff !important;
  cursor: pointer;
  margin-left: auto;
}

.text-left {
  text-align: left;
}
</style>
