/* prettier-ignore */
<template>
  <v-container class="training-sessions" max-width="800px">
    <div class="compare-results">
      <!-- Styled back button with Vuetify MD3 -->
      <v-btn
        color="primary"
        dark
        @click="goBackToManageTeam"
        class="back-button"
      >
        <v-icon left>mdi-arrow-left</v-icon> Return to Manage Team
      </v-btn>

      <h1>Comparison Results</h1>

      <div v-if="loading">Loading...</div>
      <div v-else>
        <!-- Player Info and Comparison Table -->
        <h2>
          {{ player1.name }} {{ player1.last_name }} (Team: {{ team1Name }},
          Position: {{ player1.position }})
        </h2>
        <h2>
          {{ player2.name }} {{ player2.last_name }} (Team: {{ team2Name }},
          Position: {{ player2.position }})
        </h2>

        <table class="session-details-table">
          <thead>
            <tr>
              <th>Metric</th>
              <th>{{ player1.name }}'s Value</th>
              <th>{{ player2.name }}'s Value</th>
              <th>Difference</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Weight Lift Impact</td>
              <td
                :class="
                  boldClass(
                    session1.weight_lift_impact,
                    session2.weight_lift_impact
                  )
                "
              >
                {{ session1.weight_lift_impact }}
              </td>
              <td
                :class="
                  boldClass(
                    session2.weight_lift_impact,
                    session1.weight_lift_impact
                  )
                "
              >
                {{ session2.weight_lift_impact }}
              </td>
              <td
                :class="
                  differenceClass(
                    session1.weight_lift_impact,
                    session2.weight_lift_impact
                  )
                "
              >
                {{
                  calculatePercentageDifference(
                    session1.weight_lift_impact,
                    session2.weight_lift_impact
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Lower Body Power</td>
              <td
                :class="
                  boldClass(
                    session1.lower_body_power,
                    session2.lower_body_power
                  )
                "
              >
                {{ session1.lower_body_power }}
              </td>
              <td
                :class="
                  boldClass(
                    session2.lower_body_power,
                    session1.lower_body_power
                  )
                "
              >
                {{ session2.lower_body_power }}
              </td>
              <td
                :class="
                  differenceClass(
                    session1.lower_body_power,
                    session2.lower_body_power
                  )
                "
              >
                {{
                  calculatePercentageDifference(
                    session1.lower_body_power,
                    session2.lower_body_power
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Upper Body Power</td>
              <td
                :class="
                  boldClass(
                    session1.upper_body_power,
                    session2.upper_body_power
                  )
                "
              >
                {{ session1.upper_body_power }}
              </td>
              <td
                :class="
                  boldClass(
                    session2.upper_body_power,
                    session1.upper_body_power
                  )
                "
              >
                {{ session2.upper_body_power }}
              </td>
              <td
                :class="
                  differenceClass(
                    session1.upper_body_power,
                    session2.upper_body_power
                  )
                "
              >
                {{
                  calculatePercentageDifference(
                    session1.upper_body_power,
                    session2.upper_body_power
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Serve Speed</td>
              <td
                :class="boldClass(session1.serve_speed, session2.serve_speed)"
              >
                {{ session1.serve_speed }}
              </td>
              <td
                :class="boldClass(session2.serve_speed, session1.serve_speed)"
              >
                {{ session2.serve_speed }}
              </td>
              <td
                :class="
                  differenceClass(session1.serve_speed, session2.serve_speed)
                "
              >
                {{
                  calculatePercentageDifference(
                    session1.serve_speed,
                    session2.serve_speed
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Serve Accuracy</td>
              <td
                :class="
                  boldClass(session1.serve_accuracy, session2.serve_accuracy)
                "
              >
                {{ session1.serve_accuracy }}
              </td>
              <td
                :class="
                  boldClass(session2.serve_accuracy, session1.serve_accuracy)
                "
              >
                {{ session2.serve_accuracy }}
              </td>
              <td
                :class="
                  differenceClass(
                    session1.serve_accuracy,
                    session2.serve_accuracy
                  )
                "
              >
                {{
                  calculatePercentageDifference(
                    session1.serve_accuracy,
                    session2.serve_accuracy
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>STIix Index</td>
              <td
                :class="boldClass(session1.stiix_index, session2.stiix_index)"
              >
                {{ session1.stiix_index }}
              </td>
              <td
                :class="boldClass(session2.stiix_index, session1.stiix_index)"
              >
                {{ session2.stiix_index }}
              </td>
              <td
                :class="
                  differenceClass(session1.stiix_index, session2.stiix_index)
                "
              >
                {{
                  calculatePercentageDifference(
                    session1.stiix_index,
                    session2.stiix_index
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Toggle Button Group for Charts -->
        <div class="chart-toggle-group">
          <v-btn-toggle v-model="selectedChart" mandatory>
            <v-btn value="line">Line Chart</v-btn>
            <v-btn value="spider">Spider Chart</v-btn>
          </v-btn-toggle>
        </div>
        <!-- Subtext for Zoom Information -->
        <p class="zoom-info-text mt-5">
          You can zoom in or out of the line chart using your mouse scroll wheel
          or touch gestures.
        </p>

        <!-- Chart Display -->
        <div v-if="!loading" class="chart-container">
          <div v-if="selectedChart === 'line'">
            <line-chart
              ref="lineChart"
              :data="lineChartData"
              :options="lineChartOptions"
            />
          </div>
          <div v-else-if="selectedChart === 'spider'">
            <radar-chart :data="radarChartData" :options="radarChartOptions" />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
// import api from "api";
import api from "@/axios.js";
import RadarChart from "./RadarChart.vue"; // Import radar chart component
import LineChart from "./LineChart.vue"; // Import line chart component

export default {
  name: "CompareResultsComponent",
  components: {
    RadarChart, // Register radar chart component
    LineChart, // Register line chart component
  },
  data() {
    return {
      player1: {}, // Initialize as empty to avoid null errors
      player2: {},
      player1Sessions: [], // Array to store all sessions for player 1
      player2Sessions: [], // Array to store all sessions for player 2
      session1: null,
      session2: null,
      team1Name: "Unknown",
      team2Name: "Unknown",
      teamId: null, // Store team ID
      stiixIndex1: 0,
      stiixIndex2: 0,
      radarChartData: null,
      radarChartOptions: null,
      lineChartData: null,
      lineChartOptions: null,
      loading: true,
      selectedChart: "line", // Default to "line" chart
    };
  },

  async mounted() {
    // Extract query parameters for player and session IDs from the route
    const { player1Id, player2Id, session1Id, session2Id, teamId } =
      this.$route.query;
    this.teamId = teamId; // Store team ID for future use
    console.log(`Team ID received: ${this.teamId}`); // Log team ID to verify
    try {
      const accessToken = localStorage.getItem("access_token"); // Retrieve access token for authorization

      // Fetch player 1 data from API
      const player1Response = await api.get(`/api/players/${player1Id}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      this.player1 = player1Response.data;

      // Fetch player 2 data from API
      const player2Response = await api.get(`/api/players/${player2Id}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      this.player2 = player2Response.data;

      // Fetch session data for player 1
      const session1Response = await api.get(
        `/api/training-sessions/${session1Id}/`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.session1 = session1Response.data;

      // Fetch session data for player 2
      const session2Response = await api.get(
        `/api/training-sessions/${session2Id}/`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.session2 = session2Response.data;

      // Fetch team names for both players
      const team1Response = await api.get(
        `/api/team/teams/${this.player1.team}/`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.team1Name = team1Response.data.team_name;

      const team2Response = await api.get(
        `/api/team/teams/${this.player2.team}/`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.team2Name = team2Response.data.team_name;

      // Fetch all sessions for both players
      await this.fetchAllTrainingSessions();
      // Dynamically calculate chart size based on viewport width
      const isMobile = window.innerWidth < 700;

      // Prepare radar chart data for player comparison
      this.radarChartData = {
        labels: [
          "Weight Lift Impact",
          "Lower Body Power",
          "Upper Body Power",
          "Serve Speed",
          "Serve Accuracy",
        ],
        datasets: [
          {
            label: `${this.player1.name} ${this.player1.last_name}`, // Label for player 1
            data: [
              this.session1.weight_lift_impact,
              this.session1.lower_body_power,
              this.session1.upper_body_power,
              this.session1.serve_speed,
              this.session1.serve_accuracy,
            ],
            borderColor: "rgba(54, 162, 235, 1)", // Blue line for player 1
            borderWidth: 1, // Make the line slightly thicker for emphasis
            pointBackgroundColor: "rgba(54, 162, 235, 1)", // Blue fill for points
            pointBorderColor: "#ffffff", // White border for points
            pointRadius: isMobile ? 2 : 6, // Larger points for better visibility
            pointHoverRadius: isMobile ? 4 : 8, // Hover size for better interactivity
            backgroundColor: "rgba(54, 162, 235, 0.2)", // Light blue fill for area
          },
          {
            label: `${this.player2.name} ${this.player2.last_name}`, // Label for player 2
            data: [
              this.session2.weight_lift_impact,
              this.session2.lower_body_power,
              this.session2.upper_body_power,
              this.session2.serve_speed,
              this.session2.serve_accuracy,
            ],
            borderColor: "rgba(255, 99, 132, 1)", // Red line for player 2
            borderWidth: 1, // Make the line slightly thicker for emphasis
            pointBackgroundColor: "rgba(255, 99, 132, 1)", // Red fill for points
            pointBorderColor: "#ffffff", // White border for points
            pointRadius: isMobile ? 2 : 6, // Larger points for better visibility
            pointHoverRadius: isMobile ? 4 : 8, // Hover size for better interactivity
            backgroundColor: "rgba(255, 99, 132, 0.2)", // Light red fill for area
          },
        ],
      };

      this.loading = false; // Set loading to false after all data is loaded
    } catch (error) {
      console.error("Error loading comparison data:", error); // Log any errors during data fetching
      this.loading = false; // Set loading to false if an error occurs
    }
  },

  methods: {
    goBackToManageTeam() {
      this.$router.push({ name: "ManageTeam" });
    },
    differenceClass(value1, value2) {
      if (value1 > value2) {
        return "higher-difference"; // Positive difference
      } else if (value1 < value2) {
        return "lower-difference"; // Negative difference
      } else {
        return ""; // No class for equal values
      }
    },

    prepareLineChartData() {
      // Combine and sort all unique session dates from both players
      const labels = [
        ...new Set([
          ...this.player1Sessions.map((session) => session.session_date),
          ...this.player2Sessions.map((session) => session.session_date),
        ]),
      ].sort((a, b) => new Date(a) - new Date(b)); // Sort dates chronologically

      // Map each date to the corresponding STIix index for player 1
      const player1Data = labels.map((date) => {
        const session = this.player1Sessions.find(
          (s) => s.session_date === date
        );
        return session ? parseFloat(session.stiix_index) : null; // Use null for missing data
      });

      // Map each date to the corresponding STIix index for player 2
      const player2Data = labels.map((date) => {
        const session = this.player2Sessions.find(
          (s) => s.session_date === date
        );
        return session ? parseFloat(session.stiix_index) : null; // Use null for missing data
      });

      // Update the line chart data structure
      this.lineChartData = {
        labels, // Use sorted session dates as x-axis labels
        datasets: [
          {
            label: `${this.player1.name} ${this.player1.last_name}`,
            data: player1Data,
            borderColor: "rgba(54, 162, 235, 1)", // Blue line
            backgroundColor: "rgba(54, 162, 235, 0)", // Transparent background
            fill: false, // Disable fill under the line
            tension: 0.3, // Smooth line
            pointBackgroundColor: "#ffffff", // White point fill
            pointBorderColor: "#6200ee", // Blue point border
            radius: 3,
            hoverRadius: 5,
            borderWidth: 2,
            pointRadius: 5, // Larger point size for mobile and desktop
            pointHoverRadius: 8, // Larger hover size for emphasis
          },
          {
            label: `${this.player2.name} ${this.player2.last_name}`,
            data: player2Data,
            borderColor: "rgba(255, 99, 132, 1)", // Red line
            backgroundColor: "rgba(255, 99, 132, 0)", // Transparent background
            fill: false,
            tension: 0.3,
            pointBackgroundColor: "#ffffff",
            pointBorderColor: "#ff4081", // Red point border
            radius: 3,
            hoverRadius: 5,
            borderWidth: 2,
            pointRadius: 5, // Larger point size for mobile and desktop
            pointHoverRadius: 8, // Larger hover size for emphasis
          },
        ],
      };

      this.prepareLineChartOptions();
    },

    prepareLineChartOptions() {
      this.lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: { top: 20, bottom: 20, left: 20, right: 20 },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Training Session Date", // X-axis label
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: 12,
              },
              color: "#333", // Dark grey
            },
            ticks: {
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: 10,
              },
              color: "#333",
            },
            grid: { color: "#cccccc" }, // Light gridlines
          },
          y: {
            title: {
              display: true,
              text: "STIx Value", // Y-axis label
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: 12,
              },
              color: "#333",
            },
            beginAtZero: true,
            ticks: {
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: 12,
              },
              color: "#333",
            },
            grid: { color: "#cccccc" },
          },
        },
        plugins: {
          legend: {
            labels: {
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
              },
              color: "#333333", // Dark grey
            },
          },
          title: {
            display: true,
            text: "STIx Over Time", // Chart title
            font: {
              family: "'Roboto', sans-serif",
              weight: "bold",
              size: 18,
            },
            color: "#333333", // Dark grey
          },
          tooltip: {
            enabled: true,
            backgroundColor: "#ffffff", // Tooltip background
            borderColor: "#6200ee", // Tooltip border
            borderWidth: 1,
            titleColor: "#333", // Tooltip title
            bodyColor: "#333", // Tooltip body text
            callbacks: {
              label: (context) => `STIx Index: ${context.raw}`,
            },
          },
          zoom: {
            pan: { enabled: true, mode: "x" }, // Horizontal panning
            zoom: {
              wheel: { enabled: true }, // Mouse wheel zoom
              pinch: { enabled: true }, // Pinch-to-zoom
              mode: "x", // Horizontal zoom
            },
          },
        },
        elements: {
          line: {
            borderWidth: 2, // Line thickness
          },
          point: {
            radius: 3,
            hoverRadius: 5,
            borderWidth: 2,
            pointRadius: 5, // Larger point size for mobile and desktop
            pointHoverRadius: 8, // Larger hover size for emphasis
          },
        },
      };
    },

    async fetchAllTrainingSessions() {
      const accessToken = localStorage.getItem("access_token");

      try {
        const player1SessionsResponse = await api.get(
          `/api/training-sessions/`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { playerId: this.$route.query.player1Id },
          }
        );
        this.player1Sessions = player1SessionsResponse.data;

        const player2SessionsResponse = await api.get(
          `/api/training-sessions/`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: { playerId: this.$route.query.player2Id },
          }
        );
        this.player2Sessions = player2SessionsResponse.data;

        this.prepareLineChartData();
      } catch (error) {
        console.error("Error fetching training sessions:", error);
      }
    },

    // Calculate the percentage difference between two metric values
    calculatePercentageDifference(value1, value2) {
      if (value1 === undefined || value2 === undefined || value2 === 0) {
        return "N/A"; // Avoid division by zero or undefined values
      }

      const difference = (((value1 - value2) / value2) * 100).toFixed(2);

      return difference > 0
        ? `${difference}% Higher for ${this.player1.name} ${this.player1.last_name}`
        : difference < 0
        ? `${Math.abs(difference)}% Higher for ${this.player2.name} ${
            this.player2.last_name
          }`
        : "No Difference"; // When values are equal
    },
    // Apply bold class if value1 is greater than value2 for visual emphasis
    boldClass(value1, value2) {
      return value1 > value2 ? "bold-value" : "";
    },
    // Navigate back to the ComparePlayers component
    goBackToCompare() {
      this.$router.push({ name: "ComparePlayers" });
    },
  },
};
</script>

<style scoped>
table.session-details-table td.higher-difference {
  background-color: #d4edda !important; /* Light green for positive difference */
  color: #155724 !important; /* Dark green text */
}

table.session-details-table td.lower-difference {
  background-color: #f8d7da !important; /* Light red for negative difference */
  color: #721c24 !important; /* Dark red text */
}
.v-container.training-sessions {
  background-color: #ffffff; /* White background for clarity */
  border-radius: 12px; /* Rounded corners for a modern look */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
  padding: 20px; /* Inner padding */
  margin-top: 100px; /* Lowering the container and centering it */
  margin-bottom: 100px;
  font-family: "Roboto", sans-serif; /* Consistent font family */
}
/* Container for the entire comparison results section */
.compare-results {
  text-align: center; /* Center-align text */
  margin-top: 10px; /* Add space from the top */
}

/* Styling for the "Return to Compare Players" button */
.back-button {
  margin-bottom: 20px; /* Space below the button */
  padding: 10px 20px; /* Padding inside the button for better click area */
  background-color: #6200ee; /* Material Design primary color */
  color: white; /* Text color for contrast */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1.1em; /* Increase font size */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}
.chart-container {
  margin: 30px auto; /* Centers the container and adds spacing */
  height: 600px; /* Adequate height for visualizing charts */
  max-width: 100%; /* Prevents charts from overflowing */
  text-align: center; /* Centers the charts horizontally */
}
/* Hover effect for the back button */
.back-button:hover {
  background-color: #3700b3; /* Darker shade for hover */
}
.session-details-table {
  width: 100%;
  margin: 20px auto;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 12px; /* Rounded corners for MD3 aesthetics */
  overflow: hidden; /* Ensures rounded corners apply */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for elevation */
  font-family: "Roboto", sans-serif;
  background-color: #ffffff; /* White background */
}

.session-details-table th {
  background-color: #6200ee; /* MD3 Primary Color */
  color: #ffffff; /* White text for contrast */
  padding: 16px;
  text-align: left; /* Align text to the left */
  font-weight: 700; /* Bold for headers */
  text-transform: uppercase; /* All caps for headers */
  font-size: 1rem; /* Standard font size */
}

.session-details-table td {
  padding: 16px;
  text-align: left; /* Align text to the left */
  color: #333; /* Dark text for readability */
  font-size: 1rem;
}

.session-details-table tr:nth-child(even) td {
  background-color: #f7f7f7; /* Light grey for alternate rows */
}

.session-details-table tr:nth-child(odd) td {
  background-color: #ffffff; /* White background for odd rows */
}

.session-details-table tr:hover td {
  background-color: #e1e1e1; /* Slightly darker grey for hover effect */
}

.session-details-table tr:last-child td {
  font-weight: bold; /* Bold text for STIix Index row */
  color: #6200ee; /* Highlight the STIix Index row in primary color */
  background-color: #f0efff; /* Slightly tinted background for emphasis */
}

.session-details-table th:first-child,
.session-details-table td:first-child {
  border-top-left-radius: 12px; /* Rounded corners for the first column */
  border-bottom-left-radius: 12px;
}

.session-details-table th:last-child,
.session-details-table td:last-child {
  border-top-right-radius: 12px; /* Rounded corners for the last column */
  border-bottom-right-radius: 12px;
}
</style>
