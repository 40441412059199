<template>
  <v-container max-width="600px">
    <v-card elevation="3" class="username-card">
      <v-card-title class="mb-4 username-title">Change Username</v-card-title>
      <v-card-text>
        <!-- Current password input -->
        <v-text-field
          v-model="oldPassword"
          label="Current Password"
          :type="showPassword ? 'text' : 'password'"
          outlined
          class="mb-4"
          :rules="[rules.required('Current password is required.')]"
          prepend-inner-icon="mr-3 mdi-lock"
          :append-inner-icon="
            showPassword ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
          "
          @click:append-inner="togglePasswordVisibility"
        ></v-text-field>

        <!-- New username input -->
        <v-text-field
          v-model="newUsername"
          label="New Username"
          outlined
          class="mb-4"
          :rules="[rules.required('New username is required.'), rules.username]"
          prepend-inner-icon="mdi-account"
        ></v-text-field>
      </v-card-text>

      <!-- Save Changes Button -->
      <v-card-actions class="button-container">
        <v-btn
          color="primary"
          class="save-button"
          @click="handleChangeUsername"
          :loading="loading"
          block
          large
          :disabled="!isFormValid || loading"
        >
          <v-icon left>mdi-content-save</v-icon> Save Changes
        </v-btn>
      </v-card-actions>

      <!-- Display Multiple Error Alerts -->
      <div v-if="alerts.errors.length" class="mt-3">
        <v-alert
          v-for="(error, index) in alerts.errors"
          :key="index"
          color="error"
          border="start"
          title="Error"
          variant="elevated"
          class="error-alert"
        >
          <template #append>
            <v-icon large class="custom-close-icon" @click="removeAlert(index)">
              mdi-close-circle
            </v-icon>
          </template>
          {{ error }}
        </v-alert>
      </div>

      <!-- Success Alert -->
      <v-alert
        v-if="alerts.success"
        color="success"
        class="mt-3 success-alert"
        border="start"
        title="Success"
        variant="elevated"
      >
        <template #append>
          <v-icon
            large
            class="custom-close-icon"
            @click="alerts.success = false"
          >
            mdi-close-circle
          </v-icon>
        </template>
        {{ alerts.success }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "ChangeUsernameComponent",
  data() {
    return {
      oldPassword: "",
      newUsername: "",
      loading: false,
      showPassword: false,
      alerts: {
        success: "",
        errors: [],
      },
      rules: {
        required: (message) => (value) => !!value || message,
        username: (value) =>
          value.length >= 3 || "Username must be at least 3 characters",
      },
    };
  },
  computed: {
    isFormValid() {
      return (
        this.oldPassword && this.newUsername && this.newUsername.length >= 3
      );
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    removeAlert(index) {
      this.alerts.errors.splice(index, 1);
    },
    async handleChangeUsername() {
      this.alerts.success = "";
      this.alerts.errors = [];

      this.loading = true;

      try {
        const response = await api.put(
          "/api/auth/settings/change-username/",
          {
            old_password: this.oldPassword,
            new_username: this.newUsername,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        this.alerts.success = `${response.data.detail}. Redirecting to edit user profile...`;

        setTimeout(() => {
          this.$router.push({ name: "EditUserProfile" });
        }, 3000);
      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data;

          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              this.alerts.errors.push(...errorData[key]);
            } else {
              this.alerts.errors.push(errorData[key]);
            }
          }
        } else {
          this.alerts.errors.push("Error changing username. Please try again.");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.username-card {
  border-radius: 12px;
  padding: 40px;
  margin: 100px auto;
  text-align: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.username-title {
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
  font-weight: bold;
}

.save-button {
  width: 100%;
  max-width: 400px;
  background-color: #6200ee;
  color: white !important;
  border-radius: 30px;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.save-button:hover {
  background-color: #3700b3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.success-alert,
.error-alert {
  text-align: left;
}

.success-alert {
  background-color: #e6ffed;
  color: #388e3c;
}

.error-alert {
  background-color: #ffebee;
  color: #d32f2f;
}

.custom-close-icon {
  font-size: 24px;
  color: #ffffff !important;
  cursor: pointer;
}
</style>
