/* prettier-ignore */
<template>
  <v-container max-width="600px">
    <v-card elevation="3" class="email-card">
      <!-- Title for the email change form -->
      <v-card-title class="mb-4 email-title">Change Email</v-card-title>
      <v-card-text>
        <!-- Current password input with specific validation message -->
        <v-text-field
          v-model="oldPassword"
          label="Current Password"
          :type="showPassword ? 'text' : 'password'"
          outlined
          class="mb-4"
          :rules="[rules.required('Current password is required.')]"
          prepend-inner-icon="mr-3 mdi-lock"
          :append-inner-icon="
            showPassword ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
          "
          @click:append-inner="togglePasswordVisibility"
          :error-messages="fieldErrors.old_password"
        ></v-text-field>

        <!-- New email input with validation -->
        <v-text-field
          v-model="newEmail"
          label="New Email"
          type="email"
          outlined
          class="mb-4"
          :rules="[rules.required('New email is required.'), rules.email]"
        ></v-text-field>

        <!-- Confirm new email input with validation -->
        <v-text-field
          v-model="confirmNewEmail"
          label="Confirm New Email"
          type="email"
          outlined
          :rules="[
            rules.required('Please confirm your new email.'),
            rules.matchingEmail,
          ]"
          :error-messages="confirmEmailError"
        ></v-text-field>
      </v-card-text>

      <!-- Verify Email Button -->
      <v-card-actions class="button-container">
        <v-btn
          color="primary"
          class="verify-button"
          @click="handleChangeEmail"
          :loading="loading"
          block
          large
          :disabled="!isFormValid || loading"
        >
          <v-icon left>mr-2 mdi-email</v-icon> Verify Email
        </v-btn>
      </v-card-actions>
      <!-- Success Alert -->
      <v-alert
        v-if="alerts.success"
        color="success"
        class="mt-3 success-alert"
        border="start"
        title="Success"
        variant="elevated"
      >
        <template #append>
          <v-icon
            large
            class="custom-close-icon"
            @click="alerts.success = false"
          >
            mdi-close-circle
          </v-icon>
        </template>
        {{ alerts.success }}
      </v-alert>

      <!-- Error Alert for displaying error messages -->
      <v-alert
        v-if="alerts.error"
        color="error"
        class="mt-3 error-alert"
        border="start"
        title="Error"
        variant="elevated"
      >
        <template #append>
          <v-icon large class="custom-close-icon" @click="alerts.error = false">
            mdi-close-circle
          </v-icon>
        </template>
        {{ alerts.error }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
// import api from "api";
import api from "@/axios.js";

export default {
  name: "ChangeEmailComponent",
  data() {
    return {
      oldPassword: "", // Current password input
      newEmail: "", // New email input
      confirmNewEmail: "", // Confirmation of the new email
      loading: false, // Loading state for button
      showPassword: false, // Toggle for current password visibility
      alerts: {
        success: "", // Success message alert
        error: "", // Error message alert
      },
      fieldErrors: {
        old_password: "", // Error for old password field
        confirm_new_email: "", // Error for confirm new email field
      },
      rules: {
        required: (message) => (value) => !!value || message, // Rule to ensure the field is filled
        email: (value) => /.+@.+\..+/.test(value) || "Invalid email format", // Email format rule
        matchingEmail: (value) =>
          value === this.newEmail || "Emails do not match", // Rule to check if emails match
      },
    };
  },
  computed: {
    // Check if form is valid for submission
    isFormValid() {
      return (
        this.oldPassword &&
        this.newEmail &&
        this.confirmNewEmail &&
        this.newEmail === this.confirmNewEmail &&
        /.+@.+\..+/.test(this.newEmail)
      );
    },
    // Dynamic error message for confirming email match
    confirmEmailError() {
      return this.confirmNewEmail && this.confirmNewEmail !== this.newEmail
        ? "Emails do not match"
        : "";
    },
  },
  watch: {
    // Watch for changes in newEmail and confirmNewEmail to trigger real-time validation
    newEmail() {
      this.validateConfirmEmail();
    },
    confirmNewEmail() {
      this.validateConfirmEmail();
    },
  },
  methods: {
    // Toggle visibility of the password field
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    // Clear all error messages
    clearErrors() {
      this.alerts.success = "";
      this.alerts.error = "";
      this.fieldErrors.old_password = "";
      this.fieldErrors.confirm_new_email = "";
    },
    // Validate if new and confirm email match dynamically
    validateConfirmEmail() {
      if (this.confirmNewEmail && this.confirmNewEmail !== this.newEmail) {
        this.fieldErrors.confirm_new_email = "Emails do not match";
      } else {
        this.fieldErrors.confirm_new_email = "";
      }
    },
    // Handle the email change request
    async handleChangeEmail() {
      // Clear existing alerts and errors
      this.clearErrors();
      this.loading = true;

      try {
        // Send request to change email
        const response = await api.put(
          "/api/auth/settings/change-email/",
          {
            old_password: this.oldPassword,
            new_email: this.newEmail,
            confirm_new_email: this.confirmNewEmail,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        // Display success message and reset fields
        this.alerts.success = `${response.data.detail} Redirecting to edit user profile...`;
        this.oldPassword = "";
        this.newEmail = "";
        this.confirmNewEmail = "";
        // Redirect to UserProfileEdit after a short delay
        setTimeout(() => {
          this.$router.push({ name: "EditUserProfile" });
        }, 3000);
      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data;

          // Check for old_password error first and display it
          if (errorData.old_password) {
            this.fieldErrors.old_password = "";
            this.alerts.error = errorData.old_password; // Move "Incorrect password" to v-alert
            return; // Exit if there's an old_password error
          }

          // If old_password is correct, check for new_email errors
          if (errorData.new_email) {
            this.alerts.error = errorData.new_email; // Move "A user with that email already exists." to v-alert
          }

          if (errorData.confirm_new_email) {
            this.fieldErrors.confirm_new_email = errorData.confirm_new_email;
          }

          // Display a generic error if any email-related issues exist
          if (this.alerts.error || this.fieldErrors.confirm_new_email) {
            this.alerts.error =
              this.alerts.error || "Please correct the highlighted fields.";
          }
        } else {
          // Fallback for any other error
          this.alerts.error = "Error changing email. Please try again.";
        }
      } finally {
        // Reset loading state
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Container styling for email change form */
/* Form title styling */
.email-title {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  font-weight: bold;
}
/* Card styling for email change form */
.email-card {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

/* Form title styling */
.email-title {
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
  font-weight: bold;
}

/* Button container styling */
.button-container {
  display: flex;
  justify-content: center;
}

/* Styling for verify button */
.verify-button {
  width: 100%;
  max-width: 400px;
  background-color: #6200ee;
  color: white !important;
  border-radius: 30px;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for verify button */
.verify-button:hover {
  background-color: #3700b3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* Success alert styling */
.success-alert {
  background-color: #e6ffed;
  color: #388e3c;
  text-align: left;
}

/* Error alert styling */
.error-alert {
  background-color: #ffebee;
  color: #d32f2f;
  text-align: left;
}

/* Custom styling for close icon inside alerts */
.custom-close-icon {
  font-size: 24px;
  color: #ffffff !important;
  cursor: pointer;
}
</style>
