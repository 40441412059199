<template>
  <!-- Main container for the dashboard, with a max width for consistent layout -->
  <v-container class="dashboard" max-width="700px">
    <!-- Card component to hold the dashboard content, with MD3 styling and shadow effect -->
    <v-card class="dashboard-card" outlined>
      <!-- Title for the dashboard page, fully centered -->
      <v-card-title class="dashboard-title text-h4">
        <v-icon color="primary" class="mr-2">mdi-view-dashboard</v-icon>
        Dashboard
      </v-card-title>

      <v-card-text>
        <!-- Welcome message displayed if the fullName is available -->
        <p v-if="fullName" class="welcome-message">Welcome, {{ fullName }}!</p>
        <p v-else class="loading-message">Loading user data...</p>

        <!-- Interactive tiles for dashboard actions -->
        <v-row class="action-tiles">
          <v-col cols="12" md="6">
            <!-- Import File tile -->
            <v-card
              class="action-card import-tile"
              outlined
              @click="goToImport"
            >
              <v-card-title>
                <v-icon class="action-icon" color="white"
                  >mdi-file-import</v-icon
                >
              </v-card-title>
              <v-card-text class="action-text">Import File</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <!-- Manage Team tile -->
            <v-card
              class="action-card manage-tile"
              outlined
              @click="goToManageTeam"
            >
              <v-card-title>
                <v-icon class="action-icon" color="white"
                  >mdi-account-group</v-icon
                >
              </v-card-title>
              <v-card-text class="action-text">Manage Team</v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <!-- Generate Single Player Report tile -->
            <v-card
              class="action-card single-report-tile"
              outlined
              @click="goToGenerateReport('single')"
            >
              <v-card-title>
                <v-icon class="action-icon" color="white"
                  >mdi-file-document</v-icon
                >
              </v-card-title>
              <v-card-text class="action-text">
                Generate Single Player Report
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <!-- Generate Player Comparison Report tile -->
            <v-card
              class="action-card comparison-report-tile"
              outlined
              @click="goToGenerateComparisonReport"
            >
              <v-card-title>
                <v-icon class="action-icon" color="white">mdi-chart-bar</v-icon>
              </v-card-title>
              <v-card-text class="action-text">
                Generate Player Comparison Report
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "DashboardComponent",
  data() {
    return {
      firstName: "", // User's first name
      lastName: "", // User's last name
    };
  },
  computed: {
    // Concatenates firstName and lastName into a fullName
    fullName() {
      return `${this.firstName} ${this.lastName}`.trim();
    },
  },
  async mounted() {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      // Redirect to login if the access token is missing
      this.$router.push("/login");
    } else {
      try {
        // Fetch user profile data from the backend
        const response = await api.get("/api/auth/user/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Assign fetched data to component variables
        this.firstName = response.data.first_name;
        this.lastName = response.data.last_name;
      } catch (error) {
        console.error("Error fetching user data:", error);
        this.$router.push("/login");
      }
    }
  },
  methods: {
    goToImport() {
      this.$router.push("/import-file");
    },
    goToManageTeam() {
      this.$router.push("/manage-team");
    },
    goToGenerateReport(reportType) {
      this.$router.push({
        path: "/data-visualization",
        query: { reportType },
      });
    },
    goToGenerateComparisonReport() {
      this.$router.push("/data-visualization-comparison");
    },
    onProfileUpdated(payload) {
      // Update firstName and lastName dynamically if emitted
      this.firstName = payload.firstName || this.firstName;
      this.lastName = payload.lastName || this.lastName;
    },
  },
};
</script>

<style scoped>
/* Main dashboard container with centered content and margin */

/* Dashboard card with MD3 styling and shadow */
.dashboard-card {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

/* Center-aligned dashboard title */
.dashboard-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Welcome message styling */
.welcome-message {
  font-size: 1.6em;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
  margin-bottom: 30px;
}

/* Loading message styling */
.loading-message {
  font-size: 1.4em;
  color: #999;
}

/* Interactive action cards */
.action-tiles {
  margin-top: 30px;
}

.action-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px; /* Reduced padding */
  border-radius: 10px; /* Slightly smaller border radius */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.action-card:hover {
  transform: translateY(-4px); /* Slightly smaller hover effect */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for Import File tile */
.import-tile {
  background: linear-gradient(135deg, #6200ee, #3700b3);
  color: white;
}

/* Styling for Manage Team tile */
.manage-tile {
  background: linear-gradient(135deg, #6200ee, #3700b3);
  color: white;
}

/* Styling for Generate Single Player Report tile */
.single-report-tile {
  background: linear-gradient(135deg, #6200ee, #3700b3);
  color: white;
}

/* Styling for Generate Player Comparison Report tile */
.comparison-report-tile {
  background: linear-gradient(135deg, #6200ee, #3700b3);
  color: white;
}

/* Action card icon styling */
.action-icon {
  font-size: 2.5rem; /* Reduced icon size */
  margin-bottom: 8px; /* Reduced spacing below the icon */
  color: white;
}

/* Action card text styling */
.action-text {
  font-size: 1rem; /* Reduced text size */
  font-weight: 500; /* Slightly lighter weight */
  text-align: center;
  color: white;
}
</style>
