<template>
  <v-app>
    <!-- Taller Top Navigation Bar -->
    <v-app-bar app height="100px" class="app-bar">
      <!-- Back Button -->
      <v-btn
        icon
        :disabled="isBackButtonDisabled"
        @click="goBack"
        class="v-btn"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <!-- Replace this block for the STx button -->
      <v-toolbar-title class="app-title">
        <v-btn v-if="isLoggedIn" text to="/dashboard" class="logo-button">
          <v-icon left>mdi-volleyball</v-icon> STix
        </v-btn>
        <div v-else class="logo-title">
          <v-icon left>mdi-volleyball</v-icon> STix
        </div>
      </v-toolbar-title>

      <!-- Spacer -->
      <v-spacer></v-spacer>

      <!-- Profile Menu for Logged-in Users -->
      <div v-if="isLoggedIn" class="nav-buttons">
        <v-menu offset-y right v-model="menuVisible">
          <template v-slot:activator>
            <v-avatar size="45" class="profile-avatar" @click="toggleMenu">
              <template v-if="profilePicture">
                <img
                  :src="profilePicture"
                  alt="Profile Picture"
                  class="profile-image"
                />
              </template>
              <template v-else>
                <span class="no-avatar-text"></span>
              </template>
            </v-avatar>
          </template>
          <v-list class="dropdown-menu">
            <!-- Display the profile avatar at the top -->
            <v-list-item>
              <v-avatar size="70" class="dropdown-avatar">
                <template v-if="profilePicture">
                  <img
                    :src="profilePicture"
                    alt="Profile Picture"
                    class="profile-image"
                  />
                </template>
                <template v-else>
                  <span class="no-avatar-text">No Avatar</span>
                </template>
              </v-avatar>
            </v-list-item>
            <v-divider></v-divider>
            <!-- Profile Link -->
            <v-list-item @click="goToProfile">
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <!-- User Settings Link -->
            <v-list-item @click="goToSettings">
              <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
              <v-list-item-title>User Settings</v-list-item-title>
            </v-list-item>
            <!-- Logout Button -->
            <v-list-item @click="logout" class="logout-button">
              <v-list-item-icon
                ><v-icon color="red">mdi-logout</v-icon></v-list-item-icon
              >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- Login and Register for Guests -->
      <div v-else class="auth-buttons">
        <v-btn text to="/login" class="v-btn">
          <v-icon left>mdi-login</v-icon> Login
        </v-btn>
        <v-btn text to="/register" class="v-btn">
          <v-icon left>mdi-account-plus</v-icon> Register
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isLoggedIn"
      app
      :rail="isRail"
      permanent
      class="navigation-rail"
      width="80"
      rail-width="50"
      @mouseenter="expandDrawer"
      @mouseleave="collapseDrawer"
    >
      <v-list dense>
        <!-- Dashboard -->
        <v-list-item to="/dashboard" class="nav-item">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!isRail">
            <v-list-item-title class="expanded-text"
              >Dashboard</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <!-- File Import -->
        <v-list-item to="/import-file" class="nav-item">
          <v-list-item-icon>
            <v-icon>mdi-file-import</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!isRail">
            <v-list-item-title class="expanded-text">Import</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Manage Team -->
        <v-list-item to="/manage-team" class="nav-item">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!isRail">
            <v-list-item-title class="expanded-text">Teams</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Single Report -->
        <v-list-item to="/data-visualization" class="nav-item">
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!isRail">
            <v-list-item-title class="expanded-text">Report</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Comparison Report -->
        <v-list-item to="/data-visualization-comparison" class="nav-item">
          <v-list-item-icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!isRail">
            <v-list-item-title class="expanded-text">Compare</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Main Content Area -->
    <v-main class="app-background">
      <router-view
        @profile-picture-updated="updateProfilePicture"
        @login="handleLogin"
        @logged-in="handleLogin"
      ></router-view>
    </v-main>
  </v-app>
</template>

<script>
import api from "@/axios.js";
export default {
  name: "App",
  data() {
    return {
      isLoggedIn: !!localStorage.getItem("access_token"),
      isRail: true, // Tracks the state of the navigation drawer (rail mode)
      profilePicture: localStorage.getItem("profile_picture") || "",
      menuVisible: false,
      defaultAvatar: null, // No placeholder image since "No Avatar" is text
    };
  },
  computed: {
    isBackButtonDisabled() {
      // Define routes where the back button should be disabled
      const noBackRoutes = [
        "/login",
        "/reset-password",
        "/dashboard",
        "/",
        "/confirm-email", // Add the ConfirmEmail route here
      ];

      // Match dynamic routes like ConfirmEmail with params
      return noBackRoutes.includes(this.$route.path);
    },
    showDashboardButton() {
      console.log(
        "showDashboardButton:",
        this.isLoggedIn && this.$route.path !== "/dashboard"
      );
      return this.isLoggedIn;
    },
    showLoginButton() {
      console.log(
        "showLoginButton:",
        !this.isLoggedIn && this.$route.path !== "/login"
      );
      return !this.isLoggedIn && this.$route.path !== "/login";
    },
    showRegisterButton() {
      console.log(
        "showRegisterButton:",
        !this.isLoggedIn && this.$route.path !== "/register"
      );
      return !this.isLoggedIn && this.$route.path !== "/register";
    },

    isOnDashboard() {
      return this.$route.path === "/dashboard";
    },
    isOnLogin() {
      return this.$route.path === "/login";
    },
    isOnRegister() {
      return this.$route.path === "/register";
    },
  },

  methods: {
    expandDrawer() {
      this.isRail = false; // Expand drawer on mouse enter
    },
    collapseDrawer() {
      this.isRail = true; // Collapse drawer on mouse leave
    },
    handleStorageChange() {
      // Dynamically update isLoggedIn when tokens are added/removed
      const accessToken = localStorage.getItem("access_token");
      this.isLoggedIn = !!accessToken;

      if (this.isLoggedIn) {
        this.fetchProfilePicture(); // Fetch profile picture if logged in
      } else {
        this.profilePicture = null; // Reset profile picture if logged out
      }
    },
    // Fetch the profile picture from the backend
    async fetchProfilePicture() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get("/api/auth/user/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.profilePicture = response.data.profile_picture || null; // Fallback to null for "No Avatar"
      } catch (error) {
        console.error("Error fetching profile picture:", error);
        this.profilePicture = null;
      }
    },

    // Handle the login process and update the UI state
    handleLogin() {
      this.isLoggedIn = true;
      console.log("User logged in:", this.isLoggedIn);
      this.fetchProfilePicture();
    },

    // Update the profile picture dynamically
    updateProfilePicture(newPicture) {
      this.profilePicture = newPicture || null; // Update with the new picture or fallback to "No Avatar"
      this.fetchProfilePicture(); // Re-fetch from backend to ensure accuracy
    },

    // Logout the user, clear all states, and redirect to login page
    logout() {
      localStorage.clear();
      this.isLoggedIn = false;
      console.log("User logged out:", this.isLoggedIn);
      this.profilePicture = null;
      this.$router.push("/login");
    },

    // Go back to the previous page or fallback to dashboard
    goBack() {
      if (!this.isBackButtonDisabled) {
        if (window.history.length > 1) {
          this.$router.go(-1); // Navigate back in history
        } else {
          this.$router.push("/dashboard"); // Fallback to dashboard if no history
        }
      }
    },

    // Navigate to the Profile page
    goToProfile() {
      this.$router.push("/user-profile");
    },

    // Navigate to the Settings page
    goToSettings() {
      this.$router.push("/settings");
    },

    // Toggle the visibility of the profile menu
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },

  mounted() {
    this.isLoggedIn = !!localStorage.getItem("access_token");
    if (this.isLoggedIn) {
      this.fetchProfilePicture();
    }
    // Listen for storage events (triggered when localStorage changes)
    window.addEventListener("storage", this.handleStorageChange);
  },
  beforeUnmount() {
    window.removeEventListener("storage", this.handleStorageChange);
  },
};
</script>

<style scoped>
/* Non-clickable STx logo styling */
.logo-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: default; /* Disable pointer on hover */
}
.logo-button {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  cursor: default; /* Disable pointer on hover */
}
/* Navigation Rail */
.navigation-rail {
  background: linear-gradient(
    135deg,
    #4caf50,
    #81c784
  ); /* Match top bar green gradient */
  color: #ffffff;
  z-index: 5;
  padding-top: 50px;
  transition: all 0.3s ease; /* Smooth transition for width and alignment */
  overflow: hidden; /* Prevent content overflow during expansion */
}

/* Navigation Items */
.nav-item {
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  padding: 15px 16px; /* Equal padding in both rail and expanded modes */
  transition: padding 0.3s ease, background-color 0.3s ease; /* Smooth hover transitions */
}
.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Subtle hover effect */
}
.nav-item .v-icon {
  font-size: 22px; /* Icon size consistency */
  margin-right: 16px; /* Consistent spacing between icon and text */
  transition: margin-right 0.3s ease; /* Adjust margin smoothly */
  color: #ffffff;
}
.nav-item .v-list-item-title {
  font-size: 12px; /* Reduce font size slightly */
  line-height: 1.2; /* Adjust line height */
  white-space: break-spaces; /* Allow text to wrap to the next line */
  text-align: center; /* Center the text below the icon */
  margin-top: 4px; /* Add spacing between icon and text */
}
.expanded-text {
  font-size: 13px; /* Adjust font size slightly smaller */
  line-height: 1.2; /* Reduce line height for compactness */
  text-align: left; /* Align text to the left */
  white-space: normal; /* Allow text to wrap naturally */
  opacity: 1; /* Fully visible text */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth text transition */
}

/* App Container */
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Top Bar */
.app-bar {
  background: linear-gradient(135deg, #4caf50, #81c784); /* Green Gradient */
  color: white;
  height: 60px;
  padding: 5px;
  display: flex;
  align-items: center;
}
/* Auth Buttons Container */
.auth-buttons {
  display: flex;
  gap: 15px; /* Adds space between buttons */
  align-items: center; /* Centers buttons vertically */
}

/* Background for the main content */
.app-background {
  background-color: #e8f5e9; /* Soft gray background */
}

/* Title Styling */
.app-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
}

/* Buttons */
.v-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  font-size: 14px;
  padding: 0 12px;
  height: 40px;
  line-height: 40px;
}

.v-btn .v-icon {
  margin-right: 4px;
  font-size: 20px;
  line-height: 1;
  vertical-align: middle;
}

.v-btn:disabled {
  color: rgba(255, 255, 255, 0.6); /* Lighter color when disabled */
}

/* Hover effect for navigation buttons */
.v-btn:hover:not([disabled]) {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Styling for profile picture and "No Avatar" */
.profile-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #ccc;
  margin-right: 8px; /* Adjust spacing for balance */
  cursor: pointer;
  background-color: #ccc;
  margin-right: 5px;
  background-color: #ccc;
  border: 1px solid #6200ee;
  border-radius: 80%;
  overflow: hidden;
  color: white;
}

.no-avatar-text {
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 45px; /* Match avatar size */
}

.profile-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/* Dropdown menu styling for the profile */
.v-menu {
  align-items: right;
  justify-content: right;
  position: fixed;
  top: 60px;
  right: 1px;
  min-width: 150px;
  z-index: 1000;
}

.v-list-item {
  min-width: 150px;
  max-width: 180px;
}
/* Dropdown Menu */
.dropdown-menu {
  background-color: #e8f5e9; /* Light green pastel */
  border-radius: 8px; /* Rounded corners for a softer look */
  padding: 10px 0; /* Spacing inside the dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Avatar inside the dropdown */
.dropdown-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto; /* Center the avatar in the dropdown */
  border: 1px solid #6200ee;
  border-radius: 80%;
  background-color: #ffffff;
}

/* Logout Button Styling */
.logout-button {
  color: red;
  font-weight: bold;
}
.logout-button:hover {
  background-color: rgba(255, 0, 0, 0.1); /* Highlight logout on hover */
}

/* Profile Image */
.profile-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Ensure circular avatar */
}
</style>
