<template>
  <v-container max-width="500px">
    <v-card outlined class="confirm-email">
      <v-card-title class="headline-medium text-h5 verify-email-title">
        Email Confirmation
      </v-card-title>

      <v-card-text>
        <!-- Success Message -->
        <div v-if="status === 'success'" class="text-center success-message">
          <v-icon size="50" color="success" class="mb-3"
            >mdi-check-circle</v-icon
          >
          <h2 class="headline-large">Email Confirmed!</h2>
          <p class="body-large">
            Your email has been successfully verified. You will be redirected to
            the login page shortly.
          </p>
        </div>

        <!-- Error Message -->
        <div v-else-if="status === 'error'" class="text-center error-message">
          <v-icon size="50" color="error" class="mb-3">mdi-alert-circle</v-icon>
          <h2 class="headline-large">Verification Failed</h2>
          <p class="body-large">{{ errorMessage }}</p>

          <!-- Resend Confirmation Email Button -->
          <v-btn
            color="primary"
            @click="resendConfirmationEmail"
            :disabled="resendDisabled"
            :loading="loading"
            class="mt-4 resend-btn"
          >
            <v-icon left>mdi-email</v-icon> Resend Confirmation Email
          </v-btn>

          <p class="body-small mt-2" v-if="resendDisabled">
            You can resend the email in {{ cooldown }} seconds.
          </p>
        </div>

        <!-- Loading Spinner -->
        <div v-else class="text-center loading-message">
          <v-icon size="50" color="primary" class="mdi-spin mb-3"
            >mdi-loading</v-icon
          >
          <h2 class="headline-large">Verifying...</h2>
          <p class="body-large">Please wait while we verify your email.</p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "ConfirmEmailComponent",
  props: ["uidb64", "token"],
  data() {
    return {
      status: null, // 'success', 'error', or null (loading)
      errorMessage: "", // Holds the error message from the backend
      email: null, // Store the user's email from the backend response
      loading: false, // Indicates if the resend request is in progress
      resendDisabled: false, // Controls the disabled state of the resend button
      cooldown: 0, // Cooldown timer in seconds
    };
  },
  methods: {
    async resendConfirmationEmail() {
      if (!this.email) {
        this.errorMessage = "Email address is not available for resending.";
        return;
      }

      this.loading = true;
      try {
        const response = await api.post("/api/auth/resend-verification/", {
          email: this.email,
        });
        this.errorMessage = "";
        console.log("Resend confirmation email response:", response.data);

        // Start cooldown
        this.startCooldown(20); // Set to 60 seconds
      } catch (error) {
        console.error("Error resending confirmation email:", error);
        this.errorMessage =
          error.response?.data?.detail ||
          "Failed to resend confirmation email. Please try again.";
      } finally {
        this.loading = false;
      }
    },

    startCooldown(seconds) {
      this.cooldown = seconds;
      this.resendDisabled = true;

      const interval = setInterval(() => {
        this.cooldown--;
        if (this.cooldown <= 0) {
          clearInterval(interval);
          this.resendDisabled = false;
        }
      }, 1000);
    },
  },
  async created() {
    console.log(
      "ConfirmEmailComponent created with UIDB64:",
      this.uidb64,
      "and token:",
      this.token
    );

    try {
      const response = await api.get(
        `/api/auth/confirm-email/${this.uidb64}/${this.token}/`
      );

      console.log("Email confirmation response:", response.data);

      if (
        response.status === 200 &&
        response.data.detail === "Email confirmed successfully"
      ) {
        this.status = "success";
        console.log("Email confirmed successfully, redirecting to login...");
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      } else {
        this.status = "error";
        this.errorMessage =
          response.data.detail ||
          "An unknown error occurred. Please try again.";
      }
    } catch (error) {
      this.status = "error";
      this.errorMessage =
        error.response?.data?.detail ||
        "An error occurred during email confirmation. Please try again.";

      // Extract email from backend response if available
      if (error.response?.data?.email) {
        this.email = error.response.data.email;
      }

      console.error("Error during email confirmation:", error);
    }
  },
};
</script>

<style scoped>
.verify-email-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333; /* Dark grey text for the title */
  margin-bottom: 16px; /* Space below the title */
  text-align: center; /* Center the title text */
}
.confirm-email {
  background-color: #fff; /* Match the VerifyEmailComponent container background */
  border-radius: 12px; /* Match VerifyEmailComponent rounded corners */
  padding: 40px; /* Inner padding for spacing */
  margin: 100px auto; /* Center container vertically and horizontally */
  text-align: center; /* Center content */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for modern appearance */
}
.button-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center the buttons */
  gap: 10px; /* Space between buttons */
}

/* Success message styling */
.success-message {
  color: #2e7d32; /* Material Design green color */
}

/* Error message styling */
.error-message {
  color: #c62828; /* Material Design red color */
}

/* Loading message styling */
.loading-message {
  color: #1976d2; /* Material Design primary blue color */
}

/* Headline and body text styles */
.headline-large {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.body-large {
  font-size: 1.1rem;
  margin-top: 10px;
  color: #666;
}

.body-small {
  font-size: 0.9rem;
  color: #999;
}

/* Resend button styling */
.resend-btn {
  width: 100%; /* Full-width button */
  font-weight: bold;
}
</style>
