import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify"; // Import Vuetify instance
import { loadFonts } from "./plugins/webfontloader";
import "@mdi/font/css/materialdesignicons.css"; // Material Design Icons

// Set up Axios
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = true;
// Load Fonts
loadFonts();

// Create and mount the Vue app with router, store, and Vuetify
createApp(App)
  .use(router)
  .use(store)
  .use(vuetify) // Register Vuetify pluginh
  .mount("#app");
