import axios from "axios";

// const api = axios.create({
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const api = axios.create({
  baseURL: "https://stiix-webapp-cc45cc9374b8.herokuapp.com",
});

export default api;
