<template>
  <div class="chart-container">
    <Line ref="chart" :data="data" :options="options" />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Filler,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom"; // Import zoom plugin
import "chartjs-adapter-luxon";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Filler,
  zoomPlugin // Register zoom plugin
);

export default {
  components: {
    Line,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "MMM dd, yyyy",
              },
            },
            ticks: {
              font: {
                family: "'Roboto', sans-serif",
                size: 11,
              },
              color: "#333",
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              font: {
                family: "'Roboto', sans-serif",
                size: 11,
              },
              color: "#333",
            },
            grid: {
              color: "#666",
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              font: {
                family: "'Roboto', sans-serif",
                size: 13,
                weight: "bold",
              },
              color: "#333",
            },
          },
          title: {
            display: true,
            text: "STIix Index Over Time",
            font: {
              family: "'Roboto', sans-serif",
              size: 16,
              weight: "bold",
            },
            color: "#333",
          },
          tooltip: {
            enabled: true,
            backgroundColor: "#ffffff",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
            callbacks: {
              label: function (context) {
                return `STIix Index: ${context.raw}`;
              },
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with mouse wheel
              },
              pinch: {
                enabled: true, // Enable zooming with touch gestures
              },
              mode: "x", // Allow zooming on the x-axis
            },
          },
        },
        elements: {
          line: {
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            tension: 0.3,
          },
          point: {
            backgroundColor: "rgba(54, 162, 235, 1)",
            borderColor: "#ffffff",
            radius: 5,
            hoverRadius: 7,
          },
        },
      }),
    },
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 350px;
  margin: 15px auto;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
