<template>
  <div class="data-visualization-comparison">
    <h1 class="title">Generate Report: Player Comparison</h1>

    <!-- Team Selection Dropdown -->
    <div class="styled-container">
      <h3>Select Team:</h3>
      <div class="dropdown-container">
        <v-select
          v-model="selectedTeamId"
          :items="teams"
          item-title="team_name"
          item-value="id"
          label="Choose Team"
          outlined
          no-data-text="No Teams Available"
          dense
        ></v-select>
      </div>
    </div>

    <!-- Search Bar -->
    <div
      v-if="selectedTeamId && filteredPlayers.length > 0"
      class="styled-container"
    >
      <v-text-field
        v-if="selectedTeamId && filteredPlayers.length > 0"
        v-model="search"
        density="compact"
        label="Search Players"
        prepend-inner-icon="mdi-magnify"
        variant="solo-filled"
        flat
        hide-details
        single-line
        class="search-bar"
      ></v-text-field>

      <!-- Players Table -->
      <div
        v-if="selectedTeamId && filteredPlayers.length > 0"
        class="players-table-container"
      >
        <v-data-table
          v-model="selectedPlayers"
          v-model:sort-by="sortBy"
          :headers="tableHeaders"
          :items="filteredPlayers"
          density="compact"
          :filter-keys="['name']"
          v-model:search="search"
          item-value="id"
          class="elevation-2 players-table"
          :items-per-page="20"
          hide-default-footer
          show-select
          return-object
        >
          <template #no-data>
            <div class="no-data-message">No players found for this team.</div>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- Selected Players Info and Training Sessions -->
    <div v-if="selectedPlayers.length > 0" class="selected-players-info">
      <h3>
        Selected Players and Training Sessions ({{ selectedPlayers.length }}):
      </h3>
      <ul
        v-for="player in selectedPlayers"
        :key="player.id"
        class="player-item"
      >
        <li class="player-info">
          <span class="player-text"
            >{{ player.name }} {{ player.last_name }} -
            {{ player.position }}</span
          >
          <div class="training-session-dropdown">
            <v-select
              v-model="selectedPlayerSessions[player.id]"
              :items="getPlayerTrainingSessions(player.id)"
              item-title="session_date"
              item-value="id"
              label="Select Training Session"
              outlined
              no-data-text="No training sessions available for this player."
              dense
            ></v-select>
          </div>
        </li>
      </ul>
    </div>

    <!-- Export Format Selection -->
    <div v-if="canShowExportOptions" class="export-section">
      <h3>Select Export Format:</h3>
      <v-select
        v-model="selectedExportFormat"
        :items="exportFormats"
        item-title="name"
        item-value="value"
        label="Choose Export Format"
        outlined
        dense
      ></v-select>

      <!-- Generate Report Button -->
      <div class="button-container">
        <v-btn
          v-if="selectedExportFormat"
          @click="generateReport"
          class="md3-button"
          color="primary"
          dark
        >
          <v-icon left>mdi-file-chart</v-icon> Generate Report
        </v-btn>
      </div>
    </div>

    <!-- No Players Alert -->
    <v-alert
      v-if="selectedTeamId && filteredPlayers.length === 0"
      type="warning"
      elevation="2"
      border="left"
      style="text-align: center; margin-top: 16px"
    >
      No players found for this team.
    </v-alert>
  </div>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "DataVisualizationComparison",
  data() {
    return {
      search: "", // Search query for filtering
      selectedTeamId: null, // Selected team ID
      teams: [], // List of teams fetched from the API
      players: [], // List of players fetched from the API
      filteredPlayers: [], // Players filtered by the selected team
      selectedPlayers: [], // Selected players from the table
      selectedPlayerSessions: {}, // Stores selected training sessions per player
      trainingSessions: {}, // Training sessions keyed by player ID
      selectedExportFormat: "pdf", // Selected export format
      sortBy: [{ value: "position", order: "asc" }], // Sorting configuration
      tableHeaders: [
        { title: "Player Number", value: "player_number", sortable: true },
        { title: "First Name", value: "name", sortable: true },
        { title: "Last Name", value: "last_name", sortable: true },
        { title: "Position", value: "position", sortable: true },
      ],
      exportFormats: [
        { name: "JSON", value: "json" },
        { name: "CSV", value: "csv" },
        { name: "PDF", value: "pdf" },
      ], // Options for export formats
    };
  },
  computed: {
    canShowExportOptions() {
      return (
        Object.keys(this.selectedPlayerSessions).length >= 2 &&
        Object.values(this.selectedPlayerSessions).every((session) => !!session)
      );
    },
  },
  async mounted() {
    console.log("Fetching teams and players...");
    await this.fetchTeams();
    await this.fetchPlayers();
  },
  watch: {
    selectedPlayers: {
      deep: true,
      handler(newSelectedPlayers) {
        console.log("Selected players updated:", newSelectedPlayers);
        newSelectedPlayers.forEach((player) => {
          if (!this.trainingSessions[player.id]) {
            this.fetchTrainingSessions(player.id);
          }
        });
        const deselectedPlayers = Object.keys(this.trainingSessions).filter(
          (playerId) =>
            !newSelectedPlayers.find((p) => p.id === parseInt(playerId))
        );
        deselectedPlayers.forEach((playerId) => {
          delete this.trainingSessions[playerId];
          delete this.selectedPlayerSessions[playerId];
        });
      },
    },
    selectedTeamId(newTeamId) {
      console.log(`Selected Team ID changed to: ${newTeamId}`);
      if (newTeamId) {
        this.resetSelections();
        this.filterPlayersByTeam(newTeamId);
      }
    },
  },
  methods: {
    async fetchTeams() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get("/api/team/teams/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.teams = response.data;
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    },
    async fetchPlayers() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get("/api/players/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.players = response.data;
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    },
    async fetchTrainingSessions(playerId) {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(
          `/api/training-sessions/?playerId=${playerId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        this.trainingSessions = {
          ...this.trainingSessions,
          [playerId]: response.data,
        };
      } catch (error) {
        console.error("Error fetching training sessions:", error);
        this.trainingSessions = {
          ...this.trainingSessions,
          [playerId]: [],
        };
      }
    },
    filterPlayersByTeam(teamId) {
      if (!teamId) {
        this.filteredPlayers = [];
        return;
      }
      this.filteredPlayers = this.players.filter(
        (player) => player.team === teamId
      );
    },
    getPlayerTrainingSessions(playerId) {
      return this.trainingSessions[playerId] || [];
    },
    resetSelections() {
      console.log("Resetting all selections...");
      this.search = "";
      this.filteredPlayers = [];
      this.selectedPlayers = [];
      this.selectedPlayerSessions = {};
      this.trainingSessions = {};
      this.selectedExportFormat = "json";
    },
    async generateReport() {
      console.log("Generating report...");

      // Extract selected player IDs and their corresponding session IDs
      const playerIds = Object.keys(this.selectedPlayerSessions);
      const sessionIds = Object.values(this.selectedPlayerSessions);

      try {
        const params = new URLSearchParams();
        playerIds.forEach((id) => params.append("playerIds[]", id));
        sessionIds.forEach((id) => params.append("sessionIds[]", id));
        params.append("format", this.selectedExportFormat);

        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(
          `/api/data-visualization/export-comparison/?${params.toString()}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
          }
        );

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `comparison-report.${this.selectedExportFormat}`
        );
        document.body.appendChild(fileLink);
        fileLink.click();

        console.log("Report generated successfully.");
      } catch (error) {
        console.error("Error generating report:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Container to center the button */
.button-container {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  margin-top: 20px; /* Add spacing above the button */
}
/* Container for the entire component Testing */
.data-visualization-comparison {
  max-width: 700px;
  margin: 117px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}
/* Container for unified sections */
.styled-container {
  margin-top: 25px;
  background-color: #ffffff; /* White background */
  padding: 15px;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
/* Title styling */
.title {
  font-family: "Roboto", sans-serif;
  color: #333;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

/* Subtitle headers */
h3 {
  font-family: "Roboto", sans-serif;
  color: #6200ee;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}

/* Dropdown container styling */
.dropdown-container {
  margin-bottom: 20px;
}

/* Search bar */
.search-bar {
  margin-top: 15px;
}

/* Table container */
.players-table-container {
  margin-top: 20px;
}

/* Data table styling */
.v-data-table {
  background-color: #fff;
  border-radius: 8px;
}

/* No data message inside the table */
.no-data-message {
  text-align: center;
  color: #666;
  font-size: 16px;
}

/* Selected players info container */
.selected-players-info {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Individual player item in the list */
.player-item {
  margin-bottom: 20px;
}

/* Player info section */
.player-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* Player text styling */
.player-text {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  align-items: left;
  color: #333;
  margin-bottom: 10px;
}

/* Dropdown for training sessions */
.training-session-dropdown {
  margin-top: 10px;
}

/* Alert message styling */
.v-alert {
  margin-top: 16px;
  text-align: center;
}

/* Export section styling */
.export-section {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* Button styling */
.md3-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #6200ee;
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.md3-button:hover {
  background-color: #3700b3;
}

.md3-button v-icon {
  margin-right: 8px;
}

/* Alert for no players */
.no-players-alert {
  text-align: center;
  margin-top: 16px;
}

/* Styling adjustments for responsive design */
</style>
