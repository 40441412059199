<template>
  <!-- Main container for the reset password component, centered and with max width -->
  <v-container max-width="500px">
    <v-card outlined class="reset-password">
      <!-- Card title for reset password form -->
      <v-card-title class="headline-small text-h5 reset-password-title">
        Reset Your Password
      </v-card-title>

      <v-card-text>
        <!-- Form with debounced submit handler to prevent rapid submissions -->
        <v-form @submit.prevent="debouncedSubmit" lazy-validation>
          <!-- New Password Field with validation, guidelines, and toggle visibility icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                label="New Password"
                :rules="[rules.required, rules.password]"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-lock"
                :append-inner-icon="
                  showPassword ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
                "
                @click:append-inner="togglePasswordVisibility"
                :error-messages="fieldErrors.password"
                @blur="
                  validateField('password');
                  showGuidelines = true;
                "
                @focus="showGuidelines = true"
              ></v-text-field>
              <!-- Password guidelines, shown conditionally when the field is focused -->
              <small v-if="showGuidelines" class="password-guidelines">
                Must have atleast 1 Capital letter.Must have atleast 1 Special
                character.Must have at least 1 number.Password must be at least
                8 characters long and should not be entirely numeric.
              </small>
            </v-col>
          </v-row>

          <!-- Confirm Password Field with validation and toggle visibility icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.password2"
                :type="showPassword2 ? 'text' : 'password'"
                label="Confirm New Password"
                :rules="[rules.required, rules.matchPassword]"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-lock"
                :append-inner-icon="
                  showPassword2 ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
                "
                @click:append-inner="togglePassword2Visibility"
                :error-messages="fieldErrors.password2"
                @blur="validateField('password2')"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Reset Password Button with loading state and styling -->
          <v-row>
            <v-col>
              <v-btn
                :loading="loading"
                type="submit"
                color="primary"
                block
                large
                class="mt-4 reset-btn"
                variant="elevated"
                :disabled="loading"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-icon left v-if="!loading">mr-1 mdi-refresh</v-icon> Reset
                Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <!-- Success Alert, shown after successful password reset with redirect to login page -->
        <v-alert
          v-if="alerts.success"
          color="success"
          class="mt-3 success-alert custom-alert text-left"
          close-label="Close Alert"
          border="start"
          title="Success"
          variant="elevated"
        >
          <!-- Custom close icon inside the alert to dismiss it -->
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.success = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          {{ alerts.success }}
        </v-alert>

        <!-- Error Alert, displayed for validation or server errors with navigation back to forgot-password page if token is invalid -->
        <v-alert
          v-if="alerts.error"
          color="error"
          class="mt-3 error-alert custom-alert text-left"
          close-label="Close Alert"
          border="start"
          title="Error"
          variant="elevated"
        >
          <!-- Custom close icon inside the error alert to dismiss it -->
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.error = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          {{ alerts.error }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import api from "api";
import api from "@/axios.js";
export default {
  name: "ResetPasswordComponent",
  data() {
    return {
      form: {
        password: "", // New password input field value
        password2: "", // Confirm password input field value
      },
      loading: false, // Controls loading state of submit button
      showPassword: false, // Controls visibility toggle of new password
      showPassword2: false, // Controls visibility toggle of confirm password
      fieldErrors: {
        password: [], // Stores validation errors for new password field
        password2: [], // Stores validation errors for confirm password field
      },
      alerts: {
        success: "", // Stores success message text
        error: "", // Stores error message text
      },
      showGuidelines: true, // Tracks if password guidelines should be shown on focus
      uidb64: this.$route.params.uidb64, // Retrieves encoded user ID from route parameters
      token: this.$route.params.token, // Retrieves reset token from route parameters
      rules: {
        required: (value) => !!value || "This field is required.", // Checks for required fields
        password: (value) =>
          value.length >= 8 || "Password must be at least 8 characters.", // Ensures password meets length requirement
        matchPassword: (value) =>
          value === this.form.password || "Passwords do not match.", // Checks if confirm password matches
      },
    };
  },
  methods: {
    // Toggles the visibility of the new password field
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    // Toggles the visibility of the confirm password field
    togglePassword2Visibility() {
      this.showPassword2 = !this.showPassword2;
    },
    // Validates individual fields and updates error messages if validation fails
    validateField(field) {
      if (!this.form[field]) {
        this.fieldErrors[field] = [
          `${
            field === "password" ? "New password" : "Confirm new password"
          } is required.`,
        ];
      } else {
        this.fieldErrors[field] = [];
      }
    },
    // Debounced submit function to prevent rapid submissions
    async debouncedSubmit() {
      if (this.loading) return; // Skip if already loading
      this.loading = true;
      setTimeout(() => this.submitForm(), 200); // Adds debounce delay before submitting form
    },
    // Main form submission function to handle reset password request
    async submitForm() {
      this.clearAlerts(); // Clears any existing alerts

      // Field presence validation
      if (!this.form.password || !this.form.password2) {
        if (!this.form.password)
          this.fieldErrors.password = ["New password is required."];
        if (!this.form.password2)
          this.fieldErrors.password2 = ["Confirmation password is required."];
        this.loading = false;
        return;
      }

      // Validates if both passwords match
      if (this.form.password !== this.form.password2) {
        this.alerts.error = "Passwords do not match.";
        this.loading = false;
        return;
      }

      // Attempt to send reset password request
      try {
        await api.post("/api/auth/reset-password/", {
          password: this.form.password,
          password2: this.form.password2,
          uidb64: this.uidb64,
          token: this.token,
        });
        this.alerts.success = "Password has been reset successfully!"; // Set success message
        setTimeout(() => {
          this.$router.push("/login"); // Redirect to login page after success
        }, 2000);
      } catch (error) {
        // Handle server-side validation errors and expired token errors
        if (error.response && error.response.status === 400) {
          this.alerts.error =
            "Invalid or expired reset token. Please try again.";
          setTimeout(() => {
            this.$router.push("/forgot-password"); // Redirect to forgot-password page for invalid tokens
          }, 2000);
        } else {
          this.alerts.error = "Error resetting password. Please try again."; // General error message for other errors
        }
      } finally {
        this.loading = false; // Reset loading state
      }
    },
    // Clears all alert messages and field errors
    clearAlerts() {
      this.alerts.success = "";
      this.alerts.error = "";
      this.fieldErrors.password = [];
      this.fieldErrors.password2 = [];
    },
  },
};
</script>

<style scoped>
/* Styles for the main reset password container */
.reset-password {
  border-radius: 12px; /* Rounded corners */
  padding: 40px; /* Inner padding */
  margin: 100px auto; /* Center the card vertically */
  text-align: center; /* Center text */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #ffffff; /* White background */
}

/* Reset button styling with hover effects */
.reset-btn {
  display: block;
  width: 98%;
  margin-left: 1%;
  padding: 12px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.reset-btn:hover {
  background-color: #3700b3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Shadow effect on hover */
}

/* Styling for password guidelines text */
small.password-guidelines {
  display: block;
  margin-top: 5px;
  color: gray;
  font-size: 0.9em;
  text-align: left;
}

/* Success alert styling */
.success-alert {
  background-color: #e6ffed;
  color: #388e3c;
}

/* Error alert styling */
.error-alert {
  background-color: #ffebee;
  color: #d32f2f;
}

/* Custom styling for close icon inside alerts */
.custom-close-icon {
  font-size: 24px;
  color: #ffffff !important;
  cursor: pointer;
}

/* Styling for reset password title */
.reset-password-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
  text-align: center;
}
</style>
