<template>
  <v-container max-width="500px">
    <v-card outlined class="verify-email">
      <v-card-title class="headline-small text-h5 verify-email-title">
        Verify Your Email
      </v-card-title>

      <v-card-text>
        <p>
          User registered successfully! Check email: <strong>{{ email }}</strong
          >. to confirm.
        </p>

        <!-- Success message -->
        <v-alert v-if="successMessage" type="success" class="mt-3" dismissible>
          {{ successMessage }}
        </v-alert>

        <!-- Error message -->
        <v-alert v-if="errorMessage" type="error" class="mt-3" dismissible>
          {{ errorMessage }}
        </v-alert>

        <!-- Centered button container -->
        <div class="button-container">
          <v-btn
            color="primary"
            @click="resendVerificationEmail"
            :loading="loading"
            class="mt-4"
          >
            <v-icon left>mdi-email</v-icon> Resend Verification Email
          </v-btn>

          <!-- Return to Login Button -->
          <v-btn color="primary" @click="goToLogin" class="return-btn mt-3">
            <v-icon left>mdi-login</v-icon> Return to Login
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/axios.js";

export default {
  name: "VerifyEmailComponent",
  data() {
    return {
      email: null, // Email retrieved from local storage
      loading: false,
      successMessage: "", // For success feedback
      errorMessage: "", // For error feedback
    };
  },
  methods: {
    async resendVerificationEmail() {
      this.loading = true;
      this.successMessage = "";
      this.errorMessage = "";
      try {
        const response = await api.post("/api/auth/resend-verification/", {
          email: this.email, // Send the email to the backend
        });

        // Display success message
        this.successMessage = response.data.detail;
      } catch (error) {
        // Handle error and display message
        this.errorMessage =
          error.response?.data?.detail ||
          "Failed to resend verification email. Please try again.";
      } finally {
        this.loading = false; // Stop the loading spinner
      }
    },
    goToLogin() {
      this.$router.push("/login"); // Redirect to login
    },
  },
  created() {
    // Retrieve email from local storage
    this.email = localStorage.getItem("emailForVerification");

    // Redirect if email is not found
    if (!this.email) {
      this.$router.push("/register");
    }
  },
  beforeUnmount() {
    // Optional: Clear email from local storage once used
    localStorage.removeItem("emailForVerification");
  },
};
</script>

<style scoped>
.verify-email {
  border-radius: 12px; /* Consistent rounded corners */
  padding: 40px; /* Inner padding for spacing */
  margin: 100px auto; /* Center the card vertically and horizontally */
  text-align: center; /* Center the content */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for a floating effect */
  background-color: #fff; /* White background */
}

.verify-email-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333; /* Dark grey text for the title */
  margin-bottom: 16px; /* Space below the title */
  text-align: center; /* Center the title text */
}

/* Center the buttons and add space between them */
.button-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center the buttons */
  gap: 10px; /* Add space between buttons */
}
.return-btn {
  color: white;
}
.v-btn {
  font-weight: 600;
  width: 98%; /* Full-width button */
  padding: 12px; /* Adjust button padding */
  border-radius: 24px; /* Rounded corners for buttons */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for button */
}
</style>
