/* prettier-ignore */
<template>
  <v-container class="training-sessions" max-width="700px">
    <div class="training-sessions-header">
      <h2>Select Training Sessions for:</h2>
      <h3 class="player-team-info">
        Player: {{ playerFullName }} <br />
        Team: {{ teamName }}
      </h3>
    </div>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:actions>
        <v-btn color="white" text @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    <!-- Compare Players Button at the Top -->
    <div class="compare-button">
      <v-btn color="primary" @click="goToComparePlayers" dark>
        <v-icon left>mdi-account-multiple</v-icon> Compare with another player
      </v-btn>
    </div>

    <!-- Add Training Session Button -->
    <div class="add-session-button">
      <v-btn color="primary" @click="openAddSessionModal" dark>
        <v-icon left>mdi-plus</v-icon> Add Training Session
      </v-btn>
    </div>

    <!-- Add Training Session Modal -->
    <v-dialog v-model="showAddSessionModal" max-width="500px">
      <v-card>
        <v-card-title>Add New Training Session</v-card-title>
        <v-card-text>
          <v-form ref="addSessionForm" v-model="formValid">
            <v-text-field
              label="Session Date"
              v-model="newSessionData.session_date"
              outlined
              type="date"
              :error-messages="errors.session_date"
              @blur="validateField('session_date')"
            ></v-text-field>
            <v-text-field
              label="Weight Lift Impact"
              v-model="newSessionData.weight_lift_impact"
              outlined
              type="text"
              :error-messages="errors.weight_lift_impact"
              @blur="validateField('weight_lift_impact')"
            ></v-text-field>
            <v-text-field
              label="Lower Body Power"
              v-model="newSessionData.lower_body_power"
              outlined
              type="text"
              :error-messages="errors.lower_body_power"
              @blur="validateField('lower_body_power')"
            ></v-text-field>
            <v-text-field
              label="Upper Body Power"
              v-model="newSessionData.upper_body_power"
              outlined
              type="text"
              :error-messages="errors.upper_body_power"
              @blur="validateField('upper_body_power')"
            ></v-text-field>
            <v-text-field
              label="Serve Speed"
              v-model="newSessionData.serve_speed"
              outlined
              type="text"
              :error-messages="errors.serve_speed"
              @blur="validateField('serve_speed')"
            ></v-text-field>
            <v-text-field
              label="Serve Accuracy"
              v-model="newSessionData.serve_accuracy"
              outlined
              type="text"
              :error-messages="errors.serve_accuracy"
              @blur="validateField('serve_accuracy')"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!formValid"
            @click="addTrainingSession"
          >
            Add Training Session
          </v-btn>
          <v-btn color="secondary" @click="closeAddModal">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirmation Dialog for Adding Training Session -->
    <v-dialog v-model="confirmAddDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Add Training Session</v-card-title>
        <v-card-text
          >Are you sure you want to add this training session?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="addTrainingSession">Confirm</v-btn>
          <v-btn @click="confirmAddDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success Dialog for Adding Training Session -->
    <v-dialog v-model="successAddDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Training Session Added</v-card-title>
        <v-card-text>Training session added successfully!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="successAddDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEditSessionModal" max-width="500px">
      <v-card>
        <v-card-title>Edit Training Session</v-card-title>
        <v-card-text>
          <v-text-field
            label="Session Date"
            v-model="editSessionData.session_date"
            outlined
            type="date"
          ></v-text-field>
          <v-text-field
            label="Weight Lift Impact"
            v-model="editSessionData.weight_lift_impact"
            outlined
            type="number"
          ></v-text-field>
          <v-text-field
            label="Lower Body Power"
            v-model="editSessionData.lower_body_power"
            outlined
            type="number"
          ></v-text-field>
          <v-text-field
            label="Upper Body Power"
            v-model="editSessionData.upper_body_power"
            outlined
            type="number"
          ></v-text-field>
          <v-text-field
            label="Serve Speed"
            v-model="editSessionData.serve_speed"
            outlined
            type="number"
          ></v-text-field>
          <v-text-field
            label="Serve Accuracy"
            v-model="editSessionData.serve_accuracy"
            outlined
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!hasChanges"
            @click="updateTrainingSession"
          >
            Save Changes
          </v-btn>
          <v-btn color="secondary" @click="showEditSessionModal = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success Dialog for Training Session Update -->
    <v-dialog v-model="successEditDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Training Session Updated</v-card-title>
        <v-card-text>Training session updated successfully!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="successEditDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirmation Dialog for Delete Training Session -->
    <v-dialog v-model="confirmDeleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Delete Training Session</v-card-title>
        <v-card-text
          >Are you sure you want to delete this training session?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-2" @click="deleteTrainingSession">
            Confirm
          </v-btn>
          <v-btn @click="confirmDeleteDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success Dialog for Training Session Deletion -->
    <v-dialog v-model="successDeleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Training Session Deleted</v-card-title>
        <v-card-text>Training session deleted successfully!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="successDeleteDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dropdowns and session details -->
    <div v-if="!loading" class="session-details-wrapper">
      <!-- Training session dropdown -->
      <div class="dropdown-container">
        <label for="sessionSelect" class="dropdown-label">
          Select Training Session:
        </label>
        <div class="select-wrapper">
          <select
            v-model="selectedSessionId"
            @change="loadSpiderChart"
            class="dropdown-select"
            id="sessionSelect"
          >
            <option v-if="filteredSessions.length === 0" disabled selected>
              No training sessions available for this player.
            </option>
            <option
              v-for="session in filteredSessions"
              :key="session.id"
              :value="session.id"
            >
              {{ session.session_date }}
            </option>
          </select>
          <span class="dropdown-icon">▼</span>
        </div>
      </div>

      <!-- Session details with edit/delete actions -->
      <div v-if="selectedSessionId && sessionDetails">
        <div class="session-details">
          <h2>Training Session Details</h2>
          <!-- Updated session-details-table with Material Design 3 styling -->
          <table class="session-details-table">
            <thead>
              <tr>
                <th>Metric</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Weight Lift Impact</td>
                <td>{{ sessionDetails.weight_lift_impact }}</td>
              </tr>
              <tr>
                <td>Lower Body Power</td>
                <td>{{ sessionDetails.lower_body_power }}</td>
              </tr>
              <tr>
                <td>Upper Body Power</td>
                <td>{{ sessionDetails.upper_body_power }}</td>
              </tr>
              <tr>
                <td>Serve Speed</td>
                <td>{{ sessionDetails.serve_speed }}</td>
              </tr>
              <tr>
                <td>Serve Accuracy</td>
                <td>{{ sessionDetails.serve_accuracy }}</td>
              </tr>
              <tr>
                <td><strong>STix</strong></td>
                <td>{{ sessionDetails.stiix_index }}</td>
              </tr>
            </tbody>
          </table>

          <!-- Edit and Delete Buttons -->
          <div class="session-actions">
            <v-btn color="primary" @click="openEditModal">
              <v-icon left>mdi-pencil</v-icon>
              EDIT TRAINING SESSION
            </v-btn>
            <v-btn color="red darken-2" @click="confirmDeleteDialog = true">
              <v-icon left>mdi-delete</v-icon>
              DELETE TRAINING SESSION
            </v-btn>
          </div>
        </div>
        <!-- Toggle Button Group for Charts -->
        <div class="chart-toggle-group">
          <v-btn-toggle v-model="selectedChart" mandatory>
            <v-btn value="line">Line Chart</v-btn>
            <v-btn value="spider">Spider Chart</v-btn>
          </v-btn-toggle>
        </div>
        <!-- Subtext for Zoom Information -->
        <p class="zoom-info-text">
          You can zoom in or out of the line chart using your mouse scroll wheel
          or touch gestures.
        </p>

        <!-- Spider chart for metrics -->
        <div v-if="!loading" class="chart-container">
          <div v-if="selectedChart === 'line'">
            <line-chart
              ref="lineChart"
              :data="lineChartData"
              :options="lineChartOptions"
            />
          </div>
          <div v-else-if="selectedChart === 'spider'">
            <radar-chart
              :data="spiderChartData"
              :options="spiderChartOptions"
            />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
// import api from "api";
import api from "@/axios.js";
import RadarChart from "./RadarChart.vue";
import LineChart from "./LineChart.vue";

export default {
  name: "TrainingSessionsComponent",
  components: { RadarChart, LineChart },
  props: {
    playerId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      snackbar: {
        show: false, // Controls visibility of the snackbar
        text: "", // Text message displayed in the snackbar
        color: "success", // Snackbar color: 'success', 'error', etc.
        timeout: 4000, // Duration in milliseconds
      },
      players: [],
      trainingSessions: [],
      selectedPlayerId: this.playerId || null,
      selectedSessionId: null,
      sessionDetails: null,
      spiderChartData: null,
      spiderChartOptions: null,
      lineChartData: null,
      lineChartOptions: null,
      selectedChart: "line",
      playerFullName: "", // To hold the player's full name
      teamName: "", // To hold the player's team name

      loading: false,
      successDeleteDialog: false,
      successEditDialog: false,
      successAddDialog: false,
      confirmAddDialog: false,
      confirmDeleteDialog: false,
      showDetails: false,
      showAddSessionModal: false,
      showEditSessionModal: false,
      newSessionData: {
        session_date: "",
        weight_lift_impact: "",
        lower_body_power: "",
        upper_body_power: "",
        serve_speed: "",
        serve_accuracy: "",
      },
      errors: {
        session_date: null,
        weight_lift_impact: null,
        lower_body_power: null,
        upper_body_power: null,
        serve_speed: null,
        serve_accuracy: null,
      },
      editSessionData: {},
      formValid: false,
    };
  },
  computed: {
    hasChanges() {
      return (
        JSON.stringify(this.editSessionData) !==
        JSON.stringify(this.sessionDetails)
      );
    },
    isFormValid() {
      const {
        session_date,
        weight_lift_impact,
        lower_body_power,
        upper_body_power,
        serve_speed,
        serve_accuracy,
      } = this.newSessionData;
      return (
        this.validateDate(session_date) === true &&
        this.validateNumeric(weight_lift_impact) === true &&
        this.validateNumeric(lower_body_power) === true &&
        this.validateNumeric(upper_body_power) === true &&
        this.validateNumeric(serve_speed) === true &&
        this.validateNumeric(serve_accuracy) === true
      );
    },
    filteredPlayers() {
      const uniquePlayers = [];
      const seenNames = new Set();
      this.players.forEach((player) => {
        const fullName = `${player.name}-${player.last_name}`;
        if (!seenNames.has(fullName)) {
          seenNames.add(fullName);
          uniquePlayers.push(player);
        }
      });
      return uniquePlayers;
    },
    filteredSessions() {
      // const uniqueSessions = [];
      // const seenSessions = new Set();
      // this.trainingSessions.forEach((session) => {
      //   if (!seenSessions.has(session.session_date)) {
      //     seenSessions.add(session.session_date);
      //     uniqueSessions.push(session);
      //   }
      // });
      return this.trainingSessions; // Remove sorting since data is already sorted
    },
  },
  async mounted() {
    if (!this.selectedPlayerId && this.playerId) {
      this.selectedPlayerId = this.playerId; // Ensure player ID is set if passed as a prop
    }
    try {
      this.loading = true;

      // Fetch player details (name and last name)
      await this.fetchPlayerDetails();

      // Fetch team information
      const accessToken = localStorage.getItem("access_token");
      const teamResponse = await api.get(
        `/api/team/teams/${this.$route.query.teamId}/`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      this.teamName = teamResponse.data.team_name;

      // Fetch training sessions for the player
      await this.fetchTrainingSessions();
    } catch (error) {
      console.error("Error in mounted hook:", error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    showSnackbar(message, color = "success") {
      this.snackbar.text = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    checkFormValidity() {
      this.formValid = Object.values(this.errors).every(
        (error) => error === null
      );
    },
    validateField(field) {
      const value = this.newSessionData[field];
      if (field === "session_date") {
        this.errors.session_date = this.validateDate(value);
      } else {
        this.errors[field] = this.validateNumeric(value);
      }
      this.checkFormValidity();
    },

    resetForm() {
      this.newSessionData = {
        session_date: "",
        weight_lift_impact: "",
        lower_body_power: "",
        upper_body_power: "",
        serve_speed: "",
        serve_accuracy: "",
      };
      this.errors = {
        session_date: null,
        weight_lift_impact: null,
        lower_body_power: null,
        upper_body_power: null,
        serve_speed: null,
        serve_accuracy: null,
      };
      this.formValid = false;
    },

    closeAddModal() {
      this.resetForm();
      this.showAddSessionModal = false;
    },
    async fetchPlayerDetails() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(`/api/players/${this.playerId}/`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const player = response.data;
        this.playerFullName = `${player.name} ${player.last_name}`;
      } catch (error) {
        console.error("Error fetching player details:", error);
      }
    },

    async fetchPlayers() {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get("/api/players/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        this.players = response.data;
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    },
    async fetchTrainingSessions() {
      if (!this.selectedPlayerId) return;
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(
          `/api/training-sessions/?playerId=${this.selectedPlayerId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        this.trainingSessions = response.data.sort(
          (a, b) => new Date(b.session_date) - new Date(a.session_date)
        );

        this.showDetails = false;
        this.loadLineChart(); // Load line chart with backend data
      } catch (error) {
        console.error("Error fetching training sessions:", error);
      }
    },
    async loadSpiderChart() {
      if (!this.selectedSessionId) return;
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(
          `/api/training-sessions/${this.selectedSessionId}/`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        this.sessionDetails = response.data;
        this.editSessionData = { ...response.data };

        // Dynamically calculate chart size based on viewport width
        const isMobile = window.innerWidth < 768;

        this.spiderChartData = {
          labels: [
            "Weight Lift Impact",
            "Lower Body Power",
            "Upper Body Power",
            "Serve Speed",
            "Serve Accuracy",
          ],
          datasets: [
            {
              label: "Performance",
              data: [
                response.data.weight_lift_impact,
                response.data.lower_body_power,
                response.data.upper_body_power,
                response.data.serve_speed,
                response.data.serve_accuracy,
              ],
              borderColor: "rgba(54, 162, 235, 1)", // Bold blue line
              borderWidth: 2, // Thicker line for emphasis
              pointBackgroundColor: "#03DAC6", // Secondary MD3 blue for points
              pointBorderColor: "#ffffff", // White border for points
              pointRadius: isMobile ? 2 : 4, // Adjust point size for mobile
              pointHoverRadius: isMobile ? 3 : 6, // Adjust hover size for mobile
            },
          ],
        };

        this.spiderChartOptions = {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              labels: {
                font: {
                  size: isMobile ? 5 : 12, // Smaller font for mobile
                },
              },
            },
          },
          scales: {
            r: {
              angleLines: {
                display: true,
              },
              suggestedMin: 0,
              suggestedMax: 100,
              pointLabels: {
                font: {
                  size: isMobile ? 4 : 12, // Adjust size for mobile
                },
              },
            },
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
          },
        };
      } catch (error) {
        console.error("Error loading spider chart:", error);
      }
    },

    loadLineChart() {
      if (!this.trainingSessions.length) return;

      // Extract session dates and STIix index values, sorted by session date
      const sortedSessions = [...this.trainingSessions].sort(
        (a, b) => new Date(a.session_date) - new Date(b.session_date)
      );
      const labels = sortedSessions.map((session) => session.session_date);
      const stiixData = sortedSessions.map((session) => session.stiix_index);

      // Update line chart data
      this.lineChartData = {
        labels, // Use session dates directly as categories
        datasets: [
          {
            label: "STix Index",
            data: stiixData,
            borderColor: "rgba(54, 162, 235, 1)", // Blue line
            backgroundColor: "rgba(54, 162, 235, 0)", // Removed light blue fill
            fill: false, // Disable fill under the line
            tension: 0.3, // Smooth the line
            pointBackgroundColor: "#ffffff", // Blue points
            pointBorderColor: "rgba(54, 162, 235, 1)",
            pointRadius: 6, // Larger point size for mobile and desktop
            pointHoverRadius: 8, // Larger hover size for emphasis
          },
        ],
      };

      // Update line chart options
      this.lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 20, // Added
            bottom: 20, // Added
            left: 20, // Adjusted
            right: 20, // Adjusted
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Training Session Date", // X-axis label
              font: {
                family: "'Roboto', sans-serif",
                size: 12,
                weight: "bold",
              },
              color: "#333", // Dark grey for label
            },
            ticks: {
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: 10,
              },
              color: "#333",
            },
            grid: {
              color: "#666666", // Gridline color
            },
          },
          y: {
            title: {
              display: true,
              text: "STix Value", // Y-axis label
              font: {
                family: "'Roboto', sans-serif",
                size: 12,
                weight: "bold",
              },
              color: "#333", // Dark grey for label
            },
            beginAtZero: true,
            ticks: {
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: 12,
              },
              color: "#333",
            },
            grid: {
              color: "#666666", // Gridline color
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
              },
              color: "#333333", // Dark grey text
            },
          },
          title: {
            display: true,
            text: "STix Over Time",
            font: {
              family: "'Roboto', sans-serif",
              weight: "bold",
              size: 18,
            },
            color: "#333333", // Title color
          },
          tooltip: {
            enabled: true, // Ensure tooltips are enabled
            backgroundColor: "#ffffff", // Tooltip background
            borderColor: "#6200ee", // Tooltip border color
            borderWidth: 1, // Tooltip border width
            titleColor: "#333", // Tooltip title color
            bodyColor: "#333", // Tooltip body text color
            callbacks: {
              label: function (context) {
                return `STIix Index: ${context.raw}`; // Display STIix value on hover
              },
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x", // Allow horizontal panning
            },
            zoom: {
              wheel: {
                enabled: true, // Enable zoom with the mouse wheel
              },
              pinch: {
                enabled: true, // Enable zoom with pinch gestures
              },
              mode: "x", // Only zoom horizontally
            },
          },
        },
        elements: {
          line: {
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
          },
          point: {
            backgroundColor: "#ffffff",
            radius: 3,
            hoverRadius: 5,
            borderColor: "#03DAC6",
            borderWidth: 2,
            pointRadius: 5, // Larger point size for mobile and desktop
            pointHoverRadius: 8, // Larger hover size for emphasis
          },
        },
      };
    },

    validateDate(value) {
      if (!value || !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        return "Enter a valid date (YYYY-MM-DD)";
      }
      return null;
    },
    validateNumeric(value) {
      if (!value || isNaN(value)) {
        return "Enter a valid number";
      }
      return null;
    },
    onPlayerChange() {
      this.selectedSessionId = null;
      this.showDetails = false;
      this.fetchTrainingSessions();
    },
    openAddSessionModal() {
      this.showAddSessionModal = true;
      this.confirmAddDialog = false;
      this.successAddDialog = false;
    },
    confirmAddSession() {
      this.confirmAddDialog = true;
    },
    async addTrainingSession() {
      if (!this.selectedPlayerId) {
        this.showSnackbar(
          "Please select a player before adding a session.",
          "error"
        );
        return;
      }
      try {
        const accessToken = localStorage.getItem("access_token");
        const newSession = {
          ...this.newSessionData,
          player: this.selectedPlayerId,
        };
        await api.post("/api/training-sessions/", newSession, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        // Show the snackbar immediately after successful addition
        this.showSnackbar("Training session added successfully!", "success");
        this.closeAddModal();
        this.fetchTrainingSessions(); // Refresh sessions
      } catch (error) {
        console.error("Error adding training session:", error);
        const errorMessage =
          error.response?.data?.player?.[0] ||
          "An error occurred while adding, verify if empty fields.";
        this.showSnackbar(errorMessage, "error");
      }
    },

    openEditModal() {
      this.showEditSessionModal = true;
    },
    async updateTrainingSession() {
      try {
        const accessToken = localStorage.getItem("access_token");
        await api.patch(
          `/api/training-sessions/${this.selectedSessionId}/`,
          this.editSessionData,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        // Show the snackbar immediately after successful update
        this.showSnackbar("Training session updated successfully!", "success");
        this.showEditSessionModal = false;
        this.selectedSessionId = null;
        this.sessionDetails = null;
        this.fetchTrainingSessions();
      } catch (error) {
        console.error("Error editing training session:", error);
        const errorMessage =
          error.response?.data?.detail || "An error occurred while updating.";
        this.showSnackbar(errorMessage, "error");
      }
    },

    async confirmDeleteSession() {
      this.confirmDeleteDialog = true;
    },
    async deleteTrainingSession() {
      try {
        const accessToken = localStorage.getItem("access_token");
        await api.delete(`/api/training-sessions/${this.selectedSessionId}/`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        // Show the snackbar immediately after successful deletion
        this.showSnackbar("Training session deleted successfully!", "success");
        this.confirmDeleteDialog = false;
        this.selectedSessionId = null;
        this.sessionDetails = null;
        this.fetchTrainingSessions();
      } catch (error) {
        console.error("Error deleting training session:", error);
        const errorMessage =
          error.response?.data?.detail || "An error occurred while deleting.";
        this.showSnackbar(errorMessage, "error");
      }
    },

    goToComparePlayers() {
      if (this.selectedPlayerId && this.selectedSessionId) {
        const teamId = this.$route.query.teamId; // Retrieve the current teamId from the route
        console.log(
          "Navigating to ComparePlayers with the following parameters:"
        );
        console.log("Player ID:", this.selectedPlayerId);
        console.log("Session ID:", this.selectedSessionId);
        console.log("Team ID:", teamId);
        this.$router.push({
          name: "ComparePlayers",
          query: {
            playerId: this.selectedPlayerId, // Pass the selected player's ID
            sessionId: this.selectedSessionId, // Pass the selected session's ID
            teamId: teamId, // Pass the current team ID
          },
        });
      } else {
        alert("Please select a training session.");
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@media (max-width: 768px) {
  .chart-container {
    max-width: 300px; /* Adjust for smaller screens */
  }
}
.session-details-table td:nth-child(2) {
  text-align: center; /* Center-aligns text in the Value column */
  vertical-align: middle; /* Ensures numbers are vertically aligned */
  font-weight: bold; /* Optional: Makes the numbers bold */
}

.v-container {
  background-color: #ffffff; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px; /* Inner padding */
  margin: 20px auto; /* Center and add spacing around */
  width: 85%; /* Make it responsive */
}
/* Main container styling */
.training-sessions {
  font-family: "Roboto", sans-serif;
  color: #333;
  text-align: center;
  margin-top: 117px;
}

/* Loading text styling */
.loading-text {
  font-size: 1.2em;
  color: #666;
}

/* Styling for dropdown containers */
.dropdown-container {
  margin: 20px;
  max-width: 500px;
  margin: 20px auto; /* Center horizontally with margin and restrict width */
  max-width: 500px;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

/* Label styling for dropdowns */
.dropdown-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333; /* Adjust the color if needed */
  margin-bottom: 5px;
  padding-left: 5px;
}

/* Wrapper for dropdown to add custom icon */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Dropdown select styling */
.dropdown-select {
  padding: 15px;
  border-radius: 1px;
  border-bottom: 1px solid #aaa4a4;
  font-size: 1em;
  width: 100%;
  appearance: none;
  background-color: #faf9f9;
  color: #333;
}

/* Background change for focused dropdown */
.dropdown-select:focus {
  background-color: #e6e1e1;
  border: 0px solid #6200ee;
}

/* Custom icon styling */
.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) scale(1, 0.5);
  pointer-events: none;
  font-size: 0.85em;
  color: #716b77;
}
.dropdown-select option:disabled {
  color: #333; /* Gray color for disabled text */ /* Optional: italicize the placeholder text */
}
.session-details-table {
  width: 100%;
  margin: 20px auto;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 12px; /* Rounded corners for MD3 aesthetics */
  overflow: hidden; /* Ensures rounded corners apply */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for elevation */
  font-family: "Roboto", sans-serif;
  background-color: #ffffff; /* White background */
}

.session-details-table th {
  background-color: #6200ee; /* MD3 Primary Color */
  color: #ffffff; /* White text for contrast */
  padding: 16px;
  text-align: left; /* Align text to the left */
  font-weight: 700; /* Bold for headers */
  text-transform: uppercase; /* All caps for headers */
  font-size: 1rem; /* Standard font size */
}

.session-details-table td {
  padding: 16px;
  text-align: left; /* Align text to the left */
  color: #333; /* Dark text for readability */
  font-size: 1rem;
}

.session-details-table tr:nth-child(even) td {
  background-color: #f7f7f7; /* Light grey for alternate rows */
}

.session-details-table tr:nth-child(odd) td {
  background-color: #ffffff; /* White background for odd rows */
}

.session-details-table tr:hover td {
  background-color: #e1e1e1; /* Slightly darker grey for hover effect */
}

.session-details-table tr:last-child td {
  font-weight: bold; /* Bold text for STIix Index row */
  color: #6200ee; /* Highlight the STIix Index row in primary color */
  background-color: #f0efff; /* Slightly tinted background for emphasis */
}

.session-details-table th:first-child,
.session-details-table td:first-child {
  border-top-left-radius: 12px; /* Rounded corners for the first column */
  border-bottom-left-radius: 12px;
}

.session-details-table th:last-child,
.session-details-table td:last-child {
  border-top-right-radius: 12px; /* Rounded corners for the last column */
  border-bottom-right-radius: 12px;
}

/* Chart container styling for responsiveness */
.chart-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 40px auto;
  height: 600px;
}

/* Updated Compare Players button styling */
/* Updated Compare Players button styling */
.compare-button {
  margin-bottom: 20px;
  text-align: center;
}

.compare-button button {
  background-color: #6200ee; /* Blue/Purple color for Material Design 3 */
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 6px 20px;
  border: none;
  border-radius: 20px; /* Rounded corners */
  font-size: 0.8em;
  min-height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: '"Roboto"', sans-serif; /* Roboto font for button text */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); /* Subtle shadow for elevation */
}

.compare-button button:hover {
  background-color: #3700b3; /* Darker blue/purple on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Updated Add Training Session button styling */
.add-session-button {
  margin: 20px 0;
  font-size: 0.9em;
  text-align: center;
}

.add-session-button button {
  background-color: #6200ee; /* Blue/Purple color for Material Design 3 */
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 6px 20px;
  border: none;
  border-radius: 20px; /* Rounded corners */
  font-size: 0.9em;
  min-height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: '"Roboto"', sans-serif; /* Roboto font for button text */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); /* Subtle shadow for elevation */
}

.add-session-button button:hover {
  background-color: #3700b3; /* Darker blue/purple on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Icon styling for the Add button */
.add-session-button .icon {
  margin-right: 8px;
  font-size: 1.4em;
  font-weight: bold;
}

/* Action buttons for Edit and Delete Training Session */
.session-actions {
  display: flex;
  flex-direction: column; /* Stack the buttons vertically */
  gap: 10px; /* Add spacing between buttons */
  margin-top: 20px; /* Adjust as needed for spacing */
  justify-content: center; /* Center vertically if needed */
  align-items: center; /* Center buttons horizontally */
}

/* Styling for Edit button */
.session-actions .edit-button {
  background-color: #6200ee;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
}

/* Hover effect for Edit button */
.session-actions .edit-button:hover {
  background-color: #3700b3;
}

/* Styling for Delete button */
.session-actions .delete-button {
  background-color: #e53935;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
}
/* Add this to the scoped styles in TrainingSessionsComponent.vue */
.training-sessions-header {
  text-align: center;
  margin-bottom: 24px; /* Add spacing below the header */
  font-family: "Roboto", sans-serif;
  font-weight: 700; /* Bold for better emphasis */
  font-size: 1.8rem; /* Adjust font size for clarity */
  line-height: 1.6; /* Improve line spacing for readability */
  color: #333; /* Use dark text color for better contrast */
}
.training-sessions-header h2 {
  font-size: 1.8rem; /* Larger font for the main header */
  margin-bottom: 8px; /* Slight spacing between title and info */
}
/* Hover effect for Delete button */
.session-actions .delete-button:hover {
  background-color: #c62828;
}

/* Modal styling for edit session */
.v-dialog {
  max-width: 500px;
}
.player-team-info {
  font-size: 1.4rem; /* Slightly smaller font for player/team info */
  font-weight: 500; /* Medium weight for clarity */
  line-height: 1.6; /* Improve line spacing for readability */
  color: #444; /* Slightly lighter than the main header for contrast */
}
.v-card-title {
  font-size: 1.5rem;
  color: #6200ee;
  font-weight: bold;
}

.v-text-field {
  margin-bottom: 20px;
}
.chart-toggle-group {
  display: flex;
  justify-content: center;
  margin: 20px 0; /* Add spacing above and below */
}
.session-details-table th:nth-child(2) {
  text-align: center; /* Center-aligns the header text */
  vertical-align: middle; /* Ensures the header text is vertically aligned */
}
.session-details-table td:nth-child(2) {
  text-align: center; /* Center-aligns the values */
  vertical-align: middle; /* Ensures the values are vertically aligned */
  font-weight: bold; /* Optional: Makes the numbers bold */
}
</style>
