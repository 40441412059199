<template>
  <v-container max-width="600px">
    <v-card outlined class="manage-team">
      <v-card-title class="manage-teams-title">Manage Teams</v-card-title>
      <v-card-text>
        <!-- Add Team Button -->
        <v-btn
          class="add-btn fab-btn-size"
          color="primary"
          @click="addTeam"
          dark
        >
          <v-icon class="fab-icon">mdi-plus</v-icon> Add Team
        </v-btn>

        <!-- Search Bar for Filtering Teams -->
        <v-text-field
          label="Search Teams"
          v-model="searchQuery"
          clearable
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          class="search-bar"
          @input="clearSearchQuery"
        />

        <!-- Loading Alert -->
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          size="40"
          class="loading-spinner"
        ></v-progress-circular>

        <!-- Error Alert for Failures -->
        <v-alert
          v-if="showErrors"
          color="error"
          class="mt-3 custom-alert"
          border="start"
          title="Error Alert"
          variant="elevated"
        >
          <template #append>
            <v-icon large class="custom-close-icon" @click="showErrors = false"
              >mdi-close-circle</v-icon
            >
          </template>
          <div v-for="(error, index) in errors" :key="index" class="text-left">
            {{ error }}
          </div>
        </v-alert>
        <!-- Total Teams Count -->
        <p class="total-teams-count">
          There are a total of {{ filteredTeams.length }} team(s)
        </p>

        <!-- List of Teams -->
        <v-list v-if="!loading && filteredTeams.length > 0">
          <v-list-item
            v-for="team in filteredTeams"
            :key="team.id"
            class="team-item"
            justify="center"
            align="center"
            :class="{ selected: selectedTeam === team.id }"
            @click="selectTeam(team.id)"
          >
            <v-list-item-content class="centered-content">
              <!-- Centered Team Image Above the Team Name -->
              <v-img
                v-if="teamImages[team.id]"
                :src="teamImages[team.id]"
                max-width="80"
                max-height="80"
                class="team-image centered-image"
              ></v-img>

              <!-- Team Name -->
              <v-list-item-title class="team-name">{{
                team.team_name
              }}</v-list-item-title>

              <!-- Action Buttons for the Selected Team -->
              <v-row v-if="selectedTeam === team.id" class="action-buttons">
                <v-btn
                  color="primary"
                  @click="viewTeam(team.id)"
                  class="action-btn view-team-btn"
                >
                  <v-icon left>mdi-eye</v-icon> View Team Players
                </v-btn>
                <v-btn color="primary" @click="editTeam" class="action-btn">
                  <v-icon left>mdi-pencil</v-icon> Edit Team
                </v-btn>
                <v-btn
                  color="red darken-2"
                  @click="confirmDeleteTeam"
                  class="action-btn"
                >
                  <v-icon left>mdi-delete</v-icon> Delete Team
                </v-btn>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- No Teams Found Alert -->
        <v-alert
          v-if="!loading && filteredTeams.length === 0"
          type="warning"
          elevation="2"
          class="mt-4 no-teams-message"
          border="left"
          style="text-align: center; margin-top: 80px"
        >
          No teams found.
        </v-alert>
      </v-card-text>
    </v-card>

    <!-- Dialog for Adding/Editing a Team -->
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-text-field
            label="Team Name"
            v-model="teamName"
            outlined
            dense
            :rules="[nameRule]"
            :error="!teamName.trim()"
            hide-details="auto"
          ></v-text-field>
          <!-- Team Image Upload -->
          <v-file-input
            v-model="teamImageFile"
            label="Upload Team Image"
            accept="image/*"
            prepend-icon="mdi-image"
            @change="handleImageUpload"
            outline
            dense
            class="image-upload"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            :disabled="isSaveDisabled"
            @click="saveTeam"
            >{{ dialogAction }}</v-btn
          >
          <v-btn color="grey darken-1" text @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="showDeleteConfirmation" max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Team</v-card-title>
        <v-card-text>Are you sure you want to delete this team?</v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="deleteTeam">Yes, Delete</v-btn>
          <v-btn text @click="closeDeleteConfirmation">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for Feedback -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <template v-slot:actions>
        <v-btn color="white" text @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
// import api from "api";
import api from "@/axios.js";
export default {
  name: "ManageTeamComponent",
  data() {
    return {
      teams: [],
      teamImages: {}, // Stores images for each team
      teamImageFile: null, // Temporary storage for uploaded image file
      loading: true,
      selectedTeam: null,
      showDialog: false,
      showDeleteConfirmation: false,
      dialogTitle: "",
      dialogAction: "",
      teamName: "",
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success", // Default to success color
      snackbarTimeout: 4000,
      searchQuery: "",
      errors: [], // Array to hold error messages
      showErrors: false, // Controls visibility of error alert
      nameRule: (value) => !!value || "Must fill team name", // Validation rule
    };
  },
  computed: {
    isSaveDisabled() {
      // Check if name field is empty
      const nameIsEmpty = !this.teamName.trim();
      // Check if name is unchanged
      const nameUnchanged = this.teamName.trim() === this.originalTeamName;
      // Check if image is unchanged
      const imageUnchanged = !this.teamImageFile;

      // Save button is disabled if name is empty or if nothing changed
      return nameIsEmpty || (nameUnchanged && imageUnchanged);
    },
    filteredTeams() {
      const query = this.searchQuery ? this.searchQuery.toLowerCase() : "";
      return this.teams.filter((team) =>
        team.team_name.toLowerCase().startsWith(query)
      );
    },
  },
  watch: {
    teamName(newValue) {
      // Trim and synchronize teamName with originalTeamName
      if (newValue.trim() === this.originalTeamName) {
        this.$forceUpdate();
      }
    },
  },

  async mounted() {
    await this.fetchTeams();
    this.loadTeamImages();
  },
  methods: {
    async fetchTeamPicture(teamId) {
      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await api.get(`/api/team/teams/${teamId}/picture/`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "arraybuffer",
        });

        const base64Image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        this.teamImages[teamId] = `data:image/jpeg;base64,${base64Image}`;
        console.log(`[DEBUG] Team picture loaded for team ID ${teamId}.`);
      } catch (error) {
        console.error(
          `[DEBUG] Failed to fetch team picture for ID ${teamId}.`,
          error
        );
      }
    },

    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.teamImageFile = file; // Store file for FormData
        const reader = new FileReader();
        reader.onload = (e) => {
          this.teamImages[this.selectedTeam] = e.target.result; // Preview the image
        };
        reader.readAsDataURL(file);
      } else {
        this.teamImageFile = null; // Reset image file if cleared
      }
    },

    /**
     * Load team images from localStorage when component is mounted.
     */
    loadTeamImages() {
      this.teams.forEach((team) => {
        if (team.team_picture) {
          // Convert base64 to a URL-friendly format for display
          this.teamImages = {
            ...this.teamImages,
            [team.id]: `data:image/jpeg;base64,${team.team_picture}`,
          };
        }
      });
    },

    /**
     * Handle image upload and store the image data in localStorage.
     */

    /**
     * Fetch the list of teams for the authenticated user.
     */
    async fetchTeams() {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          this.$router.push("/login");
        } else {
          const response = await api.get("/api/team/teams/", {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          this.teams = response.data;

          // Process team pictures to be displayed as images
          this.teams.forEach((team) => {
            if (team.team_picture) {
              this.teamImages[
                team.id
              ] = `data:image/jpeg;base64,${team.team_picture}`;
            }
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized, redirecting to login:", error);
          this.$router.push("/login");
        } else {
          console.error("Error fetching teams:", error);
        }
        this.handleError(error, "Error fetching teams.");
      } finally {
        this.loading = false;
      }
    },

    /**
     * Navigate to the Player Management page for the selected team.
     */
    viewTeam(teamId) {
      this.$router.push({ name: "PlayerManagement", query: { teamId } });
    },

    /**
     * Toggle selection of a team to view or hide its action buttons.
     */
    selectTeam(teamId) {
      this.selectedTeam = teamId;
    },

    /**
     * Open the dialog for adding a new team.
     */
    addTeam() {
      this.dialogTitle = "Add New Team";
      this.dialogAction = "Add";
      this.teamName = "";
      this.teamImageFile = null; // Reset image file
      this.showDialog = true;
    },

    /**
     * Confirm deletion of the selected team.
     */
    confirmDeleteTeam() {
      this.showDeleteConfirmation = true;
    },

    /**
     * Delete the selected team and remove its image from localStorage.
     */
    async deleteTeam() {
      if (this.selectedTeam) {
        try {
          const accessToken = localStorage.getItem("access_token");
          await api.delete(`/api/team/teams/${this.selectedTeam}/`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          this.teams = this.teams.filter(
            (team) => team.id !== this.selectedTeam
          );
          localStorage.removeItem(`team_image_${this.selectedTeam}`);
          this.selectedTeam = null;
          this.showDeleteConfirmation = false;
          this.showSnackbar("Team deleted successfully.", "success");
        } catch (error) {
          this.handleError(error, "Error deleting team.");
        }
      }
    },

    /**
     * Open the dialog for editing the selected team's name and image.
     */
    editTeam() {
      if (this.selectedTeam) {
        const team = this.teams.find((t) => t.id === this.selectedTeam);
        if (team) {
          this.dialogTitle = "Edit Team Name";
          this.dialogAction = "Save";

          // Explicitly reset the state
          this.originalTeamName = team.team_name.trim();
          this.teamName = team.team_name.trim();
          this.teamImageFile = null; // Reset image file input

          // Open the dialog and force refresh the component state
          this.showDialog = true;
          this.$nextTick(() => {
            this.$forceUpdate(); // Ensure computed properties are updated
          });
        }
      }
    },

    /**
     * Save the new or edited team to the backend and display success messages.
     */
    async saveTeam() {
      console.log("[DEBUG] Save Team initiated.");
      const accessToken = localStorage.getItem("access_token");

      const teamExists = this.teams.some(
        (team) =>
          team.team_name.trim().toLowerCase() ===
            this.teamName.trim().toLowerCase() &&
          (this.dialogAction === "Add" || team.id !== this.selectedTeam)
      );

      if (teamExists) {
        console.log("[DEBUG] Duplicate team name detected.");
        this.showSnackbar(
          "Team already exists. Please choose another name.",
          "error"
        );
        return;
      }

      try {
        const formData = new FormData();
        formData.append("team_name", this.teamName.trim());
        if (this.teamImageFile) {
          formData.append("team_picture_upload", this.teamImageFile); // Match backend field
          console.log(
            "[DEBUG] Team picture added to FormData:",
            this.teamImageFile.name
          );
        } else {
          console.log("[DEBUG] No team picture uploaded.");
        }

        let response;
        if (this.dialogAction === "Add") {
          console.log("[DEBUG] Adding a new team...");
          response = await api.post("/api/team/teams/", formData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          });
        } else if (this.dialogAction === "Save") {
          console.log("[DEBUG] Updating an existing team...");
          response = await api.put(
            `/api/team/teams/${this.selectedTeam}/`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }

        console.log("[DEBUG] Server Response:", response.data);

        // Update teams list or handle success
        if (this.dialogAction === "Add") {
          this.teams.push(response.data);
          if (response.data.team_picture) {
            this.teamImages[
              response.data.id
            ] = `data:image/jpeg;base64,${response.data.team_picture}`;
          }
          this.showSnackbar("Team added successfully.", "success");
        } else if (this.dialogAction === "Save") {
          const index = this.teams.findIndex((t) => t.id === this.selectedTeam);
          this.teams[index].team_name = response.data.team_name;
          if (response.data.team_picture) {
            this.teamImages[
              this.selectedTeam
            ] = `data:image/jpeg;base64,${response.data.team_picture}`;
          }
          this.showSnackbar("Team updated successfully.", "success");
        }

        this.teamImageFile = null;
        this.closeDialog();
      } catch (error) {
        console.error("[DEBUG] Error in saveTeam:", error);
        if (error.response) {
          console.error("[DEBUG] Response Error Data:", error.response.data);
        }
        this.handleError(error, "Error saving team.");
      }
    },

    /**
     * Handle errors and display them in an alert.
     */
    handleError(error, defaultMessage) {
      this.errors = [];
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        for (const key in errorData) {
          if (Object.prototype.hasOwnProperty.call(errorData, key)) {
            if (Array.isArray(errorData[key])) {
              this.errors.push(`${key}: ${errorData[key].join(" ")}`);
            } else {
              this.errors.push(`${key}: ${errorData[key]}`);
            }
          }
        }
      } else {
        this.errors.push(defaultMessage);
      }
      this.showErrors = true;
    },

    /**
     * Close the dialog for adding or editing a team.
     */
    closeDialog() {
      this.showDialog = false;
    },

    /**
     * Close the delete confirmation dialog.
     */
    closeDeleteConfirmation() {
      this.showDeleteConfirmation = false;
    },

    /**
     * Display a snackbar with a specific message and color.
     */
    showSnackbar(message, color = "success") {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    /**
     * Clear the search query.
     */
    clearSearchQuery() {
      if (this.searchQuery === null) {
        this.searchQuery = ""; // Reset searchQuery if it becomes null
      }
    },
  },
};
</script>

<style scoped>
/* Main Container for Manage Team */
.manage-team {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}
.loading-spinner {
  display: block;
  margin: 20px auto;
}
.total-teams-count {
  text-align: center;
  font-size: 1rem;
  margin: 10px 0; /* Add spacing around the text */
  color: #333; /* Neutral text color */
}

.error-text {
  color: red;
  font-size: 0.875rem;
  margin-top: 4px;
}

/* Add margin top for the image upload field */
.image-upload {
  margin-top: 20px;
}
/* Title Section for Manage Teams */
.manage-teams-title {
  text-align: center;
  font-size: 2rem;
  margin-top: 7px;
  margin-bottom: 10px;
  font-weight: bold; /* Keep title prominent */
}
/* Styling for Search Bar */
.search-bar {
  margin-top: 50px;
  margin-bottom: 1px; /* Move closer to teams */
}
/* Search Bar for Filtering Teams */
.mb-4 {
  margin-bottom: 16px;
}

/* Add Team Button */
.add-btn {
  display: flex;
  justify-content: center;
  margin: 10px auto; /* Reduce spacing */
  height: 40px;
  width: 150px;
}
/* Icon in FAB Button */
.fab-icon {
  font-size: 1.5rem;
  margin-right: 8px;
}
/* Specific Styling for "View Team Players" Button */
.view-team-btn {
  color: white !important; /* Make text white */
}
/* Action Buttons for View, Edit, Delete */
.action-btn {
  width: 90%; /* Make each button span the full width */
  margin: 5px;
  font-size: 0.75rem;
  margin-bottom: 15px;
}

/* Primary Button Styling (e.g., Edit) */
.action-btn.primary-button {
  background-color: #6200ee;
  color: white;
}

.action-btn.primary-button:hover {
  background-color: #3700b3;
}

/* Delete Button Styling */
.action-btn.delete-button {
  background-color: #b00020;
  color: white;
}

.action-btn.delete-button:hover {
  background-color: #9a0007;
}

/* Icon Styling within Buttons */
.v-icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

/* Container for Action Buttons (View, Edit, Delete) */
.action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

/* Styling for each Team Item */
/* Team Item Default Styling with Heavier Border */
/* Styling for Each Team Item */
.team-item {
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 2px solid #e0e0e0; /* Slightly darker border */
  border-radius: 8px;
  transition: border 0.3s, background-color 0.3s;
}
.team-item:hover {
  cursor: pointer;
}

/* Selected Team Styling */
.selected {
  border: 2px solid #6200ee; /* Highlighted border for selected team */
  background-color: #f0f0f0;
}

/* Styling for Team Name */
/* Centered team image styling */
.centered-image {
  display: block;
  margin: 0 auto 10px auto; /* Center horizontally and add margin below */
  border-radius: 50%; /* Ensures a circular shape */
}
.centered-content {
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-left: 600px;
}
/* Team name styling adjustments */
.team-name {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

/* Loading and No Team Messages */
.loading-text {
  font-size: 1.2em;
}

.no-teams-message {
  font-size: 1.1em;
}

/* Centered Snackbar Content */
.v-snackbar__content {
  display: flex;
  justify-content: space-between; /* Space between text and close button */
  width: 100%; /* Ensure it takes full width */
  padding: 0 16px; /* Add padding for better spacing */
}

/* Custom Close Icon for Alerts */
.custom-close-icon {
  font-size: 28px;
  color: #ffffff !important;
  cursor: pointer;
  margin-left: auto;
}

/* Align text to the left in error messages */
.text-left {
  text-align: left;
}
</style>
