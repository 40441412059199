<template>
  <v-container max-width="500px">
    <v-card outlined class="forgot-password">
      <!-- Title for the forgot password card -->
      <v-card-title class="headline-small text-h5 forgot-password-title">
        Forgot Password
      </v-card-title>

      <v-card-text>
        <!-- Form for password reset -->
        <v-form @submit.prevent="submitForm" lazy-validation>
          <!-- Email input field with icon -->
          <v-row>
            <v-col>
              <v-text-field
                v-model="email"
                label="Enter your email address"
                type="email"
                :rules="[rules.required, rules.email]"
                outlined
                dense
                clearable
                required
                prepend-inner-icon="mr-1 mdi-email"
                :error-messages="fieldErrors.email"
                @blur="validateField('email')"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Send Reset Link button -->
          <v-row>
            <v-col>
              <v-btn
                :loading="loading"
                type="submit"
                color="primary"
                block
                large
                class="mt-4 reset-btn"
                variant="elevated"
                :disabled="loading"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-icon left v-if="!loading">mr-2 mdi-send</v-icon> Send Reset
                Link
              </v-btn>
            </v-col>
          </v-row>

          <!-- Return to Login button -->
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                block
                large
                class="mt-3 return-btn"
                @click="goToLogin"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-icon left>mr-2 mdi-login</v-icon> Return to Login
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <!-- Success alert -->
        <v-alert
          v-if="alerts.success"
          color="success"
          class="mt-3 success-alert custom-alert text-left"
          border="start"
          close-label="Close Alert"
          title="Success"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.success = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          {{ alerts.success }}
        </v-alert>

        <!-- Error alert -->
        <v-alert
          v-if="alerts.error"
          color="error"
          class="mt-3 error-alert custom-alert text-left"
          border="start"
          close-label="Close Alert"
          title="Error"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.error = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          {{ alerts.error }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/axios.js";
export default {
  name: "ForgotPasswordComponent",
  data() {
    return {
      email: "",
      loading: false,
      fieldErrors: {
        email: [],
      },
      alerts: {
        success: "",
        error: "",
      },
      rules: {
        required: (value) => !!value || "Email is required.",
        email: (value) => /.+@.+\..+/.test(value) || "Email must be valid.",
      },
    };
  },
  methods: {
    // Validate specific fields and update error messages
    validateField(field) {
      if (!this[field]) {
        this.fieldErrors[field] = [
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`,
        ];
      } else {
        this.fieldErrors[field] = [];
      }
    },
    // Form submission method with loading state and error/success handling
    async submitForm() {
      this.clearAlerts(); // Clear previous alerts before submission
      this.loading = true; // Enable loading state

      // Check if email is empty before making API call
      if (!this.email) {
        this.fieldErrors.email = ["Email is required."];
        this.loading = false;
        return;
      }

      try {
        await api.post("/api/auth/forgot-password/", { email: this.email });
        this.alerts.success =
          "A password reset link has been sent to your email.";
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.alerts.error =
            "This email address does not exist in our records.";
        } else {
          this.alerts.error = "Error sending reset link. Please try again.";
        }
      } finally {
        this.loading = false; // Reset loading state after request completes
      }
    },
    // Clear all alert messages
    clearAlerts() {
      this.alerts.success = "";
      this.alerts.error = "";
    },
    // Redirect to login page
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
/* Styling for Return to Login button */
.return-btn {
  display: block;
  width: 98%;
  margin-left: 1%;
  padding: 12px;
  background-color: #03dac6; /* Secondary color */
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.return-btn:hover {
  background-color: #018786; /* Hover color for secondary button */
}
/* Card styling for Forgot Password */
.forgot-password {
  border-radius: 12px; /* Rounded corners */
  padding: 40px; /* Inner padding */
  margin: 100px auto; /* Center the card vertically */
  text-align: center; /* Center text */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #ffffff; /* White background */
}

/* Title styling */
.forgot-password-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
  text-align: center;
}

/* Button styling for Send Reset Link */
.reset-btn {
  display: block;
  width: 98%;
  margin-left: 1%;
  padding: 12px;
  background-color: #6200ee;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.reset-btn:hover {
  background-color: #3700b3;
}

/* Success alert styling */
.success-alert {
  background-color: #e6ffed;
  color: #388e3c;
}

/* Error alert styling */
.error-alert {
  background-color: #ffebee;
  color: #d32f2f;
}

/* Close icon styling for alerts */
.custom-close-icon {
  font-size: 24px;
  color: #ffffff !important;
  cursor: pointer;
}
</style>
