<template>
  <v-container class="manage-players" max-width="700px">
    <div class="manage-players-container">
      <!-- Header Title -->
      <v-card-title class="manage-players-title" style="cursor: default">
        Manage Players for {{ teamName }}
      </v-card-title>

      <v-card-text>
        <!-- Add Player Button -->
        <v-row justify="center" align="center" class="control-row mb-3">
          <v-col cols="12" md="6" class="mb-3">
            <v-btn
              class="add-player-fab"
              color="primary"
              @click="addPlayer"
              dark
            >
              <v-icon left>mdi-plus</v-icon> Add Player
            </v-btn>
          </v-col>
        </v-row>

        <!-- Filter by Position -->
        <v-row justify="center" align="center" class="control-row">
          <v-col cols="12" md="6">
            <v-select
              v-model="positionFilter"
              :items="[
                'ALL',
                'Setter',
                'Opposite',
                'Middle Blocker',
                'Outside Hitter',
                'Libero',
                'Server',
              ]"
              label="Choose Position"
              dense
              outlined
              hide-details
              :clearable="false"
            ></v-select>
          </v-col>
        </v-row>
        <!-- Total Teams Count -->
        <v-row justify="center" align="center" class="control-row">
          <v-col cols="12" md="6" class="team-count">
            There are a total of {{ filteredPlayers.length }} player(s).
          </v-col>
        </v-row>

        <!-- Error Message Alert -->
        <v-alert
          v-if="alerts.error"
          color="error"
          class="custom-alert"
          border="start"
          title="Error"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.error = false"
              >mdi-close-circle</v-icon
            >
          </template>
          <div class="text-left">{{ errorMessage }}</div>
        </v-alert>

        <!-- Loading Spinner -->
        <v-row
          justify="center"
          align="center"
          v-if="loading"
          class="spinner-row"
        >
          <v-col cols="12">
            <v-progress-circular
              :size="70"
              :width="7"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>

        <!-- Players List as Cards -->
        <v-row v-if="!loading && sortedPlayers.length > 0" class="player-cards">
          <v-col
            v-for="player in sortedPlayers"
            :key="player.id"
            cols="12"
            md="6"
          >
            <v-card
              :class="{ selected: selectedPlayerId === player.id }"
              outlined
              @click="selectPlayer(player.id)"
              class="player-card"
              elevation="2"
              style="cursor: pointer"
            >
              <v-card-title class="player-title">
                <v-icon left>mdi-account</v-icon> {{ player.name }}
                {{ player.last_name }}
              </v-card-title>

              <v-card-text class="player-info">
                <p>
                  <v-icon left>mdi-numeric"></v-icon>
                  <strong>Player Number:</strong> {{ player.player_number }}
                </p>
                <p>
                  <v-icon left>mdi-soccer-field"></v-icon>
                  <strong>Position:</strong> {{ player.position }}
                </p>
              </v-card-text>

              <!-- Floating Action Buttons for the Selected Player -->
              <v-row
                v-if="selectedPlayerId === player.id"
                class="floating-action-buttons"
              >
                <v-col>
                  <v-btn
                    @click="viewPlayerSessions(player)"
                    color="primary"
                    block
                  >
                    <v-icon left>mdi-eye</v-icon> View Player Data
                  </v-btn>
                </v-col>

                <!-- Edit Player Button -->
                <v-col>
                  <v-btn @click="editPlayer" color="primary" block>
                    <v-icon left>mdi-pencil</v-icon> Edit Player
                  </v-btn>
                </v-col>

                <!-- Delete Player Button -->
                <v-col>
                  <v-btn
                    @click="confirmDeletePlayer"
                    color="red darken-2"
                    block
                  >
                    <v-icon left>mdi-delete</v-icon> Delete Player
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- No Players Found Message -->
        <v-alert
          v-if="!loading && sortedPlayers.length === 0"
          type="warning"
          elevation="2"
          class="no-players-message"
          border="left"
          style="text-align: center; margin-top: 80px"
        >
          No players found.
        </v-alert>
      </v-card-text>

      <!-- Add/Edit Player Dialog -->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>{{
            isEditing ? "Edit Player" : "Add Player"
          }}</v-card-title>
          <v-card-text>
            <v-form ref="playerForm">
              <v-text-field
                v-model="playerForm.name"
                label="Name"
                outlined
                dense
                :rules="[nameRule]"
                :error="!playerForm.name.trim()"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-model="playerForm.last_name"
                label="Last Name"
                outlined
                dense
                :rules="[lastNameRule]"
                :error="!playerForm.last_name.trim()"
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-model="playerForm.player_number"
                label="Player Number"
                outlined
                dense
                :rules="[numberRule]"
                :error="
                  !playerForm.player_number || isNaN(playerForm.player_number)
                "
                hide-details="auto"
              ></v-text-field>
              <v-select
                v-model="playerForm.position"
                :items="[
                  'Setter',
                  'Opposite',
                  'Middle Blocker',
                  'Outside Hitter',
                  'Libero',
                  'Server',
                ]"
                label="Position"
                dense
                outlined
                hide-details
                required
                :rules="[positionRule]"
                :error="!playerForm.position.trim()"
              ></v-select>
            </v-form>
            <v-alert
              v-if="alerts.error"
              color="error"
              class="custom-alert"
              border="start"
              title="Error"
              variant="elevated"
            >
              <template #append>
                <v-icon
                  large
                  class="custom-close-icon"
                  @click="alerts.error = false"
                  >mdi-close-circle</v-icon
                >
              </template>
              <div class="text-left">{{ errorMessage }}</div>
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              :disabled="
                !playerForm.name.trim() ||
                !playerForm.last_name.trim() ||
                !playerForm.position.trim()
              "
              @click="savePlayer"
            >
              {{ isEditing ? "Save Changes" : "Add Player" }}
            </v-btn>
            <v-btn color="red" @click="dialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation Dialog for Delete -->
      <v-dialog v-model="confirmDeleteDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Delete Player</v-card-title>
          <v-card-text
            >Are you sure you want to delete this player?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" @click="deletePlayer">Yes</v-btn>
            <v-btn @click="confirmDeleteDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Snackbar for Notifications -->
      <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
      >
        {{ snackbarText }}
        <template v-slot:actions>
          <v-btn color="white" text @click="snackbar = false">Close</v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
// import api from "api";
import api from "@/axios.js";
export default {
  name: "PlayerManagementComponent",
  data() {
    return {
      players: [],
      teamName: "",
      loading: true,
      selectedPlayerId: null,
      dialog: false,
      confirmDeleteDialog: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
      snackbarTimeout: 4000,
      isEditing: false,
      formValid: false,
      errorMessage: "",
      maxPlayers: 20, // Maximum number of players allowed
      playerForm: {
        name: "",
        last_name: "",
        player_number: "",
        position: "",
      },
      originalPlayerForm: {}, // For tracking changes in edit
      positionFilter: "ALL", // Filter for position
      nameRule: (value) => !!value || "Must fill name", // Validation rule
      lastNameRule: (value) => !!value || "Must fill last name", // Validation rule
      numberRule: (value) => !!value || "Must fill player number", // Validation rule
      positionRule: (value) => !!value || "Must fill position", // Validation rule
      rules: {
        numeric: (value) => !isNaN(value) || "Player Number must be numeric.",
      },
      alerts: {
        error: false, // Track visibility of the error alert
      },
    };
  },
  computed: {
    filteredPlayers() {
      const seen = new Set();
      return this.players.filter((player) => {
        const identifier = `${player.name}-${player.last_name}`;
        const passesFilter =
          this.positionFilter === "ALL" ||
          player.position === this.positionFilter;
        if (!seen.has(identifier) && passesFilter) {
          seen.add(identifier);
          return true;
        }
        return false;
      });
    },
    sortedPlayers() {
      return [...this.filteredPlayers].sort((a, b) =>
        a.position.localeCompare(b.position)
      );
    },
    isFormChanged() {
      if (this.isEditing && this.originalPlayerForm) {
        return Object.keys(this.playerForm).some(
          (key) => this.playerForm[key] !== this.originalPlayerForm[key]
        );
      }
      return true; // Always allow adding in "Add Player" mode
    },
  },
  watch: {
    players(newPlayers) {
      // Clear the error message if the player count goes below the maximum allowed
      if (newPlayers.length < this.maxPlayers) {
        this.errorMessage = "";
        this.alerts.error = false;
      }
    },
    playerForm: {
      deep: true,
      handler() {
        this.validateForm();
      },
    },
  },
  async mounted() {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        this.$router.push("/login");
        return;
      }

      const teamId = this.$route.query.teamId;

      // Fetch team information
      const teamResponse = await api.get(`/api/team/teams/${teamId}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      this.teamName = teamResponse.data.team_name;

      // Fetch players for the team
      const response = await api.get(`/api/players/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { teamId },
      });
      this.players = response.data;
    } catch (error) {
      console.error("Error fetching players:", error);
      this.errorMessage = "Failed to load players. Please try again.";
      this.alerts.error = true;
      this.$router.push("/login");
    } finally {
      this.loading = false;
    }
  },
  methods: {
    validateForm() {
      // Ensure all fields are filled and valid
      this.formValid =
        this.playerForm.name.trim() !== "" &&
        this.playerForm.last_name.trim() !== "" &&
        this.playerForm.player_number.trim !== null &&
        !isNaN(this.playerForm.player_number) && // Ensure Player Number is numeric
        this.playerForm.position.trim() !== ""; // Ensure position is selected
    },

    selectPlayer(playerId) {
      this.selectedPlayerId = playerId;
    },
    viewPlayerSessions(player) {
      if (this.selectedPlayerId) {
        const teamId = this.$route.query.teamId; // Retrieve the current teamId
        this.$router.push({
          name: "TrainingSessions",
          query: {
            teamId: teamId, // Pass the teamId
            playerId: player.id, // Pass the selected player's id
          },
        });
      }
    },

    addPlayer() {
      if (this.players.length >= this.maxPlayers) {
        this.errorMessage =
          "You have 20 players in this team, cannot add more players until you delete one.";
        this.alerts.error = true;
        return;
      }

      this.errorMessage = ""; // Clear error if under the max player limit
      this.alerts.error = false;
      this.dialog = true;
      this.isEditing = false;
      this.playerForm = {
        name: "",
        last_name: "",
        player_number: "",
        position: "",
      };
      this.formValid = false;
    },
    editPlayer() {
      const player = this.players.find((p) => p.id === this.selectedPlayerId);
      if (player) {
        this.dialog = true;
        this.isEditing = true;
        this.errorMessage = "";
        this.alerts.error = false;
        this.playerForm = {
          ...player, // No need to explicitly convert player_number; .number handles it
        };
        this.originalPlayerForm = { ...player };
        this.formValid = false;
      }
    },
    confirmDeletePlayer() {
      this.confirmDeleteDialog = true;
    },
    deletePlayer() {
      const accessToken = localStorage.getItem("access_token");
      api
        .delete(`/api/players/${this.selectedPlayerId}/`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(() => {
          this.players = this.players.filter(
            (p) => p.id !== this.selectedPlayerId
          );
          this.selectedPlayerId = null;
          this.confirmDeleteDialog = false;

          this.showSnackbar("Player deleted successfully.", "success");
        })
        .catch(() => {
          this.showSnackbar("Failed to delete player.", "error");
        });
    },
    savePlayer() {
      const { name, last_name, player_number, position } = this.playerForm;

      // Validation for required fields and numeric check for player number
      if (!name || !last_name || !player_number || !position) {
        this.errorMessage = "Please fill out all fields.";
        this.alerts.error = true;
        return;
      }
      if (isNaN(player_number)) {
        this.errorMessage = "Player Number must be numeric.";
        this.alerts.error = true;
        return;
      }

      // Check for duplicate name and last name within the team (case-insensitive)
      const duplicateName = this.players.some(
        (player) =>
          player.name.toLowerCase() === name.trim().toLowerCase() &&
          player.last_name.toLowerCase() === last_name.trim().toLowerCase() &&
          (!this.isEditing || player.id !== this.selectedPlayerId)
      );

      if (duplicateName) {
        this.showSnackbar(
          "A player with this name and last name already exists in this team.",
          "error"
        );
        return;
      }

      // Check for duplicate player number within the team
      const duplicateNumber = this.players.some(
        (player) =>
          player.player_number === player_number &&
          (!this.isEditing || player.id !== this.selectedPlayerId)
      );

      if (duplicateNumber) {
        console.log("Duplicate player number found.");
        this.errorMessage =
          "A player with this player number already exists in this team.";
        this.alerts.error = true;
        return;
      }
      const accessToken = localStorage.getItem("access_token");
      const teamId = this.$route.query.teamId;

      if (this.isEditing) {
        api
          .put(`/api/players/${this.selectedPlayerId}/`, this.playerForm, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            const index = this.players.findIndex(
              (p) => p.id === this.selectedPlayerId
            );
            this.players[index] = response.data;
            console.log("Player edited successfully:", response.data);
            this.dialog = false;
            this.selectedPlayerId = null;
            this.showSnackbar("Player edited successfully.", "success");
          })
          .catch(() => {
            this.showSnackbar(
              "Failed to Edit Player, player number already assigned to a player in team.",
              "error"
            );
          });
      } else {
        this.playerForm.team = teamId;
        api
          .post("/api/players/", this.playerForm, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((response) => {
            this.players.push(response.data);
            console.log("Player added successfully:", response.data);
            this.dialog = false;
            this.showSnackbar("Player added successfully.", "success");
          })
          .catch(() => {
            this.showSnackbar(
              "Failed to Edit Player, player number already assigned to a player in team.",
              "error"
            );
          });
      }
    },
    /**
     * Display a snackbar with a specific message and color.
     */
    showSnackbar(message, color = "success") {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.manage-players {
  background-color: #ffffff; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
  padding: 20px; /* Inner padding for spacing */
  text-align: center;
  margin-top: 117px;
}

.v-text-field,
.v-select {
  margin-bottom: 16px; /* Consistent spacing between fields */
}

.error-text {
  color: #e74c3c; /* Red color for the error text */
  font-size: 0.85rem; /* Slightly smaller font size for error messages */
  margin-top: -8px; /* Bring the error text closer to the text field */
  margin-bottom: 0; /* Ensure no additional spacing below */
  position: relative; /* Keeps the positioning aligned with the input */
}

.manage-players-title {
  text-align: center;
  font-size: 2rem;
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: bold; /* Keep title prominent */
}
.manage-players .v-card-title {
  font-weight: bold;
}
.filter-row {
  text-align: center;
}

/* Adjusted Loading Message Style */
.loading-text {
  font-size: 1.2em;
  margin-top: 80px; /* Proper spacing */
  text-align: center; /* Center the message */
  background-color: transparent; /* Matches the container */
}

/* Player Cards Container */
.player-cards {
  margin: -20px auto;
}

/* Styling for Player Cards */
.v-card {
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #f9f9f9; /* Light gray for subtle contrast */
  border-radius: 8px;
}

.v-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly more pronounced shadow */
  background-color: #f5f5f5; /* Slight change on hover */
}

.v-card.selected {
  background-color: #d9edf7; /* Light blue background for selected cards */
  border-color: #42b983; /* Green border */
  border-width: 2px;
  border-radius: 8px; /* Consistent with the card style */
}

/* Player Title Styling */
.player-title {
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  color: #333; /* Subtle text color for readability */
}

/* Player Information Text */
.player-info {
  font-size: 1rem;
  padding-top: 8px;
  line-height: 1.5;
  color: #333; /* Subtle contrast for secondary info */
}

/* Floating Action Buttons for View, Edit, Report, Delete */
.floating-action-buttons {
  padding: 10px;
  background-color: #f7f7f7; /* Subtle contrast for the action button area */
  border-top: 1px solid #e0e0e0;
  box-shadow: inset 0 -2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

/* No Players Found Message Styling */
.no-players-message {
  color: #e74c3c;
  font-size: 1.1em;
  margin-top: 50px;
  text-align: center;
}
.control-row {
  margin-bottom: 20px;
}

/* Add Player FAB Button */
.add-player-fab {
  display: flex;
  justify-content: center;
  margin: 1px auto; /* Reduce spacing */
  height: 40px;
  width: 150px;
}

.fab-icon {
  font-size: 1.5rem;
}

/* Error Message for Max Players */
.max-players-error {
  margin-top: 50px;
  color: #e74c3c;
  font-size: 1em;
  text-align: center;
}

/* New Custom Alert Styling */
.custom-alert {
  font-size: 1rem;
  border-radius: 8px; /* Consistent with card styles */
  margin-top: 40px;
  margin-bottom: 20px;
}

.custom-close-icon {
  color: white; /* Ensures the close icon is visible on a red background */
  cursor: pointer;
  margin-left: 8px; /* Slight spacing from text */
}

/* Elevation Variant for Alerts */
.v-alert.variant-elevated {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Material Design elevation */
}

/* Snackbar Styling */
.v-snackbar__content {
  display: flex;
  justify-content: space-between; /* Space between text and close button */
  align-items: center; /* Vertically align content */
  width: 100%; /* Ensure it takes full width */
  padding: 0 16px; /* Add padding for better spacing */
  font-size: 1rem; /* Standard font size for snackbar text */
}
.spinner-row {
  margin-top: 100px;
  text-align: center;
}

.v-snackbar {
  border-radius: 8px; /* Rounded edges for snackbar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for elevation */
}

.v-btn.text {
  font-weight: bold; /* Emphasize "Close" button text */
  text-transform: uppercase; /* Align with Material Design guidelines */
}

/* Modal Button Styling */
.v-card-actions .v-btn {
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 20px; /* Rounded buttons for modern styling */
  padding: 8px 24px; /* Balanced padding for better appearance */
}

.v-card-actions .v-btn[color="green"] {
  background-color: #28a745; /* Vibrant green */
  color: white; /* Ensure text is visible */
}

.v-card-actions .v-btn[color="green"]:hover {
  background-color: #218838; /* Darker shade on hover */
}

.v-card-actions .v-btn[color="red"] {
  background-color: #dc3545; /* Vibrant red */
  color: white;
}

.v-card-actions .v-btn[color="red"]:hover {
  background-color: #c82333; /* Darker red on hover */
}
.team-count {
  text-align: center;
  font-size: 1rem;
  margin: 10px 0; /* Add spacing around the text */
  color: #333; /* Neutral text color */
}
</style>
