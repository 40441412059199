/* eslint-disable */ /* prettier-ignore */
<template>
  <v-container max-width="500px">
    <!-- Login card with adjusted styles -->
    <v-card outlined class="login">
      <v-card-title class="headline-small text-h5 login-title">
        Login
      </v-card-title>

      <v-card-text>
        <!-- Form submission handler prevents default form submission behavior -->
        <v-form @submit.prevent="submitLogin" lazy-validation>
          <v-row>
            <v-col>
              <!-- Username input field with validation rules and error display -->
              <v-text-field
                v-model="form.username"
                label="Username"
                :rules="[rules.required]"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-account"
                :error-messages="fieldErrors.username"
                @blur="validateField('username')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <!-- Password input field with toggle for visibility -->
              <v-text-field
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                outlined
                dense
                required
                prepend-inner-icon="mr-3 mdi-lock"
                :error-messages="fieldErrors.password"
                @blur="validateField('password')"
                :append-inner-icon="
                  showPassword ? 'ml-3 mdi-eye' : 'ml-3 mdi-eye-off'
                "
                @click:append-inner="togglePasswordVisibility"
                class="custom-icon-position"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Login Button with loading state and icon -->
          <v-row>
            <v-col>
              <v-btn
                :loading="loading"
                type="submit"
                color="primary"
                block
                large
                class="mt-4 login-btn"
                variant="elevated"
                :disabled="loading"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <v-icon left v-if="!loading">mr-2 mdi-login</v-icon> LOGIN
              </v-btn>
            </v-col>
          </v-row>

          <!-- Forgot Password Link (moved below Login Button) -->
          <v-row>
            <v-col>
              <router-link to="/forgot-password" class="forgot-password-link">
                Forgot Password?
              </router-link>
            </v-col>
          </v-row>
        </v-form>

        <!-- Success Alert with custom close icon -->
        <v-alert
          v-if="alerts.success"
          color="success"
          class="mt-3 success-alert custom-alert text-left"
          close-label="Close Alert"
          border="start"
          title="Success Alert"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.success = false"
            >
              mdi-close-circle
            </v-icon>
          </template>
          Successful, login and redirecting to dashboard...
        </v-alert>

        <!-- Username Required Alert -->
        <v-alert
          v-if="alerts.usernameOnly"
          color="error"
          class="mt-3 custom-alert"
          border="start"
          title="Missing Information"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.usernameOnly = false"
              >mdi-close-circle</v-icon
            >
          </template>
          <div class="text-left">Username is required.</div>
        </v-alert>

        <!-- Password Required Alert -->
        <v-alert
          v-if="alerts.passwordOnly"
          color="error"
          class="mt-3 custom-alert"
          border="start"
          title="Missing Information"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.passwordOnly = false"
              >mdi-close-circle</v-icon
            >
          </template>
          <div class="text-left">Password is required.</div>
        </v-alert>

        <!-- Both Fields Required Alert -->
        <v-alert
          v-if="alerts.noInput"
          color="error"
          class="mt-3 custom-alert"
          border="start"
          title="Missing Information"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.noInput = false"
              >mdi-close-circle</v-icon
            >
          </template>
          <div class="text-left">Username and Password are required.</div>
        </v-alert>

        <!-- Invalid Credentials Alert -->
        <v-alert
          v-if="alerts.invalidCredentials"
          color="error"
          class="mt-3 custom-alert"
          border="start"
          title="Authentication Failed"
          variant="elevated"
        >
          <template #append>
            <v-icon
              large
              class="custom-close-icon"
              @click="alerts.invalidCredentials = false"
              >mdi-close-circle</v-icon
            >
          </template>
          <div class="text-left">Invalid credentials. Please try again.</div>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// import axios from "axios";
import api from "@/axios.js";
export default {
  name: "LoginComponent",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showPassword: false, // Controls visibility of password
      loading: false, // Controls loading state for login button
      fieldErrors: {
        // Stores validation error messages for fields
        username: [],
        password: [],
      },
      alerts: {
        // Stores the visibility state of various alerts
        noInput: false,
        usernameOnly: false,
        passwordOnly: false,
        invalidCredentials: false,
        success: false, // Success alert control
      },
      rules: {
        required: (value) => !!value || "", // Validation rule for required fields
      },
    };
  },
  async created() {
    // Check for existing access token in local storage and validate it
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      try {
        // Verify token with backend to confirm session
        const response = await api.get("/api/auth/validate-token/", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        // Redirect to dashboard if token is valid
        if (response.status === 200) {
          this.$router.push("/dashboard");
        }
      } catch (error) {
        console.error("Error validating token:", error);
      }
    }
  },
  methods: {
    // Toggle visibility of password field
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    // Validate a specific field based on whether it has input
    validateField(field) {
      if (!this.form[field]) {
        this.fieldErrors[field] = [
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`,
        ];
      } else {
        this.fieldErrors[field] = [];
      }
    },
    // Submit the login form data
    async submitLogin() {
      // Set loading to true to disable the login button
      this.loading = true;
      // Reset alerts and field errors before validation
      this.resetErrors();

      // Validation checks for empty fields and set corresponding alerts
      if (!this.form.username && !this.form.password) {
        this.alerts.noInput = true;
        this.loading = false; // Reset loading state if validation fails
      } else if (!this.form.username) {
        this.alerts.usernameOnly = true;
        this.loading = false;
      } else if (!this.form.password) {
        this.alerts.passwordOnly = true;
        this.loading = false;
      } else {
        try {
          // Attempt login through backend API
          const response = await api.post("/api/auth/login/", this.form);
          // Store tokens in local storage if login is successful
          localStorage.setItem("access_token", response.data.access);
          localStorage.setItem("refresh_token", response.data.refresh);

          // Show success alert and delay redirect to dashboard
          this.alerts.success = true;
          setTimeout(() => {
            this.$emit("login");
            this.$router.push("/dashboard");
          }, 1500);
        } catch (error) {
          // Show invalid credentials alert if login fails
          this.alerts.invalidCredentials = true;
        } finally {
          this.loading = false; // Reset loading state after request completes
        }
      }
    },
    // Reset all alerts and field errors
    resetErrors() {
      this.fieldErrors.username = [];
      this.fieldErrors.password = [];
      this.alerts = {
        noInput: false,
        usernameOnly: false,
        passwordOnly: false,
        invalidCredentials: false,
        success: false,
      };
    },
  },
};
</script>

<style scoped>
/* Container styling for the login form */
.login {
  border-radius: 12px; /* Keep rounded corners */
  padding: 40px; /* Add inner padding */
  margin: 100px auto; /* Center card on page */
  text-align: center; /* Center text alignment */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Light shadow for floating effect */
  background-color: #fff; /* White background */
}

/* Styling for the login button */
.login-btn {
  display: block;
  width: 98%; /* Button width adjustment */
  margin-left: 1%; /* Center the button with slight offset */
  padding: 12px; /* Button padding */
  background-color: #6200ee; /* MD3 Primary color */
  color: white;
  border: none;
  border-radius: 24px; /* Rounded corners */
  cursor: pointer;
  font-size: 1em; /* Font size for button text */
  font-weight: bold;
  transition: background-color 0.3s ease; /* Smooth color transition */
}

/* Hover effect for login button */
.login-btn:hover {
  background-color: #3700b3; /* MD3 Hover color */
}

/* Styling for the forgot password link */
.forgot-password-link {
  display: block;
  margin-top: 10px;
  color: #6200ee; /* MD3 Primary color */
  text-decoration: none;
  font-size: 0.9em;
}

/* Hover effect for forgot password link */
.forgot-password-link:hover {
  text-decoration: underline;
  color: #3700b3; /* Hover color */
}

/* Success alert styling */
.success-alert {
  background-color: #e6ffed; /* Light green for success alert */
  color: #388e3c; /* Success text color */
}
.login-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
  text-align: center;
}
/* Aligns alert text to the left */
.text-left {
  text-align: left;
}

/* Custom close icon styling for alerts */
.custom-close-icon {
  font-size: 24px; /* Size of close icon */
  color: #ffffff !important; /* White color for close icon */
  cursor: pointer; /* Pointer cursor to indicate clickability */
}

/* Custom styling for the password eye icon positioning */
.custom-icon-position .v-input__append-inner .v-icon {
  margin-right: -4px !important; /* Adjusts icon alignment inside text box */
  cursor: pointer; /* Pointer cursor for the icon */
}
</style>
