<template>
  <div class="chart-wrapper">
    <div class="chart-container">
      <Radar :data="data" :options="computedOptions" />
    </div>
  </div>
</template>

<script>
import { Radar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
} from "chart.js";

// Register the components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement
);

export default {
  components: {
    Radar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    computedOptions() {
      const maxDataValue = Math.max(
        ...this.data.datasets.flatMap((dataset) => dataset.data)
      );
      const adjustedMax = Math.ceil(maxDataValue * 1.3); // Adjust max to make the chart fuller
      const isMobile = window.innerWidth < 600; // Determine if on mobile
      const fontSize = isMobile ? 8 : 12; // Adjust font size for mobile
      const titleFontSize = isMobile ? 8 : 18; // Adjust title font size

      return {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        scales: {
          r: {
            min: 0, // Set the minimum value to 0
            max: adjustedMax, // Set the dynamic maximum value for fullness
            ticks: {
              stepSize: Math.ceil(adjustedMax / 6), // Define step size to ensure intermediate values are visible
              count: 6, // Set tick count to have more intermediate lines (including the center and max)
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: fontSize,
              },
              color: "#333333", // Black numbers for ticks
              showLabelBackdrop: false,
            },
            pointLabels: {
              font: {
                family: "'Roboto', sans-serif",
                weight: "bold",
                size: fontSize,
              },
              color: "#333333", // Black numbers for ticks
              showLabelBackdrop: false,
            },
            grid: {
              color: "rgba(51, 51, 51, 0.2)", // Light grey for gridlines
              lineWidth: 1, // Thinner gridlines for subtle effect
            },
            angleLines: {
              color: "rgba(51, 51, 51, 0.2)", // Light grey for angle lines
              lineWidth: 1, // Thinner angle lines
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              font: {
                family: "'Roboto', sans-serif",
                size: fontSize,
                weight: "bold",
              },
              color: "#333333", // Black for legend labels
            },
          },
          title: {
            display: true,
            text: "Player Performance",
            font: {
              family: "'Roboto', sans-serif",
              size: titleFontSize,
              weight: "bold",
            },
            color: "#333333", // Black for chart title
          },
          tooltip: {
            enabled: true,
            backgroundColor: "#ffffff", // White background for tooltip
            borderColor: "#6200ee", // Blue border for tooltip
            borderWidth: 1,
            titleColor: "#333333", // Black text for title
            bodyColor: "#333333", // Black text for body
          },
        },
        elements: {
          line: {
            borderColor: "rgba(54, 162, 235, 1)", // Darker blue for player data
            borderWidth: 3, // Bold blue line
            backgroundColor: "rgba(54, 162, 235, 0.05)", // Much lighter blue fill
          },
          point: {
            backgroundColor: "#1E88E5", // Darker blue for points
            borderColor: "#ffffff", // White border for points
            radius: isMobile ? 4 : 10, // Larger point size for better visibility
            hoverRadius: isMobile ? 6 : 12, // Larger hover size for emphasis
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.chart-wrapper {
  margin-top: 20px;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  max-height: 500px;
  margin: auto;
  background: #ffffff; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 10px; /* Padding inside the container */
}
</style>
