// Import necessary functions and components for routing
import { createRouter, createWebHistory } from "vue-router";

// Component Imports
import UserProfileComponent from "../components/UserProfileComponent.vue";
import UserProfileEditComponent from "../components/UserProfileEditComponent.vue";
import RegisterComponent from "../components/RegisterComponent.vue";
import LoginComponent from "../components/LoginComponent.vue";
import DashboardComponent from "../components/DashboardComponent.vue";
import ForgotPasswordComponent from "../components/ForgotPasswordComponent.vue";
import ResetPasswordComponent from "../components/ResetPasswordComponent.vue";
import FileImportComponent from "../components/FileImportComponent.vue";
import ManageTeamComponent from "../components/ManageTeamComponent.vue";
import PlayerManagementComponent from "../components/PlayerManagementComponent.vue";
import TrainingSessionsComponent from "../components/TrainingSessionsComponent.vue";
import ComparePlayersComponent from "../components/ComparePlayersComponent.vue";
import CompareResultsComponent from "../components/CompareResultsComponent.vue";
import DataVisualizationComponent from "../components/DataVisualizationComponent.vue";
import ChangePasswordComponent from "../components/ChangePasswordComponent.vue";
import ChangeEmailComponent from "../components/ChangeEmailComponent.vue";
import UserSettingsComponent from "../components/UserSettingsComponent.vue";
import ChangeUsernameComponent from "../components/ChangeUsernameComponent.vue";
import DataVisualizationComparison from "../components/DataVisualizationComparison.vue";
import VerifyEmailComponent from "../components/VerifyEmailComponent.vue";
import ConfirmEmailComponent from "../components/ConfirmEmailComponent.vue";

// Define routes for the application
const routes = [
  // Guest-only Routes
  // These routes should only be accessible to unauthenticated users
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterComponent,
    meta: { forGuests: true },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
    meta: { forGuests: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordComponent,
    meta: { forGuests: true },
  },
  {
    path: "/reset-password/:uidb64/:token",
    name: "ResetPassword",
    component: ResetPasswordComponent,
    meta: { forGuests: true },
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmailComponent,
    props: (route) => ({ email: route.params.email }),
    meta: { forGuests: true },
  },
  {
    path: "/confirm-email/:uidb64/:token",
    name: "ConfirmEmail",
    component: ConfirmEmailComponent,
    props: true,
    meta: { forGuests: true },
  },

  // Authenticated Routes
  // These routes require the user to be authenticated
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfileComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-profile",
    name: "EditUserProfile",
    component: UserProfileEditComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/import-file",
    name: "FileImport",
    component: FileImportComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-team",
    name: "ManageTeam",
    component: ManageTeamComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-players",
    name: "PlayerManagement",
    component: PlayerManagementComponent,
    meta: { requiresAuth: true },
    props: (route) => ({ teamId: route.query.teamId }),
  },
  {
    path: "/training-sessions",
    name: "TrainingSessions",
    component: TrainingSessionsComponent,
    meta: { requiresAuth: true },
    props: (route) => ({
      teamId: route.query.teamId,
      playerId: route.query.playerId,
    }),
  },
  {
    path: "/compare-players",
    name: "ComparePlayers",
    component: ComparePlayersComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/compare-results",
    name: "CompareResults",
    component: CompareResultsComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/data-visualization",
    name: "DataVisualization",
    component: DataVisualizationComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/data-visualization-comparison",
    name: "DataVisualizationComparison",
    component: DataVisualizationComparison,
    meta: { requiresAuth: true },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePasswordComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/change-username",
    name: "ChangeUsername",
    component: ChangeUsernameComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/change-email",
    name: "ChangeEmail",
    component: ChangeEmailComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "UserSettings",
    component: UserSettingsComponent,
    meta: { requiresAuth: true },
  },
];

// Create and configure the router with web history
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global Navigation Guards
router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem("access_token");
  const isAuthenticated = !!accessToken; // Boolean for authentication status

  // Handle routes that require authentication
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "Login" }); // Redirect to login if unauthenticated
  }
  // Handle routes for guest-only access
  else if (to.meta.forGuests && isAuthenticated) {
    next({ name: "Dashboard" }); // Redirect to dashboard if already logged in
  } else {
    next(); // Proceed to the route
  }
});

export default router;
